import React from 'react'

// Vendor
import { BrowserRouter as Router } from 'react-router-dom'

// Volcano
import ReactorUi from 'reactor-ui'

// Reactor
import ReactorVeraBase from './ReactorVeraBase'

import uiApp from 'reactor-vera/apps/ui'
// import modelApp from 'reactor-vera/apps/model'

import ReactorVera from 'reactor-vera/systems/reactor-vera'

import ReactorVeraIndex from 'reactor-vera/components/ReactorVeraIndex'

import ReactorVerai18n from './components/ReactorVerai18n'

const defaultApps = [
  uiApp,
  // modelApp,
]

const defaultSystems = [
  ReactorVera
]

// import RfFormProvider from 'reactor-form/components/RfFormProvider'

const ReactorVeraApp = ({
  theme,
  globalCss,
  uiConfig,
  apps = [],
  systems = [],
  storeCreator,
  config,
  homeElement,
  indexComponent,
  children,
  routes
}) => {
  return (
    <ReactorVeraBase apps={[...defaultApps, ...apps]} systems={[...defaultSystems, ...systems]} storeCreator={storeCreator} config={config}>
      <Router>
        <ReactorVerai18n lang={'tr'}>
          <ReactorUi uiConfig={uiConfig} theme={theme} globalCss={globalCss}>
            {/* <RfFormProvider theme={theme} ctx={{
            lookupBaseUrl: '/_api/reactor/source/',
            remoteLookupBaseUrl: '/_api/reactor/remote/',
            mediaUploadUrl: '/_api/reactor/upload/RxStorage.UploadMedia',
            // mediaGalleryUrl: '/_api/reactor/source/RxFile.Media.image_list',
            entityMap: {},
            headers: { 'X-RUNIC-PLATFORM': 'RUNIC_MAIN' },
          }}> */}
            {indexComponent || <ReactorVeraIndex uiConfig={uiConfig} runicConfig={config} homeElement={homeElement} routes={routes} />}
            {children}
            {/* </RfFormProvider> */}
          </ReactorUi>
        </ReactorVerai18n>
      </Router>
    </ReactorVeraBase>
  )
}

export default ReactorVeraApp