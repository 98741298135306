import React from 'react'

// Reactor
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'

//
import { RnxEventContext } from '../eventContext'


const EventContacts = props => {
  const { rnxEvent } = React.useContext(RnxEventContext)

  return (
    <>
      <GraphTable
        modelName='RnxEventContact'
        detailLink='contact'
        headerText='Kişiler'
        actionCtx={{
          model: {
            rnx_event_id: rnxEvent.id
          }
        }}
        query={{
          filters: {
            rnx_event_id: rnxEvent.id,
          },
          itemFields: ['name', 'infos', 'status'],
          columns: ['name', 'infos.company', 'infos.position', 'status'],
          // itemFields: ['name', {'rnxContact': {fields: ['govId']}}],
          // columns: ['name', 'rnxContact.govId']
        }}

      />
    </>
  )
}

export default EventContacts