import React from 'react'

// Reactor
import useReactorAction from 'reactor/hooks/useReactorAction'
import { useSelector, useDispatch } from 'react-redux'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { Routes, Route, useParams, Link, useLocation } from 'reactor-vera/router'
import reactorActions from 'reactor/systems/reactor/actions'


const paramsToObject = (entries) => {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value
  }
  return result
}

const SocialLogin = ({

}) => {
  const dispatch = useDispatch()
  const [ready, readySet] = React.useState()
  React.useEffect(() => {
    dispatch(reactorActions.setup({
      config: {
        channel: 'ReactorPublic',
      },
      // rcTenantId: params.rcTenantId
    }))
    readySet(true)
  }, [])
  if (!ready) return null
  return <SocialLoginContent/>
}

const SocialLoginContent = props => {
  const location = useLocation()
  const params = useParams()
  const dispatchAction = useReactorAction('RnxEvent.SocialLogin')

  React.useEffect(() => {
    const urlParams = paramsToObject(new URLSearchParams(location.search))
    dispatchAction({data: urlParams, kind: params.kind}, {
      success: data => window.location.replace(data.uri)
    })
  }, [])
  return (
    <Box sx={{
      my: 6,
      textAlign: 'center'
    }}>
      Kontrol ediliyor.
    </Box>
  )
}

export default SocialLogin