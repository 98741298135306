import React from 'react'

// Vendor
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import reactorActions from 'reactor/systems/reactor/actions'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Routes, Route, useParams, Link, useHref, useNavigate } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'

import Zoom from '../components/Zoom'

const RnxEventActivity = () => {
  const [ready, readySet] = React.useState()
  const dispatch = useDispatch()
  const params = useParams()
  React.useEffect(() => {
    dispatch(reactorActions.setup({
      config: {
        channel: 'ReactorPublic',
      },
      rcTenantId: params.rcTenantId
    }))
    readySet(true)
  }, [])

  if (!ready) return null
  return <RnxEventActivityContent/>
}


const RnxEventActivityContent = props => {
  const [open, setOpen] = React.useState(true)
  const params = useParams()
  const href = useHref('../../lobby')
  const result = useReactorQuery({
    'publicActivity': {
      id: params.activityId,
      fields: [
        'config',
        'zoom'
      ]
    },
  })

  const publicActivity = result.graph?.publicActivity
  if (!publicActivity) return null
  console.log('publicActivity', publicActivity)
  return (
    <Box>
      {open && <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        p: 3,
        bg: '#FFF',
        zIndex: 99999,
        textAlign: 'center'
      }}>
        <a href={`https://us06web.zoom.us/j/${publicActivity.config.zoom_id}?pwd=${publicActivity.config.zoom_password}`} target='_blank'>Sorun yaşıyorsanız Zoom üzerinden bağlanmak için tıklayın.</a>
      </Box>}
      {open && <Box sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        p: 3,
        bg: '#FFF',
        zIndex: 99999,
        textAlign: 'center'
      }}>
        <Icon icon='circle-remove' size='5' onClick={() => setOpen(false)}/>
      </Box>}
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        p: 3,
        bg: '#FFF',
        zIndex: 99998,
        textAlign: 'center'
      }}>
        <Link to={href}>Geri dön</Link>
      </Box>
      <Zoom id={publicActivity.config.zoom_id} password={publicActivity.config.zoom_password} name={publicActivity.zoom.name} email={publicActivity.zoom.email}/>
    </Box>
  )
}

export default RnxEventActivity