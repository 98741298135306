import RnxEventManager from './pages/RnxEventManager'
import EventHome from './pages/EventHome'
import EventAccounts from './pages/EventAccounts'
import EventProfiles from './pages/EventProfiles'
import EventSetup from './pages/EventSetup'
import EventContacts from './pages/EventContacts'
import EventBooths from './pages/EventBooths'
import EventSchedule from './pages/EventSchedule'
import EventActivities from './pages/EventActivities'


export default {
  name: 'RnxEvent',
  components: {
    'RnxEventManager': {
      component: RnxEventManager
    },
    'EventHome': {
      component: EventHome
    },
    'EventAccounts': {
      component: EventAccounts
    },
    'EventProfiles': {
      component: EventProfiles
    },
    'EventSetup': {
      component: EventSetup
    },
    'EventContacts': {
      component: EventContacts
    },
    'EventBooths': {
      component: EventBooths
    },
    'EventSchedule': {
      component: EventSchedule
    },
    'EventActivities': {
      component: EventActivities
    },
  }
}