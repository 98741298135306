import React from 'react'

// Reactor
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useReactorAction from 'reactor/hooks/useReactorAction'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

import Hotspots from '../components/Hotspots'
import RnxEventBooth from './RnxEventBooth'

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

const getEmbedUrl = (url) => {
  if (!url) return null
  const id = getId(url)
  if (!id) return null
  return `https://www.youtube.com/embed/${id}`
}

const hallMap = {
  1: {
    'icon': 'square-new-window',
    'top': '29%',
    'left': '29%',
    'kind': 'url',
    'url': '../hall/1'
  },
  2: {
    'icon': 'square-new-window',
    'top': '29%',
    'left': '45%',
    'kind': 'url',
    'url': '../hall/2'
  },
  3: {
    'icon': 'square-new-window',
    'top': '29%',
    'left': '59%',
    'kind': 'url',
    'url': '../hall/3'
  }
}

const RnxEventExhibitionHall = ({
  publicEvent
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: publicEvent.eventId,
      kind: 'EXHIBITION_HALL',
      fields: [
        'name', 'image', 'data', 'settingsValues'
      ]
    },
  })
  const publicEventArea = result.graph?.publicEventArea
  console.log('event', publicEvent)
  console.log('publicEventArea', publicEventArea)
  if (!publicEventArea) return null
  console.log(publicEventArea.image)
  return <RnxEventExhibitionHallContent publicEvent={publicEvent} publicEventArea={publicEventArea}/>
}

const RnxEventExhibitionHallContent = ({
  publicEventArea,
  publicEvent
}) => {
  const params = useParams()
  const dispatchAction = useReactorAction('RnxEvent.TrackVisit')
  React.useEffect(() => {
    dispatchAction({
      'kind': 'EXHIBITION_HALL',
      'id': publicEventArea.id
    })
  }, [])

  const booths = publicEventArea.data?.booths
  const hotspots = []
  if (booths) {
    Object.entries(booths).forEach(([key, value]) => {
      hotspots.push({
        'icon': 'square-new-window',
        'top': `${(value.position.y) / 2160 * 100}%`,
        'left': `${(parseInt(value.position.x) + parseInt(value.size.x)*0.3) / 3840 * 100}%`,
        'kind': 'url',
        'text': value.name,
        'url': `./booth/${value.id}`
      })
    })
  }

  if (publicEventArea.settingsValues?.hall_count) {
    const hallCount = parseInt(publicEventArea.settingsValues?.hall_count)
    for (let index = 1; index <= hallCount; index++) {
      const hallHotspot = hallMap[index]
      if (hallHotspot) hotspots.push(hallHotspot)
    }
  }


  if ([
    '414104af-5370-43dd-b6c8-3be70d7bed8b',
    'c249bdff-d890-4194-aae9-788c15bdfdbe'
  ].includes(publicEvent.eventId)) {
    hotspots.push({
      'icon': 'square-new-window',
      'top': `26%`,
      'left': `73%`,
      'kind': 'url',
      'text': 'Gelecek İstasyonu',
      'outurl': `https://www.airmeet.com/e/36dfb540-5902-11ec-b7d2-696b2274f676`
    })
  }

  console.log('hotspots', hotspots)

  return (
    <Box sx={{
      // background: `url('${publicEventArea.image}') top center no-repeat`,
      // backgroundSize: 'cover',
      // backgroundSize: 'contain',
      // width: '100vw',
      // minWidth: 1000,
      // minHeight: 800,
      // height: '100vh',
      position: 'relative'
    }}>
      <Box as='img' src={publicEventArea.image} sx={{
        width: '100%',
        height: '100%',
      }} />
      <Box sx={{
        p: 4,
        position: 'fixed',
        top: 0,
        width: '100%'
      }}>
        <Flex>
          <Link to='../lobby'>
          <Box as='img' src={publicEvent.logo} sx={{
            width: 150
          }} />
          </Link>
        </Flex>
      </Box>

      <Hotspots hotspots={[
        ...hotspots,

        publicEventArea.settingsValues?.sol_ekran?.videoUrl && {
          'icon': 'square-new-window',
          'top': '37%',
          'left': '79%',
          'url': 'video2'
        },

        publicEventArea.settingsValues?.sag_ekran?.videoUrl && {
          'icon': 'square-new-window',
          'top': '37%',
          'left': '20%',
          'url': 'video1'
        },

        // {
        //   'icon': 'square-new-window',
        //   'top': '20%',
        //   'left': '73.5%',
        //   // 'text': '',
        //   'outurl': 'https://www.airmeet.com/e/8eb4ccc0-3124-11ec-b9f5-0d2535a9caf8'
        // },

        // {
        //   'icon': 'square-new-window',
        //   'top': '51%',
        //   'left': '31%'
        // }
      ]} />
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 999
      }}>
        <Routes>
          <Route path='booth/:boothId*' element={<RnxEventBooth publicEvent={publicEvent} />} />
        </Routes>
      </Box>

      <Routes>
        <Route path='video1' element={(
          <Box sx={{
            zIndex: 999999
          }}>
            <Link to={'../'} sx={{
              position: 'absolute',
              top: '30px',
              right: '80px',
              zIndex: 9999999
            }}>
              <Icon name='circle-remove' color='brand.500' size={10} />
            </Link>
            <Box as='iframe' sx={{
              position: 'absolute',
              zIndex: 99999,
              top: '5vh',
              left: '5vw',
              width: '90vw',
              height: '90vh',
            }} src={getEmbedUrl(publicEventArea.settingsValues?.sol_ekran?.videoUrl)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Box>
          </Box>
        )} />

        <Route path='video2' element={(
          <Box sx={{
            zIndex: 9999999
          }}>
            <Link to={'../'} sx={{
              position: 'absolute',
              top: '30px',
              right: '80px',
              zIndex: 999999
            }}>
              <Icon name='circle-remove' color='brand.500' size={10} />
            </Link>
            <Box as='iframe' sx={{
              position: 'absolute',
              zIndex: 99999,
              top: '5vh',
              left: '5vw',
              width: '90vw',
              height: '90vh',
            }} src={getEmbedUrl(publicEventArea.settingsValues?.sag_ekran?.videoUrl)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Box>
          </Box>
        )} />
      </Routes>
    </Box>
  )
}

export default RnxEventExhibitionHall