import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import * as changeCase from 'reactor-ui/util/text'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'
import Icon from 'reactor-ui/components/Icon'

// Reactor
import { Link, useActiveLink, useParams, useMatch, useResolvedPath, useHref } from 'reactor-vera/router'
import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'
import useReactor from 'reactor/hooks/useReactor'


const PlexUiSidebarHeader = ({
  variant,
  ...rest
}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const styles = useStyleConfig("PlexUiSidebarHeader", {
    variant, defaultStyleConfig: {
      baseStyle: {
        fontSize: 'xs',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: 'light.500',
      },
      variants: {
        // active: {
        //   color: 'dark.500',
        //   top: '-11px',
        //   left: 'calc(var(--chakra-space-3) - 4px)',
        //   fontSize: 'sm',
        //   background: '#FFF',
        //   py: '2px',
        //   px: '4px',
        //   // fontWeight: 'bold',
        //   // color: 'dark6',
        //   ...activeSx
        // }
      }
    }
  })
  if (!layoutConfig.sidebar.extended) return null

  return <Box __css={styles} {...rest} />
}

export const PlexUiSidebarItem = ({
  item,
  depth,
  baseUrl
}) => {
  const reactor = useReactor()
  const Component = reactor.directory.reactorVera?.components?.[item.componentKey]

  return (
    <Component item={item} depth={depth} baseUrl={baseUrl} />
  )
}


export const PlexUiGroupItem = ({
  item,
  depth,
  baseUrl
}) => {
  if (depth > 0) return <PlexUiSubGroup item={item} baseUrl={baseUrl} />
  if (item.mode === 'entity') return <PlexUiEntityGroup item={item} baseUrl={baseUrl} />

  return <PlexUiGroupContent item={item} baseUrl={baseUrl} depth={depth}/>
}

const PlexUiGroupContent = ({
  item,
  depth,
  baseUrl
}) => {
  return (
    <Box sx={{ my: 4 }}>
      <PlexUiSidebarHeader>
        {item.label || item.name}
      </PlexUiSidebarHeader>

      {item.items.map((item, dx) => {
        return (
          <Box key={dx} my={0}>
            <PlexUiSidebarItem item={item} depth={depth + 1} baseUrl={baseUrl} />
          </Box>
        )
      })}
    </Box>
  )
}

export const PlexUiEntityGroup = ({
  item,
  depth,
  baseUrl
}) => {
  const { t } = useTranslation()
  const match = useMatch(`${baseUrl}/${item.key}/${item.options.requiredRoutePath}*`)

  if (!match) return (
    <Box color='brandLight.100'>
      {t(item.options.selectMessage)}
    </Box>
  )
  // return <PlexUiGroupContent item={item} baseUrl={baseUrl} depth={depth}/>
  return (
    <Box sx={{ my: 4 }}>
      <PlexUiSidebarHeader>
        {item.name}
      </PlexUiSidebarHeader>

      {item.items.map((item, dx) => {
        return (
          <Box key={dx}>
            <PlexUiSidebarItem item={item} depth={depth + 1} baseUrl={match.pathname} />
          </Box>
        )
      })}
    </Box>
  )
}

export const PlexUiComponentItem = ({
  item,
  // variant,
  depth,
  baseUrl
}) => {
  const to = `${baseUrl}/${item.key}`
  const isActive = useActiveLink({ to })
  const variant = isActive ? 'active' : null

  const styles = useStyleConfig("PlexUiComponentItem", {
    variant, defaultStyleConfig: {
      baseStyle: {
        // fontSize: 'xs',
        textTransform: 'uppercase',
        // fontWeight: 'bold',
        color: 'light.800',
        '&:hover': {
          color: 'brandLight.500'
        },
        transition: 'all 0.2s ease',
        my: 1,
        display: 'block',
        fontSize: 'sm',
        py: 2,
        px: 2,
        borderRadius: 2,
      },
      variants: {
        active: {
          bg: 'brand.500',
          '&:hover': {
            bg: 'brand.400',
            color: 'light.800'
          },
          // color: 'dark.500'
        },
      }
    }
  })

  const { t } = useTranslation()
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  let content, sx
  if (layoutConfig.sidebar.extended) {
    content = (
      <Icon icon={item.icon} childSx={{
        ml: 4,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // width: '80%',
        display: 'inline-block',
        // transition: 'all 0.2s ease',
      }}>
        {t(`label.${changeCase.snake(item.name)}`)}
      </Icon>
    )
  } else {
    sx = {
      fontSize: 'xs',
      width: '60px',
      textAlign: 'center',
      px: 0
    }
    content = (
      <>
      <Icon icon={item.icon} wrapperSx={{
        mx: 'auto',
        width: '20px'
      }}/>
      <Box sx={{
        fontSize: 'xs',
        mt: 1
      }}>
      {t(`label.${changeCase.snake(item.name)}`)}
      </Box>
      </>
    )
  }

  return (
    <Box as={Link} to={to} __css={styles} sx={sx}>
      {content}
    </Box>
  )
}

export const PlexUiSubGroup = ({
  item,
  variant,
  depth,
  baseUrl
}) => {
  const [extended, setExtended] = React.useState(false)
  const { t } = useTranslation()

  const styles = useStyleConfig("PlexUiComponentItem", {
    variant, defaultStyleConfig: {
      baseStyle: {
        // fontSize: 'xs',
        textTransform: 'uppercase',
        // fontWeight: 'bold',
        color: 'light.800',
        '&:hover': {
          color: 'brandLight.500'
        },
        transition: 'all 0.2s ease',
        my: 2,
        display: 'block',
        fontSize: 'sm',
      },
      variants: {
        // active: {
        //   color: 'dark.500',
        //   top: '-11px',
        //   left: 'calc(var(--chakra-space-3) - 4px)',
        //   fontSize: 'sm',
        //   background: '#FFF',
        //   py: '2px',
        //   px: '4px',
        //   // fontWeight: 'bold',
        //   // color: 'dark6',
        //   ...activeSx
        // }
      }
    }
  })

  return (
    <>
      <Box __css={styles} as='a' onClick={() => setExtended(v => !v)}>
        <Flex alignItems={'center'}>
          <Icon icon={item.icon} wrapperSx={{ flex: '1 1 auto' }} childSx={{
            ml: 4,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '80%',
            display: 'inline-block',
          }}>
            {t(`label.${changeCase.snake(item.name)}`)}
          </Icon>
          <Box sx={{
            flex: '0 0 auto'
          }}>
            <Icon name={extended ? 'chevron-up' : 'chevron-down'} />
          </Box>
        </Flex>
      </Box>

      {extended && (
        <Box sx={{
          pl: 3
        }}>
          {item.items.map((item, dx) => {
            return (
              <Box key={dx}>
                <PlexUiSidebarItem item={item} depth={depth + 1} baseUrl={baseUrl} />
              </Box>
            )
          })}
        </Box>
      )}
    </>
  )
}

export const PlexUiSingleSidebarWrapper = ({
  variant,
  children
}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig.sidebar.extended
  const styles = useStyleConfig('PlexUiSidebarWrapper', {
    variant, defaultStyleConfig: {
      baseStyle: {
        display: ['none', null, null, 'block'],
        bg: 'dark.500',
        width: '74px',
        height: '100vh',
        color: 'light.800',
        pr: isExtended ? 6 : 2,
        pl: isExtended ? 3 : 2,


        borderTopRightRadius: 1,
        borderBottomRightRadius: 1,
        position: 'fixed',
        // width: extended ? 'sidebarExtended' : 'sidebar',
        // top: 10,
        color: 'text',
        transition: 'all 0.2s ease',

        overflow: 'auto',
        zIndex: 99,
        '@media print': {
          display: 'none'
        },
        overflowX: 'hidden'
      },
      variants: {
        extended: {
          width: '240px'
          // color: 'dark.500',
          // top: '-11px',
          // left: 'calc(var(--chakra-space-3) - 4px)',
          // fontSize: 'sm',
          // background: '#FFF',
          // py: '2px',
          // px: '4px',
        }
      }
    }
  })

  return (
    <Box __css={styles}>
      {children}
    </Box>
  )
}