import React from 'react'

import { Routes, Route, useParams, Link, useNavigate, useHref } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "reactor-ui/components/Modal"

import useReactorAction from 'reactor/hooks/useReactorAction'

const ZoomEvent = ({
  id,
  password,
  name,
  email
}) => {
  console.log(id, password, name, email)
  const [ZoomMtg, setZoomMtg] = React.useState()
  const href = useHref('../../lobby')
  console.log('href', href)
  React.useEffect(() => {
    // var lang = 'en-US'
    import(`@zoomus/websdk`).then(module => {
      const ZoomMtg = module.ZoomMtg
      setZoomMtg(ZoomMtg)
      var lang = 'tr-TR'
      ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.9/lib', '/av');

      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      // loads language files, also passes any error messages to the ui
      ZoomMtg.i18n.load(lang);
      ZoomMtg.i18n.reload(lang);
      document.getElementById('zmmtg-root').style.display = 'block'
    })
  }, [])

  React.useEffect(() => {
    return () => document.getElementById('zmmtg-root').style.display = 'none'
  }, [])
  var signatureEndpoint = ''
  var apiKey = 'Y0-_e6ZPQmGMZWKf2iE1RA'
  var meetingNumber = id
  var role = 0
  var leaveUrl = href
  // var leaveUrl = ''
  var userName = name
  var userEmail = email
  var passWord = password
  var registrantToken = ''


  const dispatchAction = useReactorAction('RnxEvent.GetZoomSignature')

  // const [sig, setsig] = React.useState(null)
  // React.useEffect(() => {
  //   dispatchAction(null, {
  //     success: (data) => {
  //       console.log(data)
  //       setsig(data.sig)
  //       document.getElementById('zmmtg-root').style.display = 'block'

  //       ZoomMtg.init({
  //         leaveUrl: leaveUrl,
  //         success: (success) => {
  //           console.log('success')
  //           console.log(success)

  //           ZoomMtg.join({
  //             signature: data.sig,
  //             meetingNumber: meetingNumber,
  //             userName: userName,
  //             apiKey: apiKey,
  //             userEmail: userEmail,
  //             passWord: passWord,
  //             tk: registrantToken,
  //             success: (success) => {
  //               console.log(success)
  //             },
  //             error: (error) => {
  //               console.log(error)
  //             }
  //           })

  //         },
  //         error: (error) => {
  //           console.log(error)
  //         }
  //       })
  //     }
  //   })
  // }, [])

  React.useEffect(() => {
    if (!ZoomMtg) return
    dispatchAction({
      meetingNumber
    }, {
      success: (data) => {
        console.log(data)
        // setsig(data.sig)
        document.getElementById('zmmtg-root').style.display = 'block'

        ZoomMtg.init({
          leaveUrl: leaveUrl,
          success: (success) => {
            console.log('success')
            console.log(success)

            ZoomMtg.join({
              signature: data.sig,
              meetingNumber: meetingNumber,
              userName: userName,
              apiKey: apiKey,
              userEmail: userEmail,
              passWord: passWord,
              // tk: registrantToken,
              success: (success) => {
                console.log(success)
              },
              error: (error) => {
                console.log(error)
              }
            })

          },
          error: (error) => {
            console.log(error)
          }
        })
      }
    })
  }, [ZoomMtg])

  return (
    null
  )

  if (!sig) return null
  return null
}


const Zoom = ({
  id,
  password,
  name,
  email
}) => {
  return (
    <>
      <ZoomEvent id={id} password={password} name={name} email={email} />
    </>
  )
}

export default Zoom