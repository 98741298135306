import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'


const RcAssetManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<AssetHome />} />
      <Route path='gallery/:rcAssetGalleryId/*' element={<AssetGalleryDetail />} />
    </Routes>
  )
}

const AssetHome = props => {
  const { t } = useTranslation()
  return (
    <>
      <GraphTable
        modelName='RcAssetGallery'
        query={{
          systemQuery: true
        }}
        elements={{
        }}
        detailLink='gallery'
      />
    </>
  )
}


const AssetGalleryDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rcAssetGallery': {
      filters: {
        id: params.rcAssetGalleryId
      }
    },
  })

  useBreadcrumb(`rcAssetGalleryDetail:${result.graph?.rcAssetGallery.id}`, result.graph?.rcAssetGallery.name)

  const rcAssetGallery = result.graph?.rcAssetGallery
  const meta = result.graph?.rcAssetGalleryMeta
  console.log(rcAssetGallery)

  if (!rcAssetGallery) return null

  return (
    <>
      <ModelDetailCard
        icon={'book-open'}
        entity={rcAssetGallery}
        schema={result.schema?.rcAssetGallery}
        modelName={meta.id}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      {rcAssetGallery.allowedKinds.map((kind, dx) => {
        let modelName

        if (kind.value === 'FONT') {
          modelName = 'RcFontFamily'
        }

        return (
          <Box key={dx} my={4}>
            <GraphTable
              modelName={modelName}
              query={{
                systemQuery: true,
                filters: {
                  '@rc_tag': {id: rcAssetGallery.id}
                }
              }}
              elements={{
              }}
              showModelActions={false}
              // detailLink='gallery'
            />
          </Box>
        )
      })}
    </>
  )
}

export default RcAssetManagement