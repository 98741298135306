import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

// Reactor UI
import Button from 'reactor-ui/components/Button'
import BareTabs from 'reactor-ui/components/BareTabs'
// import RouteTabs from 'reactor-vera/components/RouteTabs'

import EventCreate from './EventCreate'
import EventSetup from './EventSetup'
import EventSetupItem from './EventSetupItem'
import EventProfiles from './EventProfiles'


const Events = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<EventsHome />} />
      <Route path='create' element={<EventCreate />} />
      <Route path='event/:rnxEventId/*' element={<EventDetail />} />
    </Routes>
  )
}

const EventsHome = props => {
  const { t } = useTranslation()
  return (
    <>
      <GraphTable
        modelName='RnxEvent'
        elements={{
          primaryActions: [
            <Button key='create' colorScheme='brand' as={Link} to='create'>
              Yeni Etkinlik Oluştur
            </Button>
          ]
        }}
        detailLink={(row) => `../em/${row.id}/em.event_home`}
      />
    </>
  )
}

const EventDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEvent': {
      filters: {
        id: params.rnxEventId
      }
    },
  })

  useBreadcrumb(`rnxEventDetail:${result.graph?.rnxEvent.id}`, result.graph?.rnxEvent.name)

  const rnxEvent = result.graph?.rnxEvent

  if (!rnxEvent) return null

  return (
    <Routes>
      <Route path='/' element={<EventDetailContent result={result} />} />
      <Route path='area/:rnxEventAreaId/*' element={<EventSetupItem rnxEvent={rnxEvent} />} />
    </Routes>
  )
}

const EventDetailContent = ({
  result
}) => {
  const rnxEvent = result.graph?.rnxEvent
  const meta = result.graph?.rnxEventMeta

  return (
    <>
      <ModelDetailCard
        icon={'book-open'}
        entity={rnxEvent}
        schema={result.schema?.rnxEvent}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Contact',
            codeName: 'contact',
            element: (
              <GraphTable
                modelName='RnxEventContact'
                detailLink='contact'
                actionCtx={{
                  model: {
                    rnx_event_id: rnxEvent.id
                  }
                }}
                query={{
                  filters: {
                    rnx_event_id: rnxEvent.id
                  }
                }}
              />
            )
          },

          {
            name: 'Booth',
            codeName: 'booth',
            element: (
              <GraphTable
                modelName='RnxEventArea'
                detailLink='booth'
                headerText='Standlar'
                actionCtx={{
                  model: {
                    rnx_event_id: rnxEvent.id,
                    kind: 'BOOTH'
                  }
                }}
                query={{
                  filters: {
                    rnx_event_id: rnxEvent.id,
                    kind: 'BOOTH'
                  }
                }}
              />
            )
          },

          {
            name: 'Etkinlikler',
            codeName: 'activities',
            element: (
              <GraphTable
                modelName='RnxEventActivity'
                detailLink='eventactivity'
                headerText='Etkinlikler'
                actionCtx={{
                  model: {
                    rnx_event_id: rnxEvent.id
                  }
                }}
                query={{
                  filters: {
                    rnx_event_id: rnxEvent.id,
                  }
                }}
              />
            )
          },

          {
            name: 'Activity',
            codeName: 'activity',
            element: (
              <GraphTable
                modelName='RnxEventContactActivity'
                detailLink='activity'
                headerText='Olaylar'
                query={{
                  filters: {
                    rnx_event_id: rnxEvent.id,
                  }
                }}
              />
            )
          },

          {
            name: 'Account',
            codeName: 'account',
            element: (
              <GraphTable
                modelName='RnxEventAccount'
                detailLink='account'
                headerText='Hesaplar'
                actionCtx={{
                  model: {
                    rnx_event_id: rnxEvent.id
                  }
                }}
                query={{
                  filters: {
                    rnx_event_id: rnxEvent.id,
                  }
                }}
              />
            )
          },

          {
            name: 'Profiles',
            codeName: 'profiles',
            element: (
              <EventProfiles/>
            )
          },

          {
            name: 'Setup',
            codeName: 'setup',
            element: (
              <EventSetup rnxEvent={rnxEvent}/>
            )
          }

        ]}
      />

    </>
  )
}

export default Events