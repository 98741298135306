import AreaTemplateManagement from './pages/AreaTemplateManagement'
import EventTemplateManagement from './pages/EventTemplateManagement'


export default {
  name: 'RnxEventAdmin',
  components: {
    'AreaTemplateManagement': {
      component: AreaTemplateManagement
    },
    'EventTemplateManagement': {
      component: EventTemplateManagement
    }
  }
}