import React from 'react'

// Reactor
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'

//
import { RnxEventContext } from '../eventContext'


const EventSchedule = props => {
  const { rnxEvent } = React.useContext(RnxEventContext)
  console.log('rnxEvent', rnxEvent)
  return (
    <>
      <GraphTable
        modelName='RnxEventActivity'
        detailLink='eventactivity'
        headerText='Etkinlikler'
        actionCtx={{
          model: {
            rnx_event_id: rnxEvent.id
          }
        }}
        query={{
          filters: {
            rnx_event_id: rnxEvent.id,
          }
        }}
      />
    </>
  )
}

export default EventSchedule