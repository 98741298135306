import React from 'react'

import RfField from './RfField'


const RfFormField = ({
  sx,
  fieldSx,
  ...props
}) => {
  return <RfField field={{...props, kind: props.kind || 'text', sx: fieldSx}} sx={sx}/>
}

export default RfFormField