import React from 'react'

// Reactor
import { Routes, Route, Link, useParams, useNavigate } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

import EventSetupItem from './EventSetupItem'


const EventSetup = ({
  // rnxEvent
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'rnxEventAreaList': {
      filters: {
        rnx_event_id: params.rnxEventId,
        kind: [1, 2, 3, 5, 8]
      },
      order_by: 'id|asc'
      // itemFields: [
      //   'name', 'data'
      // ]
    },
  })
  const rnxEventAreaList = result.graph?.rnxEventAreaList
  console.log('rnxEventAreaList', rnxEventAreaList)
  if (!rnxEventAreaList) return null

  return (
    <Routes>
      <Route path='/' element={(
        <Box>
          {rnxEventAreaList.items.map((item, dx) => {
            return (
              <Link to={`area/${item.id}`} key={dx} sx={{
                display: 'block',
                my: 2
              }}>
                {item.name}
              </Link>
            )
          })}
        </Box>
      )} />
      <Route path='area/:rnxEventAreaId/*' element={<EventSetupItem />} />
    </Routes>
  )
}

export default EventSetup