import React from 'react'

// Vendor
import { Box } from "@chakra-ui/react"


const Card = ({sx, children, ...rest}) => {
  return (
    <Box sx={{
      px: 4,
      py: 3,
      borderWidth: '1px',
      borderRadius: 4,
      overflow: 'hidden',
      bg: '#FFF',
      ...sx
    }} {...rest}>
      {children}
    </Box>
  )
}

const CardHeader = ({
  children,
  sx
}) => {
  return (
    <Box sx={{
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 'sm',
      color: 'brand.900',
      ...sx
    }}>
      {children}
    </Box>
  )
}

Card.Header = CardHeader

export default Card