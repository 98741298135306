import React from 'react'

import { useTranslation } from 'react-i18next'

// Reactor
import { Routes, Route, useParams, Link, useHref } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useReactorAction from 'reactor/hooks/useReactorAction'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'

import Hotspots from '../components/Hotspots'

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

import { Portal } from "@chakra-ui/react"

const RnxEventBooth = ({
  publicEvent,
  boothId,
  headerContent,
  backUrl
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicBooth': {
      id: boothId || params.boothId,
      fields: [
        'name', 'image', 'data', 'logo', 'account'
      ]
    },
  })
  const publicBooth = result.graph?.publicBooth
  console.log('publicBooth', publicBooth)
  if (!publicBooth) return null
  return <BoothContent backUrl={backUrl} headerContent={headerContent} publicBooth={publicBooth} publicEvent={publicEvent} />
}

const BoothContent = ({
  publicEvent,
  publicBooth,
  headerContent,
  backUrl: backUrlFromProps
}) => {
  const { t } = useTranslation()
  const href = useHref('')
  const roothref = useHref('../../')
  const backUrl = backUrlFromProps || roothref
  const params = useParams()
  const dispatchAction = useReactorAction('RnxEvent.TrackVisit')
  React.useEffect(() => {
    params.boothId && dispatchAction({
      'kind': 'BOOTH',
      'id': params.boothId
    })
  }, [])

  const hotspots = []
  if (publicBooth.data?.info?.booth_kind === 'Tip 3') {
    // if (publicBooth.data.info.website) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '18%',
    //     'left': '27%',
    //     'text': publicBooth.data.info.website,
    //     'outurl': `${publicBooth.data.info.website}`
    //   })
    // }

    if (publicBooth.data.info.youtube) {
      hotspots.push({
        'icon': 'square-new-window',
        'top': '24%',
        'left': '59%',
        // 'text': publicBooth.data.info.website,
        'url': `video`
      })
    }

    // if (publicBooth.data.info.google_meet) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '45%',
    //     'left': '36%',
    //     'text': 'Google Meet',
    //     'outurl': `${publicBooth.data.info.google_meet}`
    //   })
    // }

    // if (publicBooth.data.info.calendlyurl) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '40%',
    //     'left': '25.5%',
    //     'text': 'Randevu alın',
    //     'outurl': publicBooth.data.info.calendlyurl
    //   })
    // }

    // if (publicBooth.data.info.brochure) {
    //   publicBooth.data.info.brochure.forEach((b, dx) => {
    //     let top = 56 + (dx * 8)
    //     hotspots.push({
    //       'icon': 'square-new-window',
    //       'top': `${top}%`,
    //       'left': '70.5%',
    //       'text': 'Broşürümüzü indirin',
    //       'outurl': `/eventrisefiles/files/${b}`
    //     })
    //   })
    // }

    //   if (publicBooth.data.info.whatsapp) {
    //     let number
    //     if (publicBooth.data.info.whatsapp.length === 10) {
    //       number = `90${publicBooth.data.info.whatsapp}`
    //     } else if (publicBooth.data.info.whatsapp.length === 12) {
    //       number = `${publicBooth.data.info.whatsapp}`
    //     }

    //     if (number) {
    //       hotspots.push({
    //         'icon': 'social/whatsapp',
    //         'bgcolor': 'green',
    //         'color': '#FFFFFF',
    //         'size': '32px',
    //         'top': '82%',
    //         'left': '72%',
    //         'text': 'Whatsapp',
    //         'outurl': `http://wa.me/${number}`
    //       })
    //     }
    //   }
  }

  if (publicBooth.data?.info?.booth_kind === 'Tip 1' || !publicBooth.data?.info?.booth_kind) {
    // if (publicBooth.data.info.website) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '13%',
    //     'left': '27%',
    //     'text': publicBooth.data.info.website,
    //     'outurl': `${publicBooth.data.info.website}`
    //   })
    // }

    if (publicBooth.data.info.youtube) {
      hotspots.push({
        'icon': 'square-new-window',
        'top': '14%',
        'left': '61%',
        // 'text': publicBooth.data.info.website,
        'url': `video`
      })
    }

    // if (publicBooth.data.info.calendlyurl) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '53%',
    //     'left': '71%',
    //     'text': 'Randevu alın',
    //     'outurl': publicBooth.data.info.calendlyurl
    //   })
    // }

    // if (publicBooth.data.info.google_meet) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '49%',
    //     'left': '32%',
    //     'text': 'Google Meet',
    //     'outurl': `${publicBooth.data.info.google_meet}`
    //   })
    // }

    // if (publicBooth.data.info.brochure) {
    //   publicBooth.data.info.brochure.forEach((b, dx) => {
    //     let top = 58 + (dx * 14)
    //     hotspots.push({
    //       'icon': 'square-new-window',
    //       'top': `${top}%`,
    //       'left': '11%',
    //       'text': 'Broşürümüzü indirin',
    //       'outurl': `/__reactor/api/files/${b}`
    //     })
    //   })
    // }

    // if (publicBooth.data.info.whatsapp) {
    //   let number
    //   if (publicBooth.data.info.whatsapp.length === 10) {
    //     number = `90${publicBooth.data.info.whatsapp}`
    //   } else if (publicBooth.data.info.whatsapp.length === 12) {
    //     number = `${publicBooth.data.info.whatsapp}`
    //   }

    //   if (number) {
    //     hotspots.push({
    //       'icon': 'social/whatsapp',
    //       'bgcolor': 'green',
    //       'color': '#FFFFFF',
    //       'size': '32px',
    //       'top': '82%',
    //       'left': '72%',
    //       'text': 'Whatsapp',
    //       'outurl': `http://wa.me/${number}`
    //     })
    //   }
    // }
  }

  if (publicBooth.data?.info?.booth_kind === 'Tip 2') {
    // if (publicBooth.data.info.website) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '23%',
    //     'left': '64%',
    //     'text': publicBooth.data.info.website,
    //     'outurl': `${publicBooth.data.info.website}`
    //   })
    // }

    if (publicBooth.data.info.youtube) {
      hotspots.push({
        'icon': 'square-new-window',
        'top': '13%',
        'left': '63%',
        // 'text': publicBooth.data.info.website,
        'url': `video`
      })
    }

    // if (publicBooth.data.info.calendlyurl) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '35%',
    //     'left': '37%',
    //     'text': 'Randevu alın',
    //     'outurl': publicBooth.data.info.calendlyurl
    //   })
    // }

    // if (publicBooth.data.info.google_meet) {
    //   hotspots.push({
    //     'icon': 'square-new-window',
    //     'top': '46%',
    //     'left': '29.5%',
    //     'text': 'Google Meet',
    //     'outurl': `${publicBooth.data.info.google_meet}`
    //   })
    // }

    // if (publicBooth.data.info.brochure) {
    //   publicBooth.data.info.brochure.forEach((b, dx) => {
    //     let top = 56 + (dx * 8)
    //     hotspots.push({
    //       'icon': 'square-new-window',
    //       'top': `${top}%`,
    //       'left': '72.5%',
    //       'text': 'Broşürümüzü indirin',
    //       'outurl': `/__reactor/api/files/${b}`
    //     })
    //   })
    // }

    // if (publicBooth.data.info.whatsapp) {
    //   let number
    //   if (publicBooth.data.info.whatsapp.length === 10) {
    //     number = `90${publicBooth.data.info.whatsapp}`
    //   } else if (publicBooth.data.info.whatsapp.length === 12) {
    //     number = `${publicBooth.data.info.whatsapp}`
    //   }

    //   if (number) {
    //     hotspots.push({
    //       'icon': 'social/whatsapp',
    //       'bgcolor': 'green',
    //       'color': '#FFFFFF',
    //       'size': '32px',
    //       'top': '82%',
    //       'left': '72%',
    //       'text': 'Whatsapp',
    //       'outurl': `http://wa.me/${number}`
    //     })
    //   }
    // }
  }

  let weburl = publicBooth.account.profile?.internet_sitesi
  if (weburl && weburl?.[6] != '/') weburl = `http://${weburl}`

  let calendlyUrl = publicBooth.account.profile?.calendly
  if (calendlyUrl && calendlyUrl?.[6] != '/') calendlyUrl = `http://${calendlyUrl}`

  return (
    <Box sx={{
      position: 'relative'
    }}>
      <Box sx={{
        position: 'fixed',
        width: '100vw',
        minHeight: '100vh',
        left: '0',
        top: '0',
        bg: 'rgba(0, 0, 0, 0.6)',
        zIndex: 99,
      }} />
      <Box sx={{
        position: 'fixed',
        width: ['100vw', null, null, '70vw'],
        maxHeight: '96vh',
        overflow: 'auto',
        left: [0, null, null, '15vw'],
        top: '2vh',
        bg: '#FFF',
        borderRadius: 4,
        p: 3,
        zIndex: 999,
        // overflow: 'hidden'
      }}>
        <Link to={backUrl} sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          zIndex: 9
        }}>
          <Icon name='circle-remove' color='brand.500' size={10} />
        </Link>
        {headerContent}
        <Box sx={{
          position: 'relative',
        }}>
          <Box as='img' src={publicBooth.image} sx={{
            width: '100%',
            // height: '100%',
          }} />
        </Box>

        <Box sx={{
          width: ['100%', null, null, '40vw'],
          mx: 'auto'
        }}>
          <Box sx={{
            width: '25%',
            bg: '#FFF',
            borderRadius: 6,
            mt: '-30px',
            zIndex: 9,
            position: 'relative',
            p: 4,
            mx: 4
          }}>
            <Box as='img' src={publicBooth.logo} sx={{
              width: '100%',
              // height: '100%',
            }} />
          </Box>
          <Flex sx={{
            justifyContent: 'space-between'
          }}>
            <Box sx={{
              ml: 5,
              fontWeight: 'bold'
            }}>
              {publicBooth.account.name}
            </Box>
            <Box>
              <Box as='a' target='_blank' color='brand.500' href={weburl}>
                {publicBooth.account.profile?.internet_sitesi}
              </Box>
            </Box>
          </Flex>

          <Flex sx={{
            mx: -1,
            ml: 4,
            mt: 3,
            flexWrap: 'wrap'
          }}>
            {publicBooth.account.profile?.faaliyet_alanlari?.map?.((f, dx) => {
              return (
                <Box key={dx} sx={{
                  mx: 1,
                  bg: 'dark.300',
                  color: '#FFF',
                  borderRadius: 20,
                  px: 3,
                  py: 1,
                  fontSize: 'sm'
                }}>
                  {f}
                </Box>
              )
            })}
          </Flex>

          <Box mx={4} my={4} >
            <Flex mx={-3} flexWrap='wrap' justifyContent='space-between'>
              {publicBooth.account.profile.whatsapp && (
                <Button as={'a'} colorScheme='brand' href={`http://wa.me/${publicBooth.account.profile.whatsapp}`} target='_blank' sx={{
                  flex: '0 0 45%',
                  mx: 3
                }}>
                  <Icon wrapperSx={{
                    mx: 'auto',
                    width: '45%'
                  }} icon='social/whatsapp'>Whatsapp</Icon>
                </Button>
              )}

              {publicBooth.account.profile.telefon && (
                <Button as={'a'} colorScheme='brand' href={`tel://${publicBooth.account.profile.telefon}`} target='_blank' sx={{
                  flex: '0 0 45%',
                  mx: 3
                }}>
                  <Icon wrapperSx={{
                    mx: 'auto',
                    width: '45%'
                  }} icon='mobile-phone'>{t('label.phonenumber')}</Icon>
                </Button>
              )}

              {publicBooth.account.profile.calendly && publicBooth.account.profile.calendly[6] == '/' && (
                <Button as={'a'} isFullWidth colorScheme='brand' href={`${calendlyUrl}`} target='_blank' sx={{
                  // display: 'block',
                  // flex: '0 0 100%',
                  mx: 3,
                  my: 4
                }}>
                  Randevu Al
                </Button>
              )}
            </Flex>
          </Box>

          <Box mx={4} my={4}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl'
            }}>
              {t('label.about_company')}
            </Box>
            {publicBooth.account.profile.firma_hakkinda}
          </Box>

          {publicBooth.account.brochures && publicBooth.account.brochures.length > 0 && <Box mx={4} my={4}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl'
            }}>
              Broşürler
            </Box>
            <Flex>
              {publicBooth.account.brochures.map((b, dx) => {
                return (
                  <Box as='a' target='_blank' href={b.url} key={dx} sx={{
                    flex: '0 0 30%',
                    display: 'block'
                  }}>
                    <Box as='img' src={b.url} />
                    <Box sx={{
                      mt: 2,
                      fontSize: 'sm',
                      textAlign: 'center'
                    }}>
                      {b.name}
                    </Box>
                  </Box>
                )
              })}
            </Flex>
          </Box>}
        </Box>

        <Hotspots hotspots={[
          ...hotspots,
          // {
          //   'icon': 'square-new-window',
          //   'top': '48%',
          //   'left': '13%',
          //   'kind': 'url',
          //   'url': './booth/19'
          // },

          // {
          //   'icon': 'square-new-window',
          //   'top': '29%',
          //   'left': '29%',
          //   'kind': 'url',
          //   'url': '../hall/1'
          // },

          // {
          //   'icon': 'square-new-window',
          //   'top': '51%',
          //   'left': '31%'
          // }
        ]} />

        {/* {params.boothId == 19 && (
        <Box sx={{
          position: 'absolute'
        }} as='iframe' width="560" height="315" src="https://www.youtube.com/embed/JQuOUpWUQ_o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
      )} */}

        {/* {params.boothId == 19 && (
        <Video />
      )} */}

        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 99999
        }}>
          <Routes>
            {publicBooth.data.info.youtube && <Route path='video' element={(
              <Portal><Box sx={{
                position: 'fixed',
                width: '96vw',
                height: '96vh',
                overflow: 'auto',
                left: '2vw',
                top: '2vh',
                bg: '#FFF',
                borderRadius: 4,
                p: 3,
                zIndex: 99991,
                // overflow: 'hidden'
              }}>
                <Link to={href} sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '20px',
                  zIndex: 9
                }}>
                  <Icon name='circle-remove' color='brand.500' size={10} />
                </Link>
                <Box as='iframe' sx={{
                  position: 'absolute',
                  top: '5vh',
                  left: '5vw',
                  width: '90vw',
                  height: '90vh',
                }} src={`//www.youtube.com/embed/${getId(publicBooth.data.info.youtube)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Box>
              </Box></Portal>
            )} />}

            {/* <Route path='calendly' element={(
            <Box>
              <Link to={'../'} sx={{
                position: 'absolute',
                top: '30px',
                right: '80px',
                zIndex: 9
              }}>
                <Icon name='circle-remove' color='brand.500' size={10} />
              </Link>
              <Box as='iframe' sx={{
                position: 'absolute',
                top: '5vh',
                left: '5vw',
                width: '90vw',
                height: '90vh',
              }} src={`//www.youtube.com/embed/${getId(publicBooth.data.info.youtube)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Box>
            </Box>
          )} /> */}
          </Routes>
        </Box>

      </Box>
    </Box>
  )
}

export default RnxEventBooth