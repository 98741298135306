import React from 'react'

// Reactor
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'

// Reactor Ui
import BareTabs from 'reactor-ui/components/BareTabs'

//
import { RnxEventContext } from '../eventContext'


const EventContacts = props => {
  const { rnxEvent } = React.useContext(RnxEventContext)
  console.log('rnxEvent', rnxEvent)
  return (
    <>
      <BareTabs
        isLazy
        tabs={[
          {
            name: 'Aktif',
            codeName: 'active',
            element: (
              <GraphTable
                modelName='RnxEventArea'
                detailLink='booth'
                headerText='Standlar'
                actionCtx={{
                  model: {
                    rnx_event_id: rnxEvent.id,
                    kind: 'BOOTH'
                  }
                }}
                query={{
                  filters: {
                    rnx_event_id: rnxEvent.id,
                    kind: 'BOOTH',
                    status: 'READY'
                  },
                  itemFields: ['name', 'status', 'ownerRnxEventAccount'],
                  columns: ['name', 'status', 'ownerRnxEventAccount'],
                }}
              />
            )
          },

          {
            name: 'Pasif',
            codeName: 'passive',
            element: (
              <GraphTable
                modelName='RnxEventArea'
                detailLink='booth'
                headerText='Standlar'
                actionCtx={{
                  model: {
                    rnx_event_id: rnxEvent.id,
                    kind: 'BOOTH'
                  }
                }}
                query={{
                  filters: {
                    rnx_event_id: rnxEvent.id,
                    kind: 'BOOTH',
                    status: ['WAITING', 'CLAIMED', 'IN_PROGRESS']
                  },
                  itemFields: ['name', 'status', 'ownerRnxEventAccount'],
                  columns: ['name', 'status', 'ownerRnxEventAccount'],
                }}
              />
            )
          },
        ]}
      />
    </>
  )
}

export default EventContacts