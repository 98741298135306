import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Vendor
import dayjs from 'dayjs'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Routes, Route, useParams, Link, useHref, useNavigate } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'

// ReactorUi
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'


const ProgramContent = props => {
  const { t } = useTranslation()
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang', 'tr')
  const params = useParams()
  const result = useReactorQuery({
    'publicEvent': {
      id: params.eventId,
      fields: [
        'activities',
        'url'
      ]
    },
  })

  const activities = result.graph?.publicEvent?.activities

  if (!activities) return null
  let lastDate

  // const actList = activities.after || activities.before

  return (
    <Box my={10} sx={{
      width: ['100%', null, null, '80%'],
      mx: 'auto'
    }}>
      {activities.after?.map((item, dx) => {
        let isNextDay
        let dts = dayjs(item.datetime_start).add(3, 'hours')
        if (lastDate) {
          const diff = dts.isAfter(lastDate, 'day')
          if (diff > 0) isNextDay = true
          console.log('deff', diff, dts.format('DD.MM.YYYY HH.mm'), lastDate.format('DD.MM.YYYY HH.mm'))
        }
        lastDate = dayjs(item.datetime_start).add(3, 'hours')
        return (
          <React.Fragment key={dx}>
            {isNextDay && (
              <Box sx={{
                borderTop: '3px solid #FFF',
                borderTopColor: 'brand.700'
              }}>

              </Box>
            )}
            <Activity lang={selectedLang} activity={item} event={result.graph?.publicEvent} />
          </React.Fragment>
        )
      })}

      {activities.before && (
        <Box>
          {t('label.previous_activities')}
          {
            activities.before?.map((item, dx) => {
              let isNextDay
              let dts = dayjs(item.datetime_start).add(3, 'hours')
              if (lastDate) {
                const diff = dts.isAfter(lastDate, 'day')
                if (diff > 0) isNextDay = true
                console.log('deff', diff, dts.format('DD.MM.YYYY HH.mm'), lastDate.format('DD.MM.YYYY HH.mm'))
              }
              lastDate = dayjs(item.datetime_start).add(3, 'hours')
              return (
                <React.Fragment key={dx}>
                  {isNextDay && (
                    <Box sx={{
                      borderTop: '3px solid #FFF',
                      borderTopColor: 'brand.700'
                    }}>

                    </Box>
                  )}
                  <Activity lang={selectedLang} activity={item} event={result.graph?.publicEvent} isPast />
                </React.Fragment>
              )
            })
          }
        </Box>
      )}
    </Box>
  )
}

const Activity = ({
  activity,
  event,
  lang,
  isPast
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(false)
  const dts = dayjs(activity.datetime_start).add(3, 'hours')
  const dtf = dayjs(activity.datetime_finish).add(3, 'hours')

  const name = activity.data?.rcEav?.default?.values?.translations?.[lang]?.name || activity.name
  const description = activity.data?.rcEav?.default?.values?.translations?.[lang]?.description || activity.data?.rcEav?.default?.values?.description
  const info = activity.data?.rcEav?.default?.values?.translations?.[lang]?.info || activity.data?.rcEav?.default?.values?.info
  return (
    <>
      <Flex sx={{
        alignItems: 'center',
        // my: [8, null, null, null, 4],
        my: 4,
        // flexDirection: ['column', null, null, null, 'row']
      }}>
        <Box sx={{
          textAlign: 'center',
          p: 4,
          bg: 'brand.500',
          color: '#FFF',
          borderRadius: 4,
          // mr: [0, null, null, null, 4]
          mr: [4]
        }}>
          <Box>
            {dts.format('DD')}
          </Box>

          <Box>
            {dts.format('MMMM')}
          </Box>
        </Box>

        <Box sx={{
          width: ['auto', null, null, '500px']
        }}>
          <Box sx={{
            fontSize: 'xl',
            fontWeight: 'bold'
          }}>
            {name}
          </Box>

          {description && <Box sx={{
            fontSize: 'sm',
            fontWeight: 'dark.400'
          }}>
            {description}
          </Box>}

          {activity.data?.rcEav?.default?.values?.video_url && (
            <Box>
              <VideoModal url={activity.data?.rcEav?.default?.values?.video_url} />
            </Box>
          )}

          <Flex sx={{
            mt: 2
          }}>
            <Icon name='clock'>
              {dts.format('HH:mm')} - {dtf.format('HH:mm')} (UTC+3)
            </Icon>
            {info && (
              <Box as='a' ml={4} onClick={() => setIsOpen(v => !v)} color='brand.500'>
                {t('label.details')}
              </Box>
            )}
          </Flex>
        </Box>

        {!isPast && activity.data?.rcEav?.default?.values?.config?.zoom_id && (
          <Box mx={6}>
            <Button as={Link} to={`${event.url}/activity/${activity.id}`} target='_blank' isFullWidth colorScheme='brand'>
              {t('btn.join')}
            </Button>

            <Button sx={{
              textTransform: 'capitalize',
              mt: 2
            }} as={'a'} href={`https://us06web.zoom.us/j/${activity.data?.rcEav?.default?.values?.config.zoom_id}?pwd=${activity.data?.rcEav?.default?.values?.config.zoom_password || ''}`} target='_blank' isFullWidth colorScheme='brandLight'>
              {t('btn.join_to_zoom')}
            </Button>
          </Box>
        )}

        {!isPast && activity.data?.rcEav?.default?.values?.config?.url && (
          <Box mx={6}>
            <Button sx={{
              textTransform: 'capitalize',
              mt: 2
            }} as={'a'} href={activity.data?.rcEav?.default?.values?.config?.url} target='_blank' isFullWidth colorScheme='brandLight'>
              {t('btn.watch')}
            </Button>
          </Box>
        )}
      </Flex>
      {isOpen && (
        <Box sx={{
          whiteSpace: 'pre-line',
          p: 4,
          borderLeft: '3px solid #FFF',
          borderLeftColor: 'brand.500',
          ml: 14
        }}>
          {info}
        </Box>
      )}
    </>
  )
}

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

import { Portal } from "@chakra-ui/react"

const VideoModal = ({
  url
}) => {
  const [open, setOpen] = React.useState()
  const { t } = useTranslation()
  return (
    <>
      <Box as='a' onClick={() => setOpen(true)} color='brand.500'>
        <Icon name='video'>
          {t('txt.watch_video')}
        </Icon>
      </Box>
      {open && <Portal><Box sx={{
        position: 'fixed',
        width: '96vw',
        height: '96vh',
        overflow: 'auto',
        left: '2vw',
        top: '2vh',
        bg: '#FFF',
        borderRadius: 4,
        p: 3,
        zIndex: 99991,
        // overflow: 'hidden'
      }}>
        <Box onClick={() => setOpen(false)} as='a' sx={{
          position: 'absolute',
          top: '5px',
          right: '20px',
          zIndex: 9
        }}>
          <Icon name='circle-remove' color='brand.500' size={10} />
        </Box>
        <Box as='iframe' sx={{
          position: 'absolute',
          top: '5vh',
          left: '5vw',
          width: '90vw',
          height: '90vh',
        }} src={`//www.youtube.com/embed/${getId(url)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Box>
      </Box></Portal>}
    </>
  )
}

export default ProgramContent