import React from 'react'

// Vendor
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import { useField } from 'react-final-form'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import DateInput from 'reactor-ui/components/DateInput'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfDateField = ({ name, field }) => {
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })

  return (
    <>
      <DateInput
        value={input.value}
        onChange={input.onChange}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        label={field.label}
      />
      <RfFieldAlert meta={meta} />
      <RfFieldDescription field={field} />
    </>
  )
}

export default RfDateField