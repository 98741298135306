import Projects from './pages/Projects'


export default {
  name: 'RnxContentProject',
  components: {
    'Projects': {
      component: Projects
    },
  }
}