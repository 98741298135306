import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import { Routes, Route, useParams, Link, useNavigate } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useReactorAction from 'reactor/hooks/useReactorAction'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

import RnxEventVisitorProfile from './RnxEventVisitorProfile'


const RnxEventAttendees = ({
  publicEvent
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: publicEvent.eventId,
      kind: 'EXHIBITION_HALL',
      fields: [
        'name', 'image', 'data'
      ]
    },
  })
  const publicEventArea = result.graph?.publicEventArea
  console.log('event', publicEvent)
  console.log('publicEventArea', publicEventArea)
  if (!publicEventArea) return null
  console.log(publicEventArea.image)
  return <RnxEventExhibitionHallContent publicEvent={publicEvent} publicEventArea={publicEventArea} />
}

const RnxEventExhibitionHallContent = ({
  publicEventArea,
  publicEvent
}) => {
  const params = useParams()
  const dispatchAction = useReactorAction('RnxEvent.TrackVisit')
  // React.useEffect(() => {
  //   dispatchAction({
  //     'kind': 'EXHIBITION_HALL',
  //     'id': publicEventArea.id
  //   })
  // }, [])

  return (
    <Box sx={{
      // background: `url('${publicEventArea.image}') top center no-repeat`,
      // backgroundSize: 'cover',
      // backgroundSize: 'contain',
      // width: '100vw',
      // minWidth: 1000,
      // minHeight: 800,
      // height: '100vh',
      position: 'relative'
    }}>
      <Box as='img' src={publicEventArea.image} sx={{
        width: '100%',
        height: '100%',
      }} />
      <Box sx={{
        p: 4,
        position: 'fixed',
        top: 0,
        width: '100%'
      }}>
        <Flex>
          <Link to='../lobby'>
            <Box as='img' src={publicEvent.logo} sx={{
              width: 150
            }} />
          </Link>
        </Flex>
      </Box>

      <Sponsors />

      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 99999
      }}>
        <Routes>
          <Route path='profile/:profileId/*' element={<RnxEventVisitorProfile publicEvent={publicEvent} />} />
        </Routes>
      </Box>
    </Box>
  )
}


import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "reactor-ui/components/Modal"

import AttendeeContent from '../components/AttendeeContent'

const Sponsors = ({

}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onClose()
        navigate('../exhibition-hall')
      }} size='full' sx={{
        // minHeight: 'fit-content'
      }}>
        <ModalOverlay sx={{}} />
        <ModalContent sx={{
          width: ['100%', null, null, '85%'],
          my: '90px',
          minHeight: 'fit-content'
        }}>
          <ModalCloseButton />
          <ModalBody sx={{
            minHeight: 'fit-content',
          }}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl',
              color: 'brand.500',
              textTransform: 'capitalize'
            }}>
              {t('label.attendees')}
            </Box>
            <AttendeeContent />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RnxEventAttendees