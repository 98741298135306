import React from 'react'

import sortBy from 'lodash/sortBy'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Routes, Route, useParams, Link, useHref, useNavigate } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

const AttendeeContent = ({
  onClose
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicEvent': {
      id: params.eventId,
      fields: [
        'attendees', 'url'
      ]
    },
  })

  const contacts = result.graph?.publicEvent?.attendees?.contacts
  const url = result.graph?.publicEvent?.url
  console.log('contacts', contacts, url)
  if (!contacts) return null
  return (
    <Flex sx={{
      flexWrap: 'wrap',
      mx: -4
    }}>
      {contacts.map((contact, dx) => {
        return (
          <Link onClick={onClose} to={`${url}/attendees/profile/${contact.id}`} key={dx} sx={{
            mx: 4,
            border: '1px solid #FFF',
            borderColor: 'brand.500',
            flex: '0 0 150px',
            width: '150px',
            px: 3,
            my: 4,
            py: 2,
            borderRadius: 4,
            textAlign: 'center'
          }}>
            <Icon name='user' sx={{
              mx: 'auto'
            }} size={20} color='brand.500'/>

            <Box sx={{
              fontWeight: 'bold'
            }}>
            {contact.name}
            </Box>

            <Box sx={{
              fontSize: 'sm'
            }}>
            {contact.rnx_event_account?.name || contact.data?.rcEav?.default?.values?.infos?.company}
            </Box>

            <Box sx={{
              fontSize: 'sm'
            }}>
            {contact.data?.rcEav?.default?.values?.infos?.position}
            </Box>
          </Link>
        )
      })}
    </Flex>
  )
}

export default AttendeeContent