import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ActionGroup, { ActionGroupMenu } from 'reactor-vera/apps/data/components/ActionGroup'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import Content from 'reactor-ui/components/Content'
import Enum from 'reactor-vera/apps/model/components/Enum'


const EventTemplateManagement = props => {
  return (
    <Routes>
      <Route path='/' element={<EventTemplateHome />} />
      <Route path='template/:rnxEventTemplateId/*' element={<EventAreaTemplateDetailRoutes />} />
    </Routes>
  )
}

const EventTemplateHome = props => {
  const { t } = useTranslation()
  return (
    <>
      <GraphTable
        modelName='RnxEventTemplate'
        query={{
          systemQuery: true,
        }}
        detailLink='template'
      />
    </>
  )
}

const EventAreaTemplateDetailRoutes = props => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEventTemplate': {
      filters: {
        id: params.rnxEventTemplateId
      },
      // fields: [
      //   'name', 'kind', 'parent', 'allowedSubAreas', { 'rcImageTemplate': { fields: ['name', 'kind', 'baseImage'] } }, 'hotSpots', 'settings'
      // ]
    },
  })

  useBreadcrumb(`rnxEventTemplateDetail:${result.graph?.rnxEventTemplate.id}`, result.graph?.rnxEventTemplate.name)

  if (!result.graph?.rnxEventTemplate) return null
  return (
    <Routes>
      <Route path='/' element={<EventTemplateDetail result={result} />} />
      {/* <Route path='template/:rnxEventTemplateId/*' element={<EventAreaTemplateDetailRoutes />} /> */}
    </Routes>
  )
}

const EventTemplateDetail = ({
  result
}) => {
  const rnxEventTemplate = result.graph?.rnxEventTemplate
  const meta = result.graph?.rnxEventTemplateMeta

  if (!rnxEventTemplate) return null
  console.log('rnxEventTemplate', rnxEventTemplate)

  return (
    <>
      <ModelDetailCard
        icon={'book-open'}
        entity={rnxEventTemplate}
        schema={result.schema?.rnxEventTemplate}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <AccountProfiles rnxEventTemplate={rnxEventTemplate} />

      <ContactProfiles rnxEventTemplate={rnxEventTemplate} />
    </>
  )
}

const AccountProfiles = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEventTemplate': {
      filters: {
        id: params.rnxEventAreaTemplateId
      },
      fields: [
        'accountProfiles'
      ]
    },
  })

  const rnxEventTemplate = result.graph?.rnxEventTemplate

  if (!rnxEventTemplate) return null

  return (
    <Card my={4}>
      <Card.Header>Hesap Profilleri</Card.Header>

      {rnxEventTemplate.accountProfiles && (
        Object.entries(rnxEventTemplate.accountProfiles).map(([codeName, config]) => {
          return (
            <Box key={codeName}>
              <Icon name='kiosk' color='brand.900' my={3} >
                {config.name}
                <ActionGroupMenu sx={{ml: 2}} name={'AccountProfile'} contexts={['Entity']} kinds={['CREATE', 'UPDATE', 'DELETE']} ctx={{ id: rnxEventTemplate.id, codeName: codeName }} />
              </Icon>

              <Content ml={6}>
                <Content.Header>
                  Diller
                </Content.Header>
                {config.languages && Object.entries(config.languages).map(([key, lang], dx) => (
                  <Flex key={dx} my={1}>
                    <Enum value={lang.language}/>
                    {key != config.defaultLanguage && <ActionGroupMenu sx={{ml: 2}} name={'AccountProfile'} contexts={['Entity.Language']} kinds={['CREATE', 'UPDATE', 'DELETE']} ctx={{ id: rnxEventTemplate.id, codeName: codeName, language: key }} />}
                  </Flex>
                ))}
              </Content>
            </Box>
          )
        })
      )}
    </Card>
  )
}

const ContactProfiles = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEventTemplate': {
      filters: {
        id: params.rnxEventAreaTemplateId
      },
      fields: [
        'contactProfiles'
      ]
    },
  })

  const rnxEventTemplate = result.graph?.rnxEventTemplate

  if (!rnxEventTemplate) return null

  return (
    <Card my={4}>
      <Card.Header>Kişi Profilleri</Card.Header>

      {rnxEventTemplate.contactProfiles && (
        Object.entries(rnxEventTemplate.contactProfiles).map(([codeName, config]) => {
          return (
            <Box key={codeName}>
              <Icon name='user' color='brand.900' my={3} >
                {config.name}
                <ActionGroupMenu sx={{ml: 2}} name={'ContactProfile'} contexts={['Entity']} kinds={['CREATE', 'UPDATE', 'DELETE']} ctx={{ id: rnxEventTemplate.id, codeName: codeName }} />
              </Icon>

              <Content ml={6}>
                <Content.Header>
                  Diller
                </Content.Header>
                {config.languages && Object.entries(config.languages).map(([key, lang], dx) => (
                  <Flex key={dx} my={1}>
                    <Enum value={lang.language}/>
                    {key != config.defaultLanguage && <ActionGroupMenu sx={{ml: 2}} name={'ContactProfile'} contexts={['Entity.Language']} kinds={['CREATE', 'UPDATE', 'DELETE']} ctx={{ id: rnxEventTemplate.id, codeName: codeName, language: key }} />}
                  </Flex>
                ))}
              </Content>
            </Box>
          )
        })
      )}
    </Card>
  )
}

export default EventTemplateManagement