import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Image from 'reactor-ui/components/Image'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'

// Reactor
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import useModelListReactorQuery from 'reactor/hooks/useModelListReactorQuery'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ActionGroup, { ActionGroupMenu } from 'reactor-vera/apps/data/components/ActionGroup'
import Enum from 'reactor-vera/apps/model/components/Enum'
import InfoPill from 'reactor-vera/apps/data/components/InfoPill'


const AreaTemplateManagement = props => {
  return (
    <Routes>
      <Route path='/' element={<AreaTemplateHome />} />
      <Route path='areaTemplate/:rnxEventAreaTemplateId/*' element={<AreaTemplateDetailRoutes />} />
    </Routes>
  )
}

const AreaTemplateDetailRoutes = props => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEventAreaTemplate': {
      filters: {
        id: params.rnxEventAreaTemplateId
      },
      fields: [
        'name', 'kind', 'parent', 'allowedSubAreas', { 'rcImageTemplate': { fields: ['name', 'kind', 'baseImage'] } }, 'hotSpots', 'settings'
      ]
    },
  })

  useBreadcrumb(`rnxEventAreaTemplateDetail:${result.graph?.rnxEventAreaTemplate.id}`, result.graph?.rnxEventAreaTemplate.name)

  if (!result.graph?.rnxEventAreaTemplate) return null
  return (
    <Routes>
      <Route path='/' element={<AreaTemplateDetail result={result} />} />
      <Route path='areaTemplate/:rnxEventAreaTemplateId/*' element={<AreaTemplateDetailRoutes />} />
    </Routes>
  )
}

const AreaTemplateHome = props => {
  const { t } = useTranslation()
  return (
    <>
      <GraphTable
        modelName='RnxEventAreaTemplate'
        query={{
          systemQuery: true,
          filters: {
            'depth': 0
          }
        }}
        detailLink='areaTemplate'
      />
    </>
  )
}

const AreaTemplateDetail = ({
  result
}) => {
  const rnxEventAreaTemplate = result.graph?.rnxEventAreaTemplate
  const meta = result.graph?.rnxEventAreaTemplateMeta

  if (!rnxEventAreaTemplate) return null
  console.log('rnxEventAreaTemplate', rnxEventAreaTemplate)

  return (
    <>
      <EntityDetail
        modelName='RnxEventAreaTemplate'
        icon={'book-open'}
        entity={rnxEventAreaTemplate}
        schema={result.schema?.rnxEventAreaTemplate}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      {/* <ModelDetailCard
        modelName='RnxEventAreaTemplate'
        icon={'book-open'}
        entity={rnxEventAreaTemplate}
        schema={result.schema?.rnxEventAreaTemplate}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      /> */}

      {rnxEventAreaTemplate.rcImageTemplate ? (
        <Flex>

          <Box flex='1 1 0'>
            <AreaSubAreas rnxEventAreaTemplate={rnxEventAreaTemplate} />
            {/* <AreaHotSpots rnxEventAreaTemplate={rnxEventAreaTemplate} /> */}
            <AreaSettings rnxEventAreaTemplate={rnxEventAreaTemplate} />
          </Box>

          <Box flex='1 1 0' m={6} mr={0}>
            <Image sx={{ width: '100%' }} src={rnxEventAreaTemplate.rcImageTemplate?.baseImage?.url} />
          </Box>

        </Flex>
      ) : (
        <>
          <AreaSubAreas rnxEventAreaTemplate={rnxEventAreaTemplate} />
          <AreaHotSpots rnxEventAreaTemplate={rnxEventAreaTemplate} />
          <AreaSettings rnxEventAreaTemplate={rnxEventAreaTemplate} />
          {rnxEventAreaTemplate.kind.value === 'FAIR_AND_CONGRESS_CENTER' && (
            <>
              {/* <AreaContactProfiles rnxEventAreaTemplate={rnxEventAreaTemplate} /> */}
              <AreaBoothKinds rnxEventAreaTemplate={rnxEventAreaTemplate} />
            </>
          )}
        </>
      )}

    </>
  )
}

const AreaBoothKinds = ({
  rnxEventAreaTemplate
}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'fairAndCongressCenterAreaTemplate': {
      filters: {
        id: params.rnxEventAreaTemplateId
      },
      fields: [
        'boothKinds'
      ]
    },
  })

  const fairAndCongressCenterAreaTemplate = result.graph?.fairAndCongressCenterAreaTemplate

  if (!fairAndCongressCenterAreaTemplate) return null

  return (
    <Card my={4}>
      <Card.Header>
        Stand Türleri
      </Card.Header>

      {fairAndCongressCenterAreaTemplate.boothKinds && (
        Object.entries(fairAndCongressCenterAreaTemplate.boothKinds).map(([codeName, config]) => {
          return (
            <Icon name='kiosk' color='brand.900' my={3} key={codeName}>
              {config.name}
              <Button icon='pencil' ml={4} color='brand.900' />
            </Icon>
          )
        })
      )}
    </Card>
  )
}

const AreaSubAreas = ({
  rnxEventAreaTemplate
}) => {
  const { t } = useTranslation()
  const params = useParams()

  if (!rnxEventAreaTemplate.allowedSubAreas || rnxEventAreaTemplate.allowedSubAreas.length === 0) return null

  // const [result, graphKey, filterInstance] = useModelListReactorQuery({
  //   modelName: 'rnxEventAreaTemplate',
  //   query: {
  //     filters: {
  //       parent_id: params.rnxEventAreaTemplateId
  //     },
  //   }
  // })
  // console.log('--r', result)
  return (
    <Box sx={{
      my: 6
    }}>
      <GraphTable
        modelName='RnxEventAreaTemplate'
        headerText='Alt alanlar'
        query={{
          systemQuery: true,
          filters: {
            'parent_id': params.rnxEventAreaTemplateId
          }
        }}
        showModelActions={false}
        elements={{
          beforeTable: (
            <Flex sx={{ mx: -4, py: 4, bg: 'info.500' }}>
              <Box mx={4}>
                İzin verilen alt alanlar:
              </Box>
              {rnxEventAreaTemplate.allowedSubAreas.map((name, dx) => {
                return (
                  <Box key={dx} sx={{
                    textTransform: 'capitalize',
                    mx: 4
                  }}>
                    <Enum value={name} />
                  </Box>
                )
              })}
            </Flex>
          )
        }}
        detailLink='areaTemplate'
      />
    </Box>
  )
}

const AreaHotSpots = ({
  rnxEventAreaTemplate
}) => {
  if (!rnxEventAreaTemplate.rcImageTemplate) return null
  return (
    <Box>
      Hotspots
      {rnxEventAreaTemplate.hotSpots && (
        Object.entries(rnxEventAreaTemplate.hotSpots).map(([codeName, config]) => {
          return (
            <Flex key={codeName} justifyContent='space-between'>
              {config.name}
              <ActionGroupMenu name={'RnxEventAreaTemplate'} contexts={['Entity']} kinds={['UPDATE', 'DELETE']} ctx={{ codeName: codeName, id: rnxEventAreaTemplate.id }} />
            </Flex>
          )
        })
      )}
    </Box>
  )
}

const AreaSettings = ({
  rnxEventAreaTemplate
}) => {
  // if (!rnxEventAreaTemplate.rcImageTemplate) return null
  const { t } = useTranslation()
  return (
    <Card>
      <Card.Header>Ayarlar</Card.Header>
      {rnxEventAreaTemplate.settings && (
        Object.entries(rnxEventAreaTemplate.settings).map(([codeName, config]) => {
          console.log(config)

          return (
            <Flex key={codeName} justifyContent='space-between' sx={{
              my: 4
            }}>
              <Box>
                <Icon name='asterisk'>
                  <Flex sx={{
                    alignItems: 'center'
                  }}>
                    <Box sx={{
                      // fontWeight: 'bold'
                    }}>
                      {config.name}
                    </Box>
                    <ActionGroupMenu sx={{ml: 4}} name={'RnxEventAreaTemplate'} contexts={['Entity.ImageSetting']} ctx={{ id: rnxEventAreaTemplate.id, codeName: codeName }} />
                  </Flex>

                  <Box sx={{
                    fontSize: 'sm',
                    color: 'dark.300'
                  }}>
                    {config.options?.description}
                  </Box>

                  <Flex sx={{
                    alignItems: 'center',
                    mx: -2,
                    mt: 1
                  }}>
                    <InfoPill sx={{ mx: 2 }} header={'Tür'} value={t(`enum.${config.kind.toLowerCase()}`)} />
                    <InfoPill sx={{ mx: 2 }} header={'Alan'} value={config.options?.area} />
                  </Flex>
                </Icon>
              </Box>
              {/* <ActionGroupMenu name={'RnxEventAreaTemplate'} contexts={['Entity']} kinds={['UPDATE', 'DELETE']} ctx={{ codeName: codeName, id: rnxEventAreaTemplate.id }} /> */}
            </Flex>
          )
        })
      )}
    </Card>
  )
}


const AreaContactProfiles = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'fairAndCongressCenterAreaTemplate': {
      filters: {
        id: params.rnxEventAreaTemplateId
      },
      fields: [
        'contactProfiles'
      ]
    },
  })

  const fairAndCongressCenterAreaTemplate = result.graph?.fairAndCongressCenterAreaTemplate

  if (!fairAndCongressCenterAreaTemplate) return null

  return (
    <Box>
      Profiller

      {fairAndCongressCenterAreaTemplate.contactProfiles && (
        Object.entries(fairAndCongressCenterAreaTemplate.contactProfiles).map(([codeName, config]) => {
          return (
            <Flex key={codeName} justifyContent='space-between'>
              {config.name}
            </Flex>
          )
        })
      )}
    </Box>
  )
}

export default AreaTemplateManagement