import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import { Routes, Route, useParams, Link, useHref } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useReactorAction from 'reactor/hooks/useReactorAction'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'

import ActionButton from 'reactor-vera/apps/data/components/ActionButton'


const RnxEventVisitorProfile = ({
  backUrl,
  asContact
}) => {
  // const dispatchAction = useReactorAction('RnxEvent.TrackVisit')
  // React.useEffect(() => {
  //   params.boothId && dispatchAction({
  //     'kind': 'BOOTH',
  //     'id': params.boothId
  //   })
  // }, [])
  const { t } = useTranslation()
  const params = useParams()
  let queryParams = {}
  if (asContact) {
    queryParams = { contactId: params.profileId }
  } else {
    queryParams = { id: params.profileId }
  }
  const result = useReactorQuery({
    'publicVisitor': {
      ...queryParams,
      eventId: params.eventId,
      fields: [
        'name', 'account', 'connectionStatus', 'infos'
      ]
    },
  })
  const publicVisitor = result.graph?.publicVisitor
  console.log('publicVisitor', publicVisitor)
  if (!publicVisitor) return null

  return (
    <Box sx={{
      position: 'relative'
    }}>
      <Box sx={{
        position: 'fixed',
        width: '100vw',
        minHeight: '100vh',
        left: '0',
        top: '0',
        bg: 'rgba(0, 0, 0, 0.6)',
        zIndex: 9998,
      }} />
      <Box sx={{
        position: 'fixed',
        width: ['90vw', null, null, '40vw'],
        maxHeight: '96vh',
        overflow: 'auto',
        left: ['5vw', null, null, '30vw'],
        top: ['5vh', null, null, '10vh'],
        bg: '#FFF',
        borderRadius: 4,
        p: 3,
        zIndex: 9999,
        // overflow: 'hidden'
      }}>
        <Link to={backUrl || '../../'} sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 9999999
        }}>
          <Icon name='circle-remove' color='brand.100' size={10} />
        </Link>
        <Box sx={{
          bg: 'brand.500',
          height: '100px',
          m: -3
        }} />
        <Box sx={{
          width: '25%',
          bg: '#FFF',
          borderRadius: 6,
          mt: '-30px',
          zIndex: 9,
          position: 'relative',
          p: 4,
          mx: 4
        }}>
          <Icon color='brand.500' size={50} wrapperSx={{ mx: 'auto', width: 50 }} icon='user-group' />
        </Box>

        <Box sx={{
          mx: 4
        }}>
          <Flex sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'lg'
            }}>
              {publicVisitor.name}
            </Box>

            {!publicVisitor.connectionStatus && (
              <ActionButton content={{
                success: (
                  <Box>
                    {t('msg.network_request_send_successfully')}
                  </Box>
                ),
                error: (
                  <Box>
                    {t('msg.network_request_error')}
                  </Box>
                )
              }} sx={{ textTransform: 'capitalize' }} callbacks={{
                success: result.refresh
              }} data={{
                id: params.profileId,
                rnxEventPublicId: params.eventId
              }} actionIdentifier='RnxEvent.SendConnectionRequest'>
                {t('btn.send_connection_request')}
              </ActionButton>
            )}

            {publicVisitor.connectionStatus?.status === 'REQUEST' && (
              <Box sx={{
                color: 'brand.500'
              }}>
                {publicVisitor.connectionStatus.isRequestedByUser ? (
                  <Icon name='clock'>
                    İsteğiniz onay bekliyor
                  </Icon>
                ) : (
                  <Flex sx={{
                    mx: -2
                  }}>
                    <ActionButton content={{
                      success: (
                        <Box sx={{ textTransform: 'capitalize' }}>
                          {t('msg.accepted')}
                        </Box>
                      ),
                      error: (
                        <Box sx={{ textTransform: 'capitalize' }}>
                          {t('msg.error')}
                        </Box>
                      )
                    }} sx={{ mx: 2, textTransform: 'capitalize' }} callbacks={{
                      success: result.refresh
                    }} data={{
                      id: publicVisitor.connectionStatus.id,
                      rnxEventPublicId: params.eventId
                    }} actionIdentifier='RnxEvent.AcceptConnectionRequest'>
                      <Icon name='check' color='success.500' />
                    </ActionButton>

                    <ActionButton content={{
                      success: (
                        <Box sx={{ textTransform: 'capitalize' }}>
                          {t('msg.rejected')}
                        </Box>
                      ),
                      error: (
                        <Box sx={{ textTransform: 'capitalize' }}>
                          {t('msg.error')}
                        </Box>
                      )
                    }} sx={{ mx: 2, textTransform: 'capitalize' }} callbacks={{
                      success: result.refresh
                    }} data={{
                      id: publicVisitor.connectionStatus.id,
                      rnxEventPublicId: params.eventId
                    }} actionIdentifier='RnxEvent.RejectConnectionRequest'>
                      <Icon name='circle-remove' color='warning.500' />
                    </ActionButton>
                  </Flex>
                )}
              </Box>
            )}

            {publicVisitor.connectionStatus?.status === 'ACTIVE' && (
              <Flex sx={{
                alignItems: 'center',
                mx: -2
              }}>
                <Box sx={{
                  color: 'brand.500',
                  textTransform: 'capitalize',
                  mx: 2
                }}>
                  <Icon name='check'>
                    {t('txt.connected')}
                  </Icon>
                </Box>
                <ActionButton content={{
                  success: (
                    <Box sx={{ textTransform: 'capitalize' }}>
                      {t('msg.rejected')}
                    </Box>
                  ),
                  error: (
                    <Box sx={{ textTransform: 'capitalize' }}>
                      {t('msg.error')}
                    </Box>
                  )
                }} sx={{ mx: 2, textTransform: 'capitalize' }} callbacks={{
                  success: result.refresh
                }} data={{
                  id: publicVisitor.connectionStatus.id,
                  rnxEventPublicId: params.eventId
                }} actionIdentifier='RnxEvent.CancelConnection'>
                  <Icon name='circle-remove' color='warning.500' />
                </ActionButton>
              </Flex>
            )}
          </Flex>

          <Icon wrapperSx={{ mt: 2 }} name='bank' color='brand.500'>
            {publicVisitor.infos?.company}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='presentation' color='brand.500'>
            {publicVisitor.infos?.position}
          </Icon>

          <Icon wrapperSx={{ mt: 2, textTransform: 'capitalize' }} name='calendar' color='brand.500'>
            {publicVisitor.infos?.calendly && publicVisitor.infos?.calendly[6] == '/' && (
              <Box as='a' sx={{ color: 'brand.500' }} target='_blank' href={publicVisitor.infos?.calendly}>{t('btn.create_appointment')}</Box>
            )}
          </Icon>
        </Box>


      </Box>
    </Box>
  )
}

export default RnxEventVisitorProfile