import React from 'react'

// Reactor
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useReactorAction from 'reactor/hooks/useReactorAction'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

import Hotspots from '../components/Hotspots'
import RnxEventBooth from './RnxEventBooth'


const RnxEventBoothHall = ({
  publicEvent
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: publicEvent.eventId,
      kind: 'BOOTH_HALL',
      order: params.hallId,
      fields: [
        'name', 'image', 'data'
      ]
    },
  })
  const publicEventArea = result.graph?.publicEventArea
  console.log('event', publicEvent)
  console.log('publicEventArea', publicEventArea)
  if (!publicEventArea) return null

  return <RnxEventExhibitionHallContent publicEvent={publicEvent} publicEventArea={publicEventArea} />
}

const RnxEventExhibitionHallContent = ({
  publicEventArea,
  publicEvent
}) => {
  const params = useParams()
  const dispatchAction = useReactorAction('RnxEvent.TrackVisit')
  React.useEffect(() => {
    dispatchAction({
      'kind': 'EXHIBITION_HALL',
      'id': publicEventArea.id
    })
  }, [])

  const booths = publicEventArea.data?.booths
  const hotspots = []
  if (booths) {
    Object.entries(booths).forEach(([key, value]) => {
      hotspots.push({
        'icon': 'square-new-window',
        'top': `${(value.position.y) / 2160 * 100}%`,
        'left': `${(parseInt(value.position.x) + parseInt(value.size.x)*0.7) / 3840 * 100}%`,
        'kind': 'url',
        'text': value.name,
        'url': `./booth/${value.id}`
      })
    })
  }

  console.log('hotspots', hotspots)

  return (
    <Box sx={{
      // background: `url('${publicEventArea.image}') top center no-repeat`,
      // backgroundSize: 'cover',
      // backgroundSize: 'contain',
      // width: '100vw',
      // minWidth: 1000,
      // minHeight: 800,
      // height: '100vh',
      position: 'relative'
    }}>
      <Box as='img' src={publicEventArea.image} sx={{
        width: '100%',
        height: '100%',
      }} />
      <Box sx={{
        p: 4,
        position: 'fixed',
        top: 0,
        width: '100%'
      }}>
        <Flex>
          <Link to='../../lobby'>
            <Box as='img' src={publicEvent.logo} sx={{
              width: 150
            }} />
          </Link>
        </Flex>
      </Box>

      <Hotspots hotspots={[
        ...hotspots,
        // {
        //   'icon': 'square-new-window',
        //   'top': '48%',
        //   'left': '13%',
        //   'kind': 'url',
        //   'url': './booth/19'
        // },

        // {
        //   'icon': 'square-new-window',
        //   'top': '29%',
        //   'left': '29%',
        //   'kind': 'url',
        //   'url': '../hall/1'
        // },

        // {
        //   'icon': 'square-new-window',
        //   'top': '51%',
        //   'left': '31%'
        // }
      ]} />
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 999
      }}>
        <Routes>
          <Route path='booth/:boothId*' element={<RnxEventBooth publicEvent={publicEvent} />} />
        </Routes>
      </Box>
    </Box>
  )
}

export default RnxEventBoothHall