import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

// Link
import { Link } from 'reactor-vera/router'


const Breadcrumb = ({
  sx
}) => {
  const activeZone = useSelector(state => state.reactorVera.zones[state.reactorVera.activeZoneName])
  const activeDomainName = useSelector(state => state.reactorVera.activeDomainName)
  const activeDomain = activeZone?.domains.find(domain => domain.name == activeDomainName)
  const activeTenantId = useSelector(state => state.reactor.activeTenantId)
  const breadcrumb = useSelector(state => state.reactorVera.breadcrumbs)

  return (
    <Flex sx={{
      variant: 'layout.content.breadcrumb',
      fontSize: 'sm',
      alignItems: 'center',
      color: 'dark.300',
      // fontWeight: 'bold',
      ...sx
    }}>
      {activeDomain && (
        <><Link to={`/${activeTenantId}/${activeZone.path}/${activeDomain.indexPath}/`}>{activeDomain.name}</Link> <Icon inline size='10px' sx={{ mx: 1 }} name='chevron-right' /></>
      )}
      {breadcrumb?.map((item, dx) => (
        <React.Fragment key={dx}>
          {dx !== breadcrumb.length - 1 ? <Link to={item.url} sx={{ textTransform: 'capitalize' }}>{item.value}</Link> : <Box as='span' sx={{ textTransform: 'capitalize' }}>{item.value}</Box>}
          {dx !== breadcrumb.length - 1 && <Icon inline size='10px' name='chevron-right' sx={{ mx: 1 }}/>}
        </React.Fragment>
      ))}
    </Flex>
  )
}

export default Breadcrumb