import Events from './pages/Events'


export default {
  name: 'RnxEventGeneral',
  components: {
    'Events': {
      component: Events
    },
    // 'UserManagement': {
    //   component: UserManagement
    // }
  }
}