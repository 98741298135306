import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import Image from 'reactor-ui/components/Image'
import { HStack } from 'reactor-ui/components/Stack'
import RfActionForm from 'reactor-form/components/RfActionForm'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import ActionGroup, { ActionGroupMenu } from 'reactor-vera/apps/data/components/ActionGroup'


const ImageTemplateManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<EventsHome />} />
      <Route path='template/:rcImageTemplateId/*' element={<ImageTemplateDetail />} />
    </Routes>
  )
}

const EventsHome = props => {
  const { t } = useTranslation()
  return (
    <>
      <GraphTable
        modelName='RcImageTemplate'
        query={{
          systemQuery: true
        }}
        elements={{
        }}
        detailLink='template'
      />
    </>
  )
}

const ImageTemplateDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rcImageTemplate': {
      filters: {
        id: params.rcImageTemplateId
      },
      fields: [
        'name', 'kind', 'baseImage'
      ],
      systemQuery: true
    },
  })

  useBreadcrumb(`rcImageTemplateDetail:${result.graph?.rcImageTemplate.id}`, result.graph?.rcImageTemplate.name)

  const rcImageTemplate = result.graph?.rcImageTemplate
  const meta = result.graph?.rcImageTemplateMeta

  if (!rcImageTemplate) return null
  console.log('rcImageTemplate', rcImageTemplate)

  return (
    <>
      <ModelDetailCard
        modelName='RcImageTemplate'
        icon={'book-open'}
        entity={rcImageTemplate}
        schema={result.schema?.rcImageTemplate}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      {rcImageTemplate.kind.value === 'IMAGE_CONVERT' && <ImageConvertDetail rcImageTemplate={rcImageTemplate} />}
    </>
  )
}

const ImageConvertDetail = ({
  rcImageTemplate
}) => {
  const result = useModelDetailReactorQuery({
    'imagePlacementTemplate': {
      filters: {
        id: rcImageTemplate.id
      },
      fields: [
        'name', 'kind', 'baseImage', 'config', 'testConversions'
      ]
    },
  })

  const imagePlacementTemplate = result.graph?.imagePlacementTemplate
  if (!imagePlacementTemplate) return null
  console.log('imagePlacementTemplate', imagePlacementTemplate)

  return (
    <>
      <Flex mt={4}>
        <Box flex='0 0 30%'>
          <Box>
            Alanlar
          </Box>
          <Box>
            {imagePlacementTemplate.config?.areas && (
              Object.values(imagePlacementTemplate.config?.areas).map(area => {
                return (
                  <HStack justify='space-between' key={area.name}>
                    <Box>
                      {area.name}
                      <RfActionForm
                        name='op.delete_rc_image_template_area'
                        actionKey='RcImageTemplate.DeleteRcImageTemplateArea'
                        buttonText='btn.delete'
                        initialValues={{
                          id: imagePlacementTemplate.id,
                          areaName: area.codeName
                        }}
                      />
                    </Box>
                    <ActionGroupMenu name={'RcImageTemplate'} contexts={['Entity']} kinds={['UPDATE', 'DELETE']} ctx={{ id: imagePlacementTemplate.id, areaName: area.codeName }} />
                  </HStack>
                )
              })
            )}
          </Box>

          <Box>
            Test Görselleri
          </Box>
          <Box>
            <ActionGroup name={'RcImageTemplateConversion'} contexts={['Model']} kinds={['CREATE']} ctx={{
              rcImageTemplateId: rcImageTemplate.id,
              // kind: rcImageTemplate.kind.value
            }} />
            {imagePlacementTemplate.testConversions?.items?.map((item, dx) => {
              return (
                <Box key={dx}>
                  <HStack justify='space-between'>
                    <Box>{item.name}</Box>
                    <ActionGroupMenu name={'RcImageTemplateConversion'} contexts={['Entity']} kinds={['UPDATE', 'DELETE']} ctx={{ id: item.id }} />
                  </HStack>
                  <Image src={item.convertedImage?.url} />
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box flex='0 0 70%'>
          <Image src={imagePlacementTemplate.baseImage.url} />
        </Box>
      </Flex>
    </>
  )
}

export default ImageTemplateManagement