import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import * as changeCase from 'reactor-ui/util/text'

import ModelField from './ModelField'
import ActionGroup from '../../data/components/ActionGroup'


const ModelDetailCard = ({
  icon,
  modelName: modelNameFromProps,
  entity,
  schema,
  name,
  fields,

  headerPre,
  parentName,

  elements,

  showEntityActions = true
}) => {
  const name_ = name || entity?.name || entity?.id
  const modelName = modelNameFromProps || changeCase.pascal(entity.__typename)
  // console.log(entity, schema, modelName)
  const { t } = useTranslation()

  return (
    <Card>
      <Flex sx={{
        alignItems: 'center',
        mb: 2,
        mx: -2
      }}>
        <Box sx={{
          flex: '1 1 auto',
          mx: 2
        }}>
          <Box sx={{
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}>
            {icon ? (
              <Icon icon={icon}>
                {headerPre} {parentName && (<Box as='span'>{parentName} {'>'} </Box>)} {name_}
              </Icon>
            ) : (
              <>
              {headerPre} {parentName && (<Box as='span'>{parentName} {'>'} </Box>)} {name_}
              </>
            )}
          </Box>
        </Box>

        <Box mx={2}>
          {showEntityActions && <ActionGroup name={modelName} contexts={['Entity']} ctx={{id: entity.id}}/>}
          <Flex>
          {elements?.primaryActions}
          </Flex>
        </Box>
      </Flex>

      <Flex sx={{
        mx: -3,
        alignItems: 'center',
        my: 3
      }}>
        {fields.map((field, dx) => {
          let fieldSchema, fieldName
          if (field.includes('.')) {
            const fieldSplit = field.split('.')
            fieldSchema = schema[fieldSplit[0]]
            fieldName = fieldSplit[1]
          } else {
            fieldSchema = schema[field]
          }
          const header = t(`field.${changeCase.snake(fieldName || fieldSchema.name)}`)
          return (
            <Box key={dx} sx={{
              mx: 3
            }}>
              <Box sx={{
                textTransform: 'uppercase',
                fontSize: 'xs',
                fontWeight: 'bold',
                mb: 1
              }}>
                {header}
              </Box>
              <ModelField fieldName={fieldName} fieldSchema={fieldSchema} entity={entity}/>
            </Box>
          )
        })}
      </Flex>
    </Card>
  )
}

export default ModelDetailCard