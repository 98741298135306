import ImageTemplateManagement from './pages/ImageTemplateManagement'


export default {
  name: 'RcImageTemplate',
  components: {
    'ImageTemplateManagement': {
      component: ImageTemplateManagement
    },
  }
}