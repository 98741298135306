import React from 'react'

import sortBy from 'lodash/sortBy'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Routes, Route, useParams, Link, useHref, useNavigate } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

const SponsorContent = ({
  onClose
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicEvent': {
      id: params.eventId,
      fields: [
        'sponsors', 'url'
      ]
    },
  })

  const sponsors = result.graph?.publicEvent?.sponsors?.sponsors
  const logos = result.graph?.publicEvent?.sponsors?.logos
  const url = result.graph?.publicEvent?.url
  console.log('spcnt', sponsors, url)
  if (!sponsors) return null
  return (
    <Flex sx={{
      flexWrap: 'wrap',
      mx: -4
    }}>
      {sortBy(sponsors, x => x.owner_rnx_event_account.name).map((sponsor, dx) => {
        const info = sponsor.data.rcEav.default.values.profile_info
        const account = sponsor.owner_rnx_event_account
        const logo = logos.find(x => x.id == info.logo)
        // console.log(account, info, logo)

        return (
          <Link onClick={onClose} to={`${url}/sponsors/booth/${sponsor.id}`} key={dx} sx={{
            mx: 4,
            border: '1px solid #FFF',
            borderColor: 'brand.500',
            flex: ['1 1 200px', null, null, '0 0 200px'],
            py: 5,
            px: 3,
            my: 4,
            borderRadius: 4,
            textAlign: 'center'
          }}>
            {logo && <Box as='img' src={logo.url} sx={{
              height: '70px',
              display: 'block',
              mx: 'auto'
            }}/>}
            {account.name}
          </Link>
        )
      })}
    </Flex>
  )
}

export default SponsorContent