import React from 'react'

// Reactor
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ActionGroup, { ActionGroupMenu } from 'reactor-vera/apps/data/components/ActionGroup'

// Reactor
import { Routes, Route, useParams, Link } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import Content from 'reactor-ui/components/Content'
import Enum from 'reactor-vera/apps/model/components/Enum'

//
import { RnxEventContext } from '../eventContext'


const EventProfiles = ({

}) => {
  return (
    <Routes>
      <Route path='/' element={<EventProfilesHome />} />
      <Route path='profile/:rnxEventProfileId/*' element={<EventProfileDetail />} />
    </Routes>
  )
}


const EventProfilesHome = props => {
  const { rnxEvent } = React.useContext(RnxEventContext)
  console.log('rnxEvent', rnxEvent)
  return (
    <>
      <GraphTable
        modelName='RnxEventProfile'
        detailLink='profile'
        headerText='Profiller'
        actionCtx={{
          model: {
            rnx_event_id: rnxEvent.id
          }
        }}
        query={{
          filters: {
            rnx_event_id: rnxEvent.id,
          }
        }}
      />
    </>
  )
}

const EventProfileDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEventProfile': {
      filters: {
        id: params.rnxEventProfileId
      },
      fields: [
        'name', 'target', 'profile', 'profileData'
      ]
    },
  })

  useBreadcrumb(`rnxEventProfileDetail:${result.graph?.rnxEventProfile.id}`, result.graph?.rnxEventProfile.name)

  const rnxEventProfile = result.graph?.rnxEventProfile
  const meta = result.graph?.rnxEventProfileMeta
  if (!rnxEventProfile) return null
  return (
    <>
      <ModelDetailCard
        modelName='RnxEventProfile'
        icon={'book-open'}
        entity={rnxEventProfile}
        schema={result.schema?.rnxEventProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <ProfileLanguages rnxEventProfile={rnxEventProfile} />
    </>
  )
}

const ProfileLanguages = ({
  rnxEventProfile
}) => {
  return (
    <Card my={4}>
      <Card.Header>Diller</Card.Header>

      <Content>
        {rnxEventProfile.profileData.languages && Object.entries(rnxEventProfile.profileData.languages).map(([key, lang], dx) => (
          <Flex key={dx} my={1}>
            <Enum value={lang.language} />
            {key != rnxEventProfile.profileData.defaultLanguage && <ActionGroupMenu sx={{ ml: 2 }} name={'RnxEventProfile'} contexts={['Entity.Language']} kinds={['CREATE', 'UPDATE', 'DELETE']} ctx={{ id: rnxEventProfile.id, language: key }} />}
          </Flex>
        ))}
      </Content>
    </Card>
  )
}

export default EventProfiles