import React from 'react'

// Vendor
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'

export { Flex, Box } from "@chakra-ui/react"

import useUiConfig from 'reactor-vera/hooks/useUiConfig'

const ReactorUi = ({
  theme,
  children,
  uiConfig,
  globalCss = () => { }
}) => {
  console.log(uiConfig)
  const globalStyles = css`
    ${globalCss(theme)}
  `

  const [_, updateUiConfig] = useUiConfig()
  React.useEffect(() => {
    uiConfig && updateUiConfig(uiConfig)
  }, [])

  return (
    <ChakraProvider theme={theme} portalZIndex={5000}>
      <Global styles={globalStyles} />
      {children}
    </ChakraProvider>
  )
}

export default ReactorUi