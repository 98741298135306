import React from 'react'

// Reactor
import useReactorAction from 'reactor/hooks/useReactorAction'

import { getFormData } from '../util'
import useFormReducer from './useFormReducer'

export const useReactorActionForm = (
  actionIdentifier,
  options = {}
) => {
  const dispatchAction = useReactorAction(actionIdentifier, {kind: options.actionKind})

  const [formState, formActions] = useFormReducer()

  const { onSuccess, onError, ...restOptions } = options
  const { phase, error, result, isSubmitting } = formState

  const handleSubmit = (data) => {
    formActions.BEGIN()
    if (options.parseData) data = options.parseData(data)
    const dataToSend = getFormData(data)

    return dispatchAction(dataToSend, {
      success: (payload) => {
        formActions.SUCCESS(payload)
        onSuccess?.(payload)
      },
      error: (payload) => {
        formActions.ERROR(payload)
        onError?.(payload)
      }
    })
  }

  return ([{
    // ...props,
    ...restOptions,
    phase,
    error,
    handleSubmit,
    result
  }, isSubmitting, formActions.RESET])
}

export default useReactorActionForm