import React from 'react'

// Reactor UI
import { Box, Flex } from 'reactor-ui'

import PlexUiSingleSidebarUser from './PlexUiSingleSidebarUser'
import PlexUiSingleSidebarTenant from './PlexUiSingleSidebarTenant'
import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'


const PlexUiSingleSidebarFooter = props => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig?.sidebar?.extended
  return (
    <Flex sx={{
      transition: 'all 0.2s ease',
      height: isExtended ? '50px' : '70px',
      bottom: 0,
      position: 'fixed',
      width: isExtended ? 240 : 74,
      bg: 'dark.900',
      mx: -3,
      px: 3,
      py: isExtended ? 0 : 3,
      color: 'light.500',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: isExtended ? 'row' : 'column'
    }}>
      <PlexUiSingleSidebarUser/>
      <PlexUiSingleSidebarTenant/>
    </Flex>
  )
}

export default PlexUiSingleSidebarFooter