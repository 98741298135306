import React from 'react'

// Reactor
import useReactorAction from 'reactor/hooks/useReactorAction'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import { Routes, Route, useNavigate, Link, useLocation, useParams } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'
import ActionButton from 'reactor-vera/apps/data/components/ActionButton'

// ReactorUi
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'

const EventForgot = ({
  publicEvent
}) => {
  return (
    <Routes>
      <Route path='/' element={<EventForgotForm publicEvent={publicEvent} />} />
      <Route path=':token' element={<EventForgotToken publicEvent={publicEvent} />} />
    </Routes>
  )
}

const EventForgotForm = ({
  publicEvent
}) => {
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang', 'tr')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.ForgotPassword',
    {
      initialValues: {
        path: location.pathname,
        lang: selectedLang
      },
      onSuccess: () => {
        // navigate('./lobby')
        // window.location.reload()
      }
    }
  )

  if (formProps.phase === 'SUCCESS') return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <Box>
        {t('txt.email_reset_success')}
      </Box>
      <Button as={Link} colorScheme='brand' to='../' mt={4} isFullWidth>
        {t('btn.login')}
      </Button>
    </Box>
  )

  console.log(formProps.error)
  return (
    <Form {...formProps}>
      <Box as='img' src={publicEvent.logoLight} sx={{
        width: 150,
        mx: 'auto',
        my: 3
      }} />
      <Box sx={{
        textAlign: 'center',
        color: 'dark.300',
        textTransform: 'capitalize'
      }}>
        {t('label.forgot_password')}
      </Box>
      <RfFormField name="email" label={t('field.email')} tabIndex={1} schema={yup.string().min(4).required()} required focus />

      <RfFormError error={formProps.error} />
      {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
      <Button sx={{ textTransform: 'uppercase' }} colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
        {t('btn.reset_password')}
      </Button>
    </Form>
  )
}

const EventForgotToken = ({
  publicEvent
}) => {
  const [phase, setPhase] = React.useState('CHECK')
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const dispatchAction = useReactorAction('RnxEvent.VerifyResetToken')

  React.useEffect(() => {
    dispatchAction({ token: params.token }, {
      success: (payload) => setPhase('FORM'),
      error: (payload) => setPhase('ERROR')
    })
  }, [])

  if (phase === 'CHECK') {
    return t('txt.checking')
  } else if (phase === 'ERROR') {
    return (
      <>
      {t('txt.reset_token_invalid')}

      <Button as={Link} to='../' mt={4} isFullWidth>
        {t('btn.reset_password_again')}
      </Button>
      </>
    )
  } else if (phase === 'FORM') {
    return <EventForgotResetForm token={params.token} publicEvent={publicEvent}/>
  }
}

const EventForgotResetForm = ({
  publicEvent,
  token
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.ResetPassword',
    {
      initialValues: {
        token
      },
      onSuccess: () => {
        // navigate('./lobby')
        // window.location.reload()
      }
    }
  )

  if (formProps.phase === 'SUCCESS') return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <Box>
        {t('txt.password_reset_success')}
      </Box>
      <Button as={Link} colorScheme='brand' to='../../' mt={4} isFullWidth>
        {t('btn.login')}
      </Button>
    </Box>
  )

  return (
    <Form {...formProps}>
      <Box as='img' src={publicEvent.logoLight} sx={{
        width: 150,
        mx: 'auto',
        my: 3
      }} />
      <Box sx={{
        textAlign: 'center',
        color: 'dark.300',
        textTransform: 'capitalize'
      }}>
        {t('label.reset_password')}
      </Box>
      <RfFormField name="password" label={t('field.password')} kind='password' required />
      <RfFormField name="passwordAgain" label={t('field.password_again')} kind='password' required />

      <RfFormError error={formProps.error} />
      {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
      <Button sx={{ textTransform: 'uppercase' }} colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
        {t('btn.reset_password')}
      </Button>
    </Form>
  )
}

export default EventForgot