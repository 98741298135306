import React from 'react'

// Vendor
import { Provider } from 'react-redux'

// Reactor
import { ReactorContext } from 'reactor/context'

import reactor from 'reactor/systems/reactor'
import authentication from 'reactor/systems/authentication'

import upload from 'reactor-vera/systems/upload'

const defaultSystems = [
  reactor,
  authentication,
  upload
]

import useReactorApp from 'reactor/hooks/useReactorApp'

const RunicVeraBase = ({
  apps = [],
  systems = [],
  storeCreator,
  config,
  children
}) => {
  const { isReady, store, reactor } = useReactorApp(({
    apps,
    systems: [...defaultSystems, ...systems],
    storeCreator,
    config
  }))
  if (!isReady) return null

  return (
    <ReactorContext.Provider value={reactor}>
      <Provider store={store}>
        {children}
      </Provider>
    </ReactorContext.Provider>
  )

}

export default RunicVeraBase
