import React from 'react'

// Reactor
import { useNavigate, useParams, Link } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

import {
  keyframes,
} from "@chakra-ui/react";
import useHover from 'reactor-ui/hooks/useHover';


const Hotspots = ({
  hotspots
}) => {
  return (
    <>
      {hotspots?.map((spot, dx) => {
        return (
          <Hotspot spot={spot} key={dx} />
        )
      })}
    </>
  )
}

const LEN = 25

const Hotspot = ({
  spot
}) => {
  const [isActive, setIsActive] = React.useState(true)
  const timeoutRef = React.useRef()
  const eventProps = React.useMemo(() => ({
    onMouseEnter: e => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      setIsActive(true)
    },
    onMouseLeave: e => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => setIsActive(false), 500)
    },
  }), [])

  if (!spot) return null

  const content = (
    <>
      {spot.text && isActive && (
        <Box sx={{
          bg: 'rgba(255,255,255, 0.6)',
          px: 3,
          py: 1,
          borderRadius: 8,
          position: 'absolute',
          top: '-35px',
          left: '-80px',
          width: '200px',
          mx: 'auto',
          fontSize: 'sm',
          textAlign: 'center',
          display: ['none', null, null, 'block']
          // left: '-50%'
        }}>
          {spot.text.length > LEN ? spot.text.substring(0, LEN - 3) + '...' : spot.text}
        </Box>
      )}
      <Icon name={spot.icon} wrapperSx={{
        zIndex: 1,
        position: 'relative',
        bg: spot.bgcolor || 'brand.500',
        borderRadius: 9999,
        width: parseInt(spot.size) * 1.4 || '30px',
        p: 2,
      }} color={spot.color || '#FFF'} size={spot.size} />
      <Box sx={{
        // width: '100%',
        width: parseInt(spot.size) * 1.4 || '30px',
        height: '100%',
        top: 0,
        left: 0,
        bg: '#FFF',
        borderRadius: 999999,
        zIndex: 0,
        // backgroundImage: url(dot1.svg),
        // backgroundPosition: top center,
        // backgroundRepeat: no-repeat,
        // backgroundSize: contain,
        position: 'absolute',
        animation: `${pulse} 4s ease-out`,
        animationIterationCount: 'infinite',
      }} />
    </>
  )

  if (spot.outurl) {
    return (
      <Box as='a' href={spot.outurl || '/'} target='_blank' {...eventProps} sx={{
        position: 'absolute',
        top: spot.top,
        left: spot.left,
        zIndex: 99
      }}>
        {content}
      </Box>
    )
  } else {
    return (
      <Link to={spot.url || '/'} {...eventProps} sx={{
        position: 'absolute',
        top: spot.top,
        left: spot.left,
        zIndex: 99
      }}>
        {content}
      </Link>
    )
  }
}

const pulse = keyframes`
0% {
  transform: scale(1.1);
  opacity: 0.9;
}
5% {
  transform: scale(1.3);
  opacity: 0.7;
}

10% {
  transform: scale(1.5);
  opacity: 0.5;
}

15% {
  transform: scale(1.7);
  opacity: 0.3;
}

20% {
  transform: scale(1.9);
  opacity: 0.1;
}

25% {
  transform: scale(2);
  opacity: 0;
}
100% {
  transform: scale(2);
  opacity: 0;
}
`

export default Hotspots
