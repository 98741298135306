import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useTable } from 'react-table'
import isFunction from 'lodash/isFunction'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import Alert from 'reactor-ui/components/Alert'
import * as changeCase from 'reactor-ui/util/text'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'

// Reactor
import useModelListReactorQuery from 'reactor/hooks/useModelListReactorQuery'
import { Link } from 'reactor-vera/router'

import renderers from 'reactor-vera/apps/model/components/ModelRenderers'

import ActionGroup, { ActionGroupMenu } from './ActionGroup'
import Pagination from './Pagination'
import FilterInput from './Filters'


const GraphTable = ({
  modelName,
  filterKey,
  query,

  actionCtx,

  detailLink,

  elements,

  headerText,
  alignLast,

  showModelActions = true,
  showEntityActions = true,

  filters,

  sx,
}) => {
  const { t } = useTranslation()
  const [result, graphKey, filterInstance] = useModelListReactorQuery({
    modelName,
    filterKey,
    query
  })

  // const { count, items, columns, nextCursor, page, prevCursor } = result.graph[graphKey]

  const _headerText = headerText || t(`label.${changeCase.snake(modelName)}`)

  return (
    <Card sx={{ width: '100%', ...sx }}>
      <Flex sx={{
        alignItems: 'center',
        mb: 2,
        mx: -2
      }}>
        <Box sx={{
          flex: '1 1 auto',
          mx: 2
        }}>
          <Card.Header sx={{
            fontSize: 'm',
            mt: 2,
            mb: 3
          }}>
            {_headerText}
          </Card.Header>
        </Box>

        <TableElements elements={elements?.primaryActions} />

        {showModelActions && (
          <Box mx={2}>
            <ActionGroup name={modelName} contexts={['Model']} kinds={['CREATE']} ctx={actionCtx?.model} />
          </Box>
        )}

        <Pagination graph={result.graph?.[graphKey]} filterInstance={filterInstance} />
      </Flex>
      {result?.status?.status === 'ERROR' && (
        <Alert>
          {t('text.fetch_error')}

          <Button ml={3} size='sm' onClick={result.refresh} sx={{
            textTransform: 'capitalize',
          }}>{t('op.refresh')}</Button>
        </Alert>
      )}
      {elements?.beforeTable && (
        <Box my={2}>
          {elements?.beforeTable}
        </Box>
      )}
      {filters && (
        <Flex sx={{
          mx: -3,
          my: 3
        }}>
          {filters.map((filter, dx) => {
            return (
              <Box sx={{ mx: 3 }} key={dx}>
                <FilterInput focus={dx === 0} label={filter.label} target={filter.target} kind={filter.kind} filterInstance={filterInstance} />
              </Box>
            )
          })}
        </Flex>
      )}
      {result?.graph?.[graphKey] && <GraphTableContent
        modelName={modelName}
        columnNames={result.graph[graphKey].columns}
        items={result.graph[graphKey].items}
        schemas={result.schema}
        alignLast={alignLast}
        detailLink={detailLink}
        elements={elements}
        showEntityActions={showEntityActions}
        filterInstance={filterInstance}
      />}
      {elements?.afterTable}
      {result.graph?.[graphKey]?.count > 20 && <Flex sx={{
        alignItems: 'center',
        mb: 2,
        mx: -2,
        my: 4
      }}>
        <Box sx={{
          flex: '1 1 auto',
          mx: 2
        }}>

        </Box>

        <Pagination graph={result.graph?.[graphKey]} filterInstance={filterInstance} />
      </Flex>}
    </Card>
  )
}

const parseColumnKeys = (colNames, schemas) => {
  const schema = schemas[colNames[0]]
  if (colNames.length === 1) return schema
  if (!schema) return null
  return parseColumnKeys(colNames.slice(1), schema)
}

const GraphTableContent = ({
  modelName,
  columnNames,
  items,
  schemas,

  detailLink,

  alignLast,

  elements,

  showEntityActions,

  filterInstance
}) => {
  const { t } = useTranslation()
  const columnCount = columnNames.length

  const tableColumns = React.useMemo(() => {
    const result = columnNames.map((colKey, dx) => {
      // console.log('--', colKey, schemas)
      let colName
      let colSchema
      let header
      let colKeys
      let accessor
      let keys
      let id

      if (colKey.includes('.')) {
        colKeys = colKey.split('.')
        colSchema = parseColumnKeys(colKeys, schemas)
        // console.log(colSchema)
        colName = colKey
        keys = colKeys.slice(1)
        // colSchema = schemas[changeCase.camel(modelName)][colName]
        header = colKeys[colKeys.length - 1]
        id = colKeys[colKeys.length - 1]
        accessor = colKeys[0]
      } else {
        colName = colKey
        colSchema = schemas[changeCase.camel(modelName)][colName]
        header = colKey
        accessor = colKey
        id = colKey
      }
      return ({
        id: id,
        Header: header,
        accessor: accessor,
        keys: keys,
        colKey: colKey,
        schema: colSchema,
        filterInstance,
        alignLast,
        link: dx === 0 && detailLink ? isFunction(detailLink) ? detailLink : (row) => `${detailLink}/${row.id}` : null
      })
    })
    if (elements?.entityActions) result.push({
      Header: elements.entityActions.header,
      id: '@customEntityActions',
      renderer: (cell) => <TableElements elements={elements.entityActions.items} entity={cell.row} />,
      alignLast: true,
    })
    if (showEntityActions) result.push({
      Header: '',
      id: '@entityActions',
      renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id }} />,
      alignLast: true,
    })
    return result
  }, [columnNames])

  const tableRows = items

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableRows,
    getRowId: (row) => row.id
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <>
      <Box mx={-4}>
        <Table {...getTableProps()} variant="striped" colorScheme="light" size='sm'>
          <Thead>
            {headerGroups.map(headerGroup => {
              return (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, dx) => {
                    const { key, ...headerProps } = column.getHeaderProps()
                    return (
                      <GraphTableColumnHeader filterInstance={filterInstance} isLast={dx === columnCount - 1} key={key} headerProps={headerProps} column={column} />
                    )
                  })}
                </Tr>
              )
            })}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, dx) => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellDx) => {
                    const { key, cellProps } = cell.getCellProps()
                    return <GraphTableCell isLast={cellDx === columnCount - 1} key={key} cellProps={cellProps} cell={cell} />
                  })}
                </Tr>
              )
            })}
            {rows.length === 0 && (
              <tr>
                <Box as='td' colSpan={columnCount} sx={{
                  textAlign: 'center',
                  bg: 'var(--chakra-colors-light) !important',
                  height: '50px'
                }}>
                  {t('text.data_empty')}
                </Box>
              </tr>
            )}
          </Tbody>
        </Table>

      </Box>
    </>
  )

}

const GraphTableColumnHeader = ({
  headerProps,
  column,
  isLast,
  filterInstance
}) => {
  const { t } = useTranslation()
  const { locationFilters } = filterInstance
  const [filterOpen, setFilterOpen] = React.useState(!!locationFilters[column.colKey] || false)
  const header = column.Header && t(`field.${changeCase.snake(column.Header)}`)
  const filterType = column.schema?.filterType
  let content

  // console.log(column.Header, locationFilters, filterOpen, column.schema)

  if (filterType) {
    content = (
      <>
        <Flex sx={{
          alignItems: 'center',
          // justifyContent: 'space-between'
        }}>
          {header}
          <Box sx={{
            ml: 2
          }}>
            <a onClick={() => setFilterOpen(v => !v)}>
              <Icon name='search' size={4} color='brand.300' />
            </a>
          </Box>
        </Flex>
        {filterOpen && <ColumnFilter column={column} label={header} />}
      </>
    )
  } else {
    content = header
  }
  return (
    <Th {...headerProps} isNumeric={column.alignLast && isLast}>
      {content}
    </Th>
  )
}

const ColumnFilter = ({
  column,
  label
}) => {
  const filterType = column.schema?.filterType
  const schema = column.schema
  const colKey = column.colKey
  const filterInstance = column.filterInstance

  return (
    <Box mt={2}>
      <FilterInput label={label} target={colKey} filterInstance={filterInstance} kind={filterType} schema={schema} />
    </Box>
  )
}

const GraphTableCell = ({
  cellProps,
  cell,
  isLast
}) => {
  let content, isNumeric
  const column = cell.column

  if (column.renderer) {
    content = cell.column.renderer(cell)
    isNumeric = true
  } else {
    // console.log('--', column)
    const columnSchema = column.schema

    let valueType, schemaType
    if (columnSchema) {
      valueType = columnSchema.valueType
      schemaType = columnSchema.schemaType
    } else {
      valueType = 'Scalar'
      schemaType = 'Text'
    }

    let value = cell.value
    if (column.keys) {
      column.keys.forEach(key => {
        value = value?.[key]
      })
    }
    // console.log(column.Header, column.keys, columnSchema, value, cell.value)

    // return <Td {...cellProps}/>

    let renderer
    if (valueType === 'Scalar') {
      renderer = renderers[schemaType]
    } else if (valueType === 'Object') {
      renderer = renderers.Object
    }

    // content = renderer ? renderer(value, cell) : cell.render('Cell')
    content = renderer ? renderer(value, cell) : value

    isNumeric = column.alignLast && isLast

    const { link } = column

    if (link) {
      content = (
        <Link sx={{
          color: 'primary',
          textDecoration: 'underline'
        }} to={link(cell.row)}>
          {content}
        </Link>
      )
    }
  }

  return (
    <Td {...cellProps} isNumeric={isNumeric}>
      {content}
    </Td>
  )
}

const TableElements = ({
  elements,
  ...props
}) => {
  if (!elements) return null

  return (
    <Flex sx={{
      alignItems: 'center'
    }}>
      {elements?.map((element, dx) => {
        return isFunction(element) ? element({ ...props, dx }) : element
      })}
    </Flex>
  )
}

export default GraphTable