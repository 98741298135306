import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import { Routes, Route, useParams, Link, useHref } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

import Hotspots from '../components/Hotspots'
import Program from '../components/Program'

import RnxEventVisitorProfile from './RnxEventVisitorProfile'

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

const getEmbedUrl = (url) => {
  if (!url) return null
  const id = getId(url)
  if (!id) return null
  return `https://www.youtube.com/embed/${id}`
}

const RnxEventLobby = ({
  publicEvent,
  conference
}) => {
  const { t } = useTranslation()
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: publicEvent.eventId,
      kind: 'LOBBY',
      fields: [
        'name', 'image', 'settingsValues'
      ]
    },
  })
  const url = useHref('')
  const publicEventArea = result.graph?.publicEventArea
  console.log('event', publicEvent)
  console.log('publicEventArea', publicEventArea)
  if (!publicEventArea) return null
  console.log(publicEventArea.image)
  return (
    <Box sx={{
      // background: `url('${publicEventArea.image}') top center no-repeat`,
      // backgroundSize: 'cover',
      // backgroundSize: 'contain',
      // width: '100vw',
      // minWidth: 1000,
      // minHeight: 800,
      // height: '100vh',
      position: 'relative'
    }}>
      <Box as='img' src={publicEventArea.image} sx={{
        width: '100%',
        height: '100%',
      }} />
      <Box sx={{
        p: 4,
        position: 'fixed',
        top: 0,
        width: '100%'
      }}>
        <Flex>
          <Box as='img' src={publicEvent.logo} sx={{
            width: 150
          }} />
        </Flex>
      </Box>

      <Hotspots hotspots={[
        {
          'icon': 'square-new-window',
          'top': '58%',
          'left': '68%',
          'kind': 'url',
          'url': '../exhibition-hall'
        },

        {
          'icon': 'square-new-window',
          'top': '58%',
          'left': '31%',
          'url': '../conference-hall'
        },

        publicEventArea.settingsValues?.ust_ekran?.videoUrl && {
          'icon': 'square-new-window',
          'top': '20%',
          'left': '49%',
          'url': 'videoTop'
        },

        publicEventArea.settingsValues?.orta_ekran?.videoUrl && {
          'icon': 'square-new-window',
          'top': '38%',
          'left': '49%',
          'url': 'videoBottom'
        },

        publicEventArea.settingsValues?.help_desk_url && {
          'icon': 'square-new-window',
          'top': '76%',
          'left': '57%',
          'text': t('label.helpdesk'),
          'outurl': publicEventArea.settingsValues?.help_desk_url
        }
      ]} />

      {conference && <Program defaultIsOpen={true} />}

      <Routes>
        <Route path='profile/:profileId/*' element={<RnxEventVisitorProfile backUrl={url}/>} />
        <Route path='videoBottom' element={(
          <Box sx={{
            zIndex: 999999
          }}>
            <Link to={'../'} sx={{
              position: 'absolute',
              top: '30px',
              right: '80px',
              zIndex: 9999999
            }}>
              <Icon name='circle-remove' color='brand.500' size={10} />
            </Link>
            <Box as='iframe' sx={{
              position: 'absolute',
              zIndex: 99999,
              top: '5vh',
              left: '5vw',
              width: '90vw',
              height: '90vh',
            }} src={getEmbedUrl(publicEventArea.settingsValues?.orta_ekran?.videoUrl)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Box>
          </Box>
        )} />

        <Route path='videoTop' element={(
          <Box sx={{
            zIndex: 9999999
          }}>
            <Link to={'../'} sx={{
              position: 'absolute',
              top: '30px',
              right: '80px',
              zIndex: 999999
            }}>
              <Icon name='circle-remove' color='brand.500' size={10} />
            </Link>
            <Box as='iframe' sx={{
              position: 'absolute',
              zIndex: 99999,
              top: '5vh',
              left: '5vw',
              width: '90vw',
              height: '90vh',
            }} src={getEmbedUrl(publicEventArea.settingsValues?.ust_ekran?.videoUrl)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Box>
          </Box>
        )} />
      </Routes>
    </Box>
  )
}

export default RnxEventLobby