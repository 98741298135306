import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const RfFieldDescription = ({ field, position = 'reverse' }) => {
  if (!field.description) return null
  const content = (
    <Box sx={{
      color: 'dark.400',
      mt: 1,
      fontSize: 'sm'
    }}>
      {field.description}
    </Box>
  )

  if (position === 'reverse') {
    return (
      <Flex sx={{
        flexDirection: 'row-reverse',
      }}>
        {content}
      </Flex>
    )
  } else if (position === 'default') {
    return content
  } else {
    return content
  }
}

export default RfFieldDescription