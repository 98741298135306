import React from 'react'

// Reactor UI
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import RfActionForm from 'reactor-form/components/RfActionForm'

import { Flex, Box } from 'reactor-ui'
import Image from 'reactor-ui/components/Image'
import Button from 'reactor-ui/components/Button'


const EventSetupItem = props => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEventArea': {
      filters: {
        id: params.rnxEventAreaId
      },
      fields: [
        'name', 'kind', 'settings', 'settingsValues', 'status', 'image'
      ]
    },
  })

  useBreadcrumb(`rnxEventArea:${result.graph?.rnxEventArea.id}`, result.graph?.rnxEventArea.name)

  const rnxEventArea = result.graph?.rnxEventArea
  console.log('rnxEventArea', rnxEventArea)

  if (!rnxEventArea) return null

  return (
    <Box>
      {rnxEventArea.name}

      <Flex>
        <Box sx={{ flex: '1 1 0' }}>
          <AreaSettings refresh={result.refresh} rnxEventArea={rnxEventArea} />
        </Box>

        <Box sx={{ flex: '1 1 0' }}>
          <Image sx={{

          }} src={rnxEventArea.image?.url} />
        </Box>

      </Flex>
    </Box>
  )
}

import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'

const AreaSettings = ({
  rnxEventArea,
  refresh
}) => {
  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.UpdateSettings',
    {
      initialValues: {
        id: rnxEventArea.id,
        values: rnxEventArea.settingsValues
      },
      onSuccess: () => refresh()
    }
  )

  return (

    <Box>
      {<RfActionForm
      name='op.update_area_image'
      actionKey='RnxEvent.UpdateAreaImage'
      buttonText='btn.save'
      initialValues={{
        id: rnxEventArea.id,
      }}
      fields={[
        {
          name: 'image',
          kind: 'file',
          label: 'Görsel',
          options: {
            acceptedFileKinds: 'image/*'
          }
        }
      ]}
      />}

      {rnxEventArea.settings && <Form {...formProps}>
        {Object.entries(rnxEventArea.settings).map(([key, value]) => {
          return (
            <SetupItem refresh={refresh} item={value} key={key} codeName={key} rnxEventArea={rnxEventArea} />
          )
        })}
        <RfFormError error={formProps.error} />
        {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
        <Button colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
          GÜNCELLE
        </Button>
      </Form>}

    </Box>
  )
}

const SetupItem = ({
  rnxEventArea,
  item,
  codeName,
  refresh
}) => {
  console.log(item)
  let content
  if (item.kind === 'IMAGE') content = <ImageItem refresh={refresh} rnxEventArea={rnxEventArea} item={item} codeName={codeName} />
  else if (item.kind === 'TEMPLATE_IMAGE') content = <ImageItem refresh={refresh} rnxEventArea={rnxEventArea} item={item} codeName={codeName} />
  else if (item.kind === 'TEMPLATE_IMAGE_FROM_VIDEO') content = <RfFormField name={`values.${codeName}.videoUrl`} label={item.name} />
  else if (item.kind === 'URL') content = <RfFormField name={`values.${codeName}`} label={item.name} />
  else if (item.kind === 'TEXT') content = <RfFormField name={`values.${codeName}`} label={item.name} />
  else if (item.kind === 'NUMBER') content = <RfFormField name={`values.${codeName}`} label={item.name} />
  return (
    <Box>
      {content}
    </Box>
  )
}

const ImageItem = ({
  rnxEventArea,
  item,
  codeName,
  refresh
}) => {

  return (
    <Box>
      <RfFormField name={`values.${codeName}`} kind='file' label={item.name} />
      {/* <Image sx={{

      }} src={rnxEventArea.settingsValues?.[codeName]?.url} />
      <RfActionForm
        name={item.name}
        displayAs='FORM'
        actionKey='RnxEvent.UpdateSettings'
        initialValues={{
          id: rnxEventArea.id,
          codeName: codeName
        }}
        fields={[
          {
            name: 'values.image',
            kind: 'file',
            label: 'Görsel'
          }
        ]}
        onSuccess={() => {
          refresh()
        }}
      /> */}
    </Box>
  )
}

export default EventSetupItem