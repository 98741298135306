import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// Reactor
import { Routes, Route, useParams, Link, useNavigate } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useReactorAction from 'reactor/hooks/useReactorAction'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

import RnxEventVisitorProfile from './RnxEventVisitorProfile'
import RnxEventBooth from './RnxEventBooth'

import ActionButton from 'reactor-vera/apps/data/components/ActionButton'
import RfActionForm from 'reactor-form/components/RfActionForm'

const RnxEventUserProfile = ({
  publicEvent
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: publicEvent.eventId,
      kind: 'EXHIBITION_HALL',
      fields: [
        'name', 'image', 'data'
      ]
    },
  })
  const publicEventArea = result.graph?.publicEventArea
  console.log('event', publicEvent)
  console.log('publicEventArea', publicEventArea)
  if (!publicEventArea) return null
  console.log(publicEventArea.image)
  return <RnxEventExhibitionHallContent publicEvent={publicEvent} publicEventArea={publicEventArea} />
}

const RnxEventExhibitionHallContent = ({
  publicEventArea,
  publicEvent
}) => {
  const params = useParams()
  const dispatchAction = useReactorAction('RnxEvent.TrackVisit')
  // React.useEffect(() => {
  //   dispatchAction({
  //     'kind': 'EXHIBITION_HALL',
  //     'id': publicEventArea.id
  //   })
  // }, [])

  return (
    <Box sx={{
      // background: `url('${publicEventArea.image}') top center no-repeat`,
      // backgroundSize: 'cover',
      // backgroundSize: 'contain',
      // width: '100vw',
      // minWidth: 1000,
      // minHeight: 800,
      // height: '100vh',
      position: 'relative'
    }}>
      <Box as='img' src={publicEventArea.image} sx={{
        width: '100%',
        height: '100%',
      }} />
      <Box sx={{
        p: 4,
        position: 'fixed',
        top: 0,
        width: '100%'
      }}>
        <Flex>
          <Link to='../lobby'>
            <Box as='img' src={publicEvent.logo} sx={{
              width: 150
            }} />
          </Link>
        </Flex>
      </Box>

      <Routes>
        <Route path='visits/*' element={<Visits publicEvent={publicEvent} />} />
        <Route path='connections/*' element={<Connections publicEvent={publicEvent} />} />
        <Route path='profile/*' element={<Profile publicEvent={publicEvent} />} />
        <Route path='booth/*' element={<Booth publicEvent={publicEvent} />} />
        <Route path='boothvisitors/*' element={<Visitors publicEvent={publicEvent} />} />
      </Routes>

      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 99999
      }}>
        <Routes>
          <Route path='profile/:profileId/*' element={<RnxEventVisitorProfile publicEvent={publicEvent} />} />
        </Routes>
      </Box>
    </Box>
  )
}


import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "reactor-ui/components/Modal"

import MyVisitsContent from '../components/MyVisitsContent'

const Visits = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onClose()
        navigate(`${publicEvent.url}/exhibition-hall`)
      }} size='full'>
        <ModalOverlay sx={{}} />
        <ModalContent sx={{
          width: ['100%', null, null, '85%'],
          my: '90px',
          minHeight: 'fit-content'
        }}>
          <ModalCloseButton />
          <ModalBody>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl',
              color: 'brand.500',
              textTransform: 'capitalize'
            }}>
              {t('label.my_visits')}
            </Box>
            <MyVisitsContent onClose={() => {
              // onClose()
              // menuClose()
            }} />
            <Routes>
              <Route path='booth/:boothId*' element={<RnxEventBooth publicEvent={publicEvent} />} />
            </Routes>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

import NetworkContent from '../components/NetworkContent'

const Connections = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onClose()
        navigate(`${publicEvent.url}/exhibition-hall`)
      }} size='full'>
        <ModalOverlay sx={{}} />
        <ModalContent sx={{
          width: ['100%', null, null, '85%'],
          my: '90px',
          minHeight: 'fit-content'
        }}>
          <ModalCloseButton />
          <ModalBody>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl',
              color: 'brand.500',
              textTransform: 'capitalize'
            }}>
              {t('label.my_network')}
            </Box>
            <NetworkContent publicEvent={publicEvent} onClose={() => {
              // onClose()
              // menuClose()
            }} />
            <Routes>
              <Route path='profile/:profileId/*' element={<RnxEventVisitorProfile asContact publicEvent={publicEvent} />} />
            </Routes>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}


const Profile = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const params = useParams()
  const result = useReactorQuery({
    'publicContact': {
      eventId: params.eventId,
      fields: [
        'profile'
      ]
    },
  })
  const publicContact = result.graph?.publicContact
  console.log('publicContact', publicContact)
  if (!publicContact) return null
  // return null

  return (
    <Box sx={{
      position: 'relative'
    }}>
      <Box sx={{
        position: 'fixed',
        width: '100vw',
        minHeight: '100vh',
        left: '0',
        top: '0',
        bg: 'rgba(0, 0, 0, 0.6)',
        zIndex: 99,
      }} />
      <Box sx={{
        position: 'fixed',
        width: ['90vw', null, null, '40vw'],
        maxHeight: '96vh',
        overflow: 'auto',
        left: ['5vw', null, null, '30vw'],
        top: ['5vh', null, null, '10vh'],
        bg: '#FFF',
        borderRadius: 4,
        p: 3,
        zIndex: 999,
        // overflow: 'hidden'
      }}>
        <Link to={`${publicEvent.url}/lobby`} sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 9999999
        }}>
          <Icon name='circle-remove' color='brand.100' size={10} />
        </Link>
        <Box sx={{
          bg: 'brand.500',
          height: '100px',
          m: -3
        }} />
        <Box sx={{
          width: '25%',
          bg: '#FFF',
          borderRadius: 6,
          mt: '-30px',
          zIndex: 9,
          position: 'relative',
          p: 4,
          mx: 4
        }}>
          <Icon color='brand.500' size={50} wrapperSx={{ mx: 'auto', width: 50 }} icon='user-group' />
        </Box>

        <Box sx={{
          mx: 4
        }}>
          <Flex sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'lg'
            }}>
              {publicContact.profile.name}
            </Box>

          </Flex>

          <Icon wrapperSx={{ mt: 2 }} name='bank' color='brand.500'>
            {publicContact.profile.infos?.company}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='presentation' color='brand.500'>
            {publicContact.profile.infos?.position}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='envelope' color='brand.500'>
            {publicContact.profile.email}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='mobile-phone' color='brand.500'>
            {publicContact.profile.contactInfos?.phone}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='social/whatsapp' color='brand.500'>
            {publicContact.profile.contactInfos?.whatsapp && (
              <Box as='a' sx={{ color: 'brand.500' }} target='_blank' href={`http://wa.me/${publicContact.profile.contactInfos?.whatsapp}`}>{publicContact.profile.contactInfos?.whatsapp}</Box>
            )}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='calendar' color='brand.500'>
            {publicContact.profile.infos?.calendly && publicContact.profile.infos?.calendly[6] == '/' && (
              <Box as='a' sx={{ color: 'brand.500' }} target='_blank' href={publicContact.profile.infos?.calendly}>{publicContact.profile.infos?.calendly}</Box>
            )}
          </Icon>

          <Box sx={{ my: 4 }}>
            <RfActionForm
              name={t('label.update_password')}
              isResetEnabled={false}
              displayAs='MODAL'
              actionKey='RnxEvent.UpdatePassword'
              fields={[
                {
                  name: 'oldPassword',
                  kind: 'password',
                  label: 'field.current_password'
                },

                {
                  name: 'newPassword',
                  kind: 'password',
                  label: 'field.new_password'
                },

                {
                  name: 'newPasswordAgain',
                  kind: 'password',
                  label: 'field.new_password_again'
                }
              ]}
              onSuccess={() => {
                result.refresh()
              }}
            />

            <RfActionForm
              name={t('label.update_your_profile')}
              isResetEnabled={false}
              displayAs='MODAL'
              actionKey='RnxEvent.UpdateProfile'
              sx={{
                mt: 2
              }}
              initialValues={{
                name: publicContact.profile.name,
                company: publicContact.profile.infos?.company,
                position: publicContact.profile.infos?.position,
                calendly: publicContact.profile.infos?.calendly,
                phone: publicContact.profile.contactInfos?.phone,
                whatsapp: publicContact.profile.contactInfos?.whatsapp,
                eventId: publicEvent.eventId
              }}
              fields={[
                {
                  name: 'name',
                  kind: 'text',
                  label: 'field.name'
                },

                {
                  name: 'company',
                  kind: 'text',
                  label: 'field.company',
                  required: false
                },

                {
                  name: 'position',
                  kind: 'text',
                  label: 'field.position',
                  required: false
                },

                {
                  name: 'phone',
                  kind: 'text',
                  label: 'field.phone',
                  required: false
                },

                {
                  name: 'whatsapp',
                  kind: 'text',
                  label: 'field.whatsapp',
                  required: false
                },

                {
                  name: 'calendly',
                  kind: 'text',
                  label: 'field.calendly',
                  required: false
                },
              ]}
              onSuccess={() => {
                result.refresh()
              }}
            />
            {/* <ActionButton size='sm' content={{
              success: (
                <Box>
                  {t('msg.success')}
                </Box>
              ),
              error: (
                <Box>
                  {t('msg.network_request_error')}
                </Box>
              )
            }} sx={{ textTransform: 'capitalize' }} callbacks={{
              success: result.refresh
            }} data={{
              id: params.profileId,
              rnxEventPublicId: params.eventId
            }} actionIdentifier='RnxEvent.UpdatePassword'>
              {t('btn.update_password')}
            </ActionButton> */}
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

const Booth = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const params = useParams()
  const result = useReactorQuery({
    'publicContact': {
      eventId: params.eventId,
      fields: [
        'booth'
      ]
    },
  })
  const publicContact = result.graph?.publicContact
  console.log('publicContact', publicContact)
  if (!publicContact?.booth) return null

  return <RnxEventBooth headerContent={(
    <Flex sx={{
      my: 2,
      mx: -4,
      alignItems: 'center'
    }}>
      <Box sx={{
        fontWeight: 'bold',
        fontSize: 'xl',
        color: 'brand.500',
        textTransform: 'capitalize',
        mx: 4
      }}>
        {t('label.my_booth')}
      </Box>

      <RfActionForm
        name={t('label.update_your_booth')}
        isResetEnabled={false}
        displayAs='MODAL'
        actionKey='RnxEvent.UpdateBooth'
        sx={{
          mt: 2
        }}
        initialValues={{
          eventId: params.eventId,
          boothId: publicContact.booth.id,
          name: publicContact.booth.name,
          youtube: publicContact.booth.profileInfo?.youtube,
          whatsapp: publicContact.booth.profileInfo?.whatsapp,
          telefon: publicContact.booth.profileInfo?.telefon,
          calendly: publicContact.booth.profileInfo?.calendly,
          internet_sitesi: publicContact.booth.profileInfo?.internet_sitesi,
          logo: publicContact.booth.logo,
          brochure: publicContact.booth.brochures,
          boothKind: publicContact.booth.profileInfo?.booth_kind || 'Tip 1'
          // position: publicContact.profile.infos?.position,
          // calendly: publicContact.profile.infos?.calendly,
          // phone: publicContact.profile.contactInfos?.phone,
          // whatsapp: publicContact.profile.contactInfos?.whatsapp,
          // eventId: publicEvent.eventId
        }}
        fields={[
          {
            name: 'name',
            kind: 'text',
            label: 'field.name'
          },

          {
            name: 'youtube',
            kind: 'text',
            label: 'Youtube',
            required: false
          },

          {
            name: 'whatsapp',
            kind: 'text',
            label: 'Whatsapp',
            required: false
          },

          {
            name: 'telefon',
            kind: 'text',
            label: 'Telefon',
            required: false
          },

          {
            name: 'calendly',
            kind: 'text',
            label: 'Calendly',
            required: false
          },

          {
            name: 'internet_sitesi',
            kind: 'text',
            label: 'Web',
            required: false
          },

          {
            name: 'logo',
            kind: 'file',
            label: 'field.logo',
            options: {
              acceptedKinds: 'image/jpg,image/png'
            }
          },

          {
            name: 'brochure',
            kind: 'file',
            label: 'field.brochure',
            options: {
              acceptedKinds: '.pdf',
              isMultiple: true
            }
          },

          {
            name: 'boothKind',
            kind: 'radio',
            label: 'Stand Türü',
            options: {
              optionSx: {
                flex: '1 1 100%',
                my: 3
              },
              options: [
                {
                  value: 'Tip 1',
                  label: 'Tip 1',
                  element: (
                    <Box>
                      Tip 1
                      <Box as='img' src='https://eventrise.io/files//0/rcs/stand1.jpg' sx={{
                        width: '100%'
                      }}/>
                    </Box>
                  )
                },

                {
                  value: 'Tip 2',
                  label: 'Tip 2',
                  element: (
                    <Box>
                      Tip 2
                      <Box as='img' src='https://eventrise.io/files//0/rcs/stand2.jpg' sx={{
                        width: '100%'
                      }}/>
                    </Box>
                  )
                },

                {
                  value: 'Tip 3',
                  label: 'Tip 3',
                  element: (
                    <Box>
                      Tip 3
                      <Box as='img' src='https://eventrise.io/files//0/rcs/stand3.jpg' sx={{
                        width: '100%'
                      }}/>
                    </Box>
                  )
                },
              ]
            }
          }
        ]}
        onSuccess={() => {
          // result.refresh()
          window.location.reload()
        }}
      />
    </Flex>
  )} backUrl={'../../exhibition-hall'} publicEvent={publicEvent} boothId={publicContact.booth.id} />

  return (
    <Box sx={{
      position: 'relative'
    }}>
      <Box sx={{
        position: 'fixed',
        width: '100vw',
        minHeight: '100vh',
        left: '0',
        top: '0',
        bg: 'rgba(0, 0, 0, 0.6)',
        zIndex: 99,
      }} />
      <Box sx={{
        position: 'fixed',
        width: ['90vw', null, null, '40vw'],
        maxHeight: '96vh',
        overflow: 'auto',
        left: ['5vw', null, null, '30vw'],
        top: ['5vh', null, null, '10vh'],
        bg: '#FFF',
        borderRadius: 4,
        p: 3,
        zIndex: 999,
        // overflow: 'hidden'
      }}>
        <Link to={`${publicEvent.url}/lobby`} sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 9999999
        }}>
          <Icon name='circle-remove' color='brand.100' size={10} />
        </Link>
        <Box sx={{
          bg: 'brand.500',
          height: '100px',
          m: -3
        }} />
        <Box sx={{
          width: '25%',
          bg: '#FFF',
          borderRadius: 6,
          mt: '-30px',
          zIndex: 9,
          position: 'relative',
          p: 4,
          mx: 4
        }}>
          <Icon color='brand.500' size={50} wrapperSx={{ mx: 'auto', width: 50 }} icon='user-group' />
        </Box>

        <Box sx={{
          mx: 4
        }}>
          <Flex sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'lg'
            }}>
              {publicContact.profile.name}
            </Box>

          </Flex>

          <Icon wrapperSx={{ mt: 2 }} name='bank' color='brand.500'>
            {publicContact.profile.infos?.company}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='presentation' color='brand.500'>
            {publicContact.profile.infos?.position}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='envelope' color='brand.500'>
            {publicContact.profile.email}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='mobile-phone' color='brand.500'>
            {publicContact.profile.contactInfos?.phone}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='social/whatsapp' color='brand.500'>
            {publicContact.profile.contactInfos?.whatsapp && (
              <Box as='a' sx={{ color: 'brand.500' }} target='_blank' href={`http://wa.me/${publicContact.profile.contactInfos?.whatsapp}`}>{publicContact.profile.contactInfos?.whatsapp}</Box>
            )}
          </Icon>

          <Icon wrapperSx={{ mt: 2 }} name='calendar' color='brand.500'>
            {publicContact.profile.infos?.calendly && publicContact.profile.infos?.calendly[6] == '/' && (
              <Box as='a' sx={{ color: 'brand.500' }} target='_blank' href={publicContact.profile.infos?.calendly}>{publicContact.profile.infos?.calendly}</Box>
            )}
          </Icon>

          <Box sx={{ my: 4 }}>
            <RfActionForm
              name={t('label.update_password')}
              isResetEnabled={false}
              displayAs='MODAL'
              actionKey='RnxEvent.UpdatePassword'
              fields={[
                {
                  name: 'oldPassword',
                  kind: 'password',
                  label: 'field.current_password'
                },

                {
                  name: 'newPassword',
                  kind: 'password',
                  label: 'field.new_password'
                },

                {
                  name: 'newPasswordAgain',
                  kind: 'password',
                  label: 'field.new_password_again'
                }
              ]}
              onSuccess={() => {
                result.refresh()
              }}
            />

            <RfActionForm
              name={t('label.update_your_profile')}
              isResetEnabled={false}
              displayAs='MODAL'
              actionKey='RnxEvent.UpdateProfile'
              sx={{
                mt: 2
              }}
              initialValues={{
                name: publicContact.profile.name,
                company: publicContact.profile.infos?.company,
                position: publicContact.profile.infos?.position,
                calendly: publicContact.profile.infos?.calendly,
                phone: publicContact.profile.contactInfos?.phone,
                whatsapp: publicContact.profile.contactInfos?.whatsapp,
                eventId: publicEvent.eventId
              }}
              fields={[
                {
                  name: 'name',
                  kind: 'text',
                  label: 'field.name'
                },

                {
                  name: 'company',
                  kind: 'text',
                  label: 'field.company'
                },

                {
                  name: 'position',
                  kind: 'text',
                  label: 'field.position'
                },

                {
                  name: 'phone',
                  kind: 'text',
                  label: 'field.phone'
                },

                {
                  name: 'whatsapp',
                  kind: 'text',
                  label: 'field.whatsapp'
                },

                {
                  name: 'calendly',
                  kind: 'text',
                  label: 'field.calendly'
                },
              ]}
              onSuccess={() => {
                result.refresh()
              }}
            />
            {/* <ActionButton size='sm' content={{
              success: (
                <Box>
                  {t('msg.success')}
                </Box>
              ),
              error: (
                <Box>
                  {t('msg.network_request_error')}
                </Box>
              )
            }} sx={{ textTransform: 'capitalize' }} callbacks={{
              success: result.refresh
            }} data={{
              id: params.profileId,
              rnxEventPublicId: params.eventId
            }} actionIdentifier='RnxEvent.UpdatePassword'>
              {t('btn.update_password')}
            </ActionButton> */}
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

const Visitors = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onClose()
        navigate(`${publicEvent.url}/exhibition-hall`)
      }} size='full'>
        <ModalOverlay sx={{}} />
        <ModalContent sx={{
          width: ['100%', null, null, '85%'],
          my: '90px',
          minHeight: 'fit-content'
        }}>
          <ModalCloseButton />
          <ModalBody>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl',
              color: 'brand.500',
              textTransform: 'capitalize'
            }}>
              {t('label.my_visitors')}
            </Box>
            <BoothVisitorContent publicEvent={publicEvent} onClose={() => {
              // onClose()
              // menuClose()
            }} />
            <Routes>
            <Route path='profile/:profileId/*' element={<RnxEventVisitorProfile publicEvent={publicEvent} />} />
            </Routes>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const BoothVisitorContent = ({
  publicEvent
}) => {
  const result = useReactorQuery({
    'publicContact': {
      fields: [
        'visits'
      ],
      eventId: publicEvent.eventId
    },
  })
  const publicContact = result.graph?.publicContact
  console.log('publicContactVisits', publicContact)
  if (!publicContact) return null
  console.log('publicContactVisits', publicContact)
  return (
    <Box>

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4
      }}>
        {publicContact.visits?.map((visit, dx) => {
          const contact = visit.rnx_contact
          const eventContact = contact?.rnx_event_contact_list?.[0]
          if (!eventContact) return null
          console.log('--', contact)
          return (
            <Box as={Link} to={`profile/${eventContact.id}`} key={dx} sx={{
              mx: 4,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              flex: '0 0 150px',
              px: 3,
              my: 4,
              py: 2,
              borderRadius: 4,
              textAlign: 'center'
            }}>
              <Icon name='user' sx={{
                mx: 'auto'
              }} size={20} color='brand.500' />

              <Box sx={{
                fontWeight: 'bold'
              }}>
                {contact.name}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {eventContact.rnx_event_account?.name || eventContact.data?.rcEav?.default?.values?.infos?.company}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {eventContact.data?.rcEav?.default?.values?.infos?.position}
              </Box>

              <Box sx={{
                fontSize: 'sm',
                bg: 'brand.500',
                color: '#FFF',
                p: 1,
                borderRadius: 10
              }}>
                {dayjs(visit.datetime_start).add(3, 'hours').format('DD.MM.YYYY HH.mm')}
              </Box>
            </Box>
          )
        })}
      </Flex>

      {/*
      return (
      <Box key={dx} sx={{
        my: 3,
        pb: 3,
        borderBottom: '1px solid #e0e0e0'
      }}>
        <Flex sx={{
          alignItems: 'center'
        }}>
          <Box sx={{ flex: '1 1 0' }}>
            <Icon name='user' color='brand.500'>
              {visit.rnx_contact?.name}
            </Icon>
          </Box>

          <Box sx={{ flex: '1 1 0' }}>
            {visit.datetime_start}
          </Box>
        </Flex>
      </Box>
      )
      })} */}
    </Box>
  )
}

export default RnxEventUserProfile