import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Routes, Route, useParams, Link, useHref, useNavigate } from 'reactor-vera/router'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Content from 'reactor-ui/components/Content'
import Icon from 'reactor-ui/components/Icon'



const NetworkContent = ({
  onClose,
  publicEvent
}) => {
  return (
    <Box>
      <Requests onClose={onClose} publicEvent={publicEvent} />
    </Box>
  )
}

const Requests = ({
  onClose,
  publicEvent
}) => {
  const { t } = useTranslation()
  const params = useParams()
  const result = useReactorQuery({
    'publicContact': {
      eventId: params.eventId,
      fields: [
        'connectionRequests', 'connections'
      ]
    },
  })
  const connectionRequests = result.graph?.publicContact?.connectionRequests
  const connections = result.graph?.publicContact?.connections
  if (!connectionRequests) return null
  console.log('connectionRequests', connectionRequests)
  const url = publicEvent.url
  return (
    <Content>
      <Content.Header sx={{ textTransform: 'capitalize' }}>
        {t('label.connection_requests')}
      </Content.Header>

      {!connectionRequests?.length && (
        <Box>
          Bağlantı isteği henüz yok.
        </Box>
      )}

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4
      }}>
        {connectionRequests.map((request, dx) => {
          const contact = request.target_rnx_contact
          return (
            <Link onClick={onClose} to={`${url}/me/connections/profile/${contact.id}`} key={dx} sx={{
              mx: 4,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              flex: '0 0 150px',
              px: 3,
              my: 4,
              py: 2,
              borderRadius: 4,
              textAlign: 'center'
            }}>
              <Icon name='user' sx={{
                mx: 'auto'
              }} size={20} color='brand.500' />

              <Box sx={{
                fontWeight: 'bold'
              }}>
                {contact.name}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {contact.rnx_event_account?.name || contact.data?.rcEav?.default?.values?.infos?.company}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {contact.data?.rcEav?.default?.values?.infos?.position}
              </Box>
            </Link>
          )
        })}
      </Flex>

      <Content.Header sx={{ textTransform: 'capitalize', mt: 6 }}>
        {t('label.connections')}
      </Content.Header>

      {!connections?.length && (
        <Box>
          Bağlantınız henüz yok.
        </Box>
      )}

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4
      }}>
        {connections.map((connection, dx) => {
          const contact = connection.target_rnx_contact
          return (
            <Link onClick={onClose} to={`${url}/me/connections/profile/${contact.id}`} key={dx} sx={{
              mx: 4,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              flex: '0 0 150px',
              px: 3,
              my: 4,
              py: 2,
              borderRadius: 4,
              textAlign: 'center'
            }}>
              <Icon name='user' sx={{
                mx: 'auto'
              }} size={20} color='brand.500' />

              <Box sx={{
                fontWeight: 'bold'
              }}>
                {contact.name}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {contact.rnx_event_account?.name || contact.data?.rcEav?.default?.values?.infos?.company}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {contact.data?.rcEav?.default?.values?.infos?.position}
              </Box>
            </Link>
          )
        })}
      </Flex>
    </Content>
  )
}

export default NetworkContent