import React from 'react'

import {
  Button as ChakraButton
} from "@chakra-ui/react"

import Icon from './Icon'

const Button = React.forwardRef(({
  submit,
  iconName,
  icon,
  iconColor,
  children,
  ...rest
}, ref) => {
  return (
    <ChakraButton ref={ref} type={submit ? 'submit' : 'button'} size={(iconName || icon) && 'sm'} {...rest}>
      {(iconName || icon) ? <Icon color={iconColor} name={iconName || icon}/> : children}
    </ChakraButton>
  )

})

export default Button