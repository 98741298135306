import React from 'react'

// Reactor
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

import EventLogin from '../components/EventLogin'
import EventRegister from '../components/EventRegister'
import EventForgot from '../components/EventForgot'


const RnxEventEntrance = ({
  publicEvent,
  register,
  forgot
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: params.eventId,
      kind: 'ENTRANCE',
      fields: [
        'name', 'image'
      ]
    },
  })
  const publicEventArea = result.graph?.publicEventArea
  console.log('event', publicEvent)
  console.log('publicEventArea', publicEventArea)
  if (!publicEventArea) return null
  console.log(publicEventArea.image)
  return (
    <Box sx={{
      background: `url('${publicEventArea.image}') center center no-repeat fixed`,
      backgroundSize: 'cover',
      width: '100vw',
      minHeight: '100vh',
      pt: 100,
      pb: 100,
    }}>
      {/* {publicEvent.status === 'ACTIVE' && ( */}
      {true && (
        register ? (
          <Box sx={{
            ml: ['auto', null, null, null, 100],
            mr: ['auto', null, null, null, 0],
            mt: 8,
            bg: 'rgba(255, 255, 255, 0.95)',
            mb: 4,
            borderRadius: 4,
            width: ['95%', null, null, 350],
            p: 5
          }}>
            <EventRegister publicEvent={publicEvent}/>
          </Box>
        ) : forgot ? (
          <Box sx={{
            ml: ['auto', null, null, null, 100],
            mr: ['auto', null, null, null, 0],
            mt: 8,
            bg: 'rgba(255, 255, 255, 0.95)',
            mb: 4,
            borderRadius: 4,
            width: ['95%', null, null, 350],
            p: 5
          }}>
            <EventForgot publicEvent={publicEvent}/>
          </Box>
        ) : (
          <Box sx={{
            ml: ['auto', null, null, null, 100],
            mr: ['auto', null, null, null, 0],
            mt: 8,
            bg: 'rgba(255, 255, 255, 0.95)',
            borderRadius: 4,
            width: ['95%', null, null, 350],
            p: 5
          }}>
            <EventLogin publicEvent={publicEvent}/>
          </Box>
        )
      )}
    </Box>
  )
}

export default RnxEventEntrance