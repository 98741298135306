import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'

// Reactor
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useReactorQuery from 'reactor/hooks/useReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import Button from 'reactor-ui/components/Button'
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'

import EventLogin from '../components/EventLogin'
import EventRegister from '../components/EventRegister'

const RnxEventForms = (props) => {
  return (
    <Routes>
      <Route path=':profileId/:langId/*' element={<RnxEventFormDetail {...props} />} />
    </Routes>
  )
}

const RnxEventFormDetail = ({
  publicEvent,
}) => {
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang', publicEvent.primaryLanguage)
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: params.eventId,
      kind: 'ENTRANCE',
      fields: [
        'name', 'image'
      ]
    },
    'profileForm': {
      id: params.profileId,
      langKey: selectedLang
    }
  })
  const publicEventArea = result.graph?.publicEventArea
  const profileForm = result.graph?.profileForm
  console.log('event', publicEvent)
  console.log('publicEventArea', publicEventArea)
  if (!publicEventArea) return null
  console.log('pform', profileForm)
  return (
    <Box sx={{
      background: `url('${publicEventArea.image}') center center no-repeat fixed`,
      backgroundSize: 'cover',
      width: '100vw',
      minHeight: '100vh',
      pt: 100,
      pb: 5
    }}>
      <Box sx={{
        bg: 'rgba(255, 255, 255, 1)',
        borderRadius: 4,
        width: ['%95', null, null, 500],
        mx: 'auto',
        p: 5
      }}>
        <FormContent profileForm={profileForm} publicEvent={publicEvent} />
      </Box>
    </Box>
  )
}

const FormContent = ({
  profileForm,
  publicEvent
}) => {
  const params = useParams()
  const { t } = useTranslation()
  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.RegisterProfile',
    {
      initialValues: {
        id: publicEvent.id,
        profileId: params.profileId,
        langKey: params.langId
      },
      onSuccess: () => {
        // navigate('./lobby')
        // window.location.reload()
      }
    }
  )
  console.log(formProps)

  if (formProps.phase === 'SUCCESS') return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <Box>
        {t('txt.registration_complete')}
      </Box>

      <Box>
      {t('txt.email_inform_on_start')}
      </Box>
    </Box>
  )

  return (
    <Box>
    <Form {...formProps}>
      <Box as='img' src={publicEvent.logoLight} sx={{
        width: 150,
        mx: 'auto',
        my: 3
      }} />
      <RfFormField name="name" label={t('field.name')} required focus description={t('txt.register_name_description')} />
      <RfFormField name="email" label={t('field.email')} required description={t('txt.register_email_description')} />
      <RfFormField name="password" label={t('field.password')} kind='password' required description={t('txt.register_password_description')} />

      <RfFormField name="accountName" label={t('field.account_name')} required description={t('txt.register_account_name_description')} />

      <RfFormField name="properties.logo" label={t('field.company_logo')} kind='file' options={{acceptedKinds: 'image/jpg,image/png'}} required description={t('txt.register_logo_description')} />

      <RfFormField name="properties.brochure" label={t('field.company_brochure')} kind='file' options={{acceptedKinds: '.pdf', isMultiple: true}} description={t('txt.register_brochure_description')} />

      <RfFormField name="properties.youtube" label='Youtube' description={t('txt.register_youtube_description')} />

      <RfFormField name="properties.booth_kind" label={t('field.booth_kind')} kind='radio' options={{
        optionSx: {
          flex: '1 1 100%',
          my: 3
        },
        options: [
          {
            value: 'Tip 1',
            label: 'Tip 1',
            element: (
              <Box>
                1
                <Box as='img' src='https://eventrise.io/files//0/rcs/stand1.jpg' sx={{
                  width: '100%'
                }}/>
              </Box>
            )
          },

          {
            value: 'Tip 2',
            label: 'Tip 2',
            element: (
              <Box>
                2
                <Box as='img' src='https://eventrise.io/files//0/rcs/stand2.jpg' sx={{
                  width: '100%'
                }}/>
              </Box>
            )
          },

          {
            value: 'Tip 3',
            label: 'Tip 3',
            element: (
              <Box>
                3
                <Box as='img' src='https://eventrise.io/files//0/rcs/stand3.jpg' sx={{
                  width: '100%'
                }}/>
              </Box>
            )
          }
        ]
      }} />

      {profileForm.profile.fields.map((f, dx) => {
        console.log('--', f)
        let kind
        let options
        if (f.kind === 'OPTION') {
          kind = 'select'
          options = {
            options: f.options ? f.options.map(o => ({ value: o.value, label: o.value })) : [],
            isMultiple: f.is_multiple,
            limit: f.limit,
            create: f.allow_create
          }
        } else if (f.kind === 'STRING') {
          kind = 'text'
        } else if (f.kind === 'IMAGE') {
          kind = 'file'
          options = {
            acceptedKinds: 'image/jpeg,image/jpg,image/png'
          }
        } else {
          kind = f.kind.toLowerCase()
        }
        return (
          <RfFormField description={<Linkify componentDecorator={componentDecorator}>{f.description}</Linkify>} name={`properties.${f.codeName}`} label={f.name} kind={kind} options={options} required />
        )
      })}
      <RfFormError error={formProps.error} />
      {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
      <Button colorScheme={'brand'} sx={{textTransform: 'uppercase'}} submit isLoading={isSubmitting} isFullWidth>
        {t('btn.save')}
      </Button>
    </Form>
    </Box>
  )
}

const componentDecorator = (decoratedHref, decoratedText, key) => {
  return (
    <Box as='a' sx={{
      color: 'brand.500',
      fontWeight: 'bold'
    }} href={decoratedHref} key={key}>
      {decoratedText}
    </Box>
  )
}

export default RnxEventForms