import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import { useNavigate, Link } from 'reactor-vera/router'

// Reactor UI
import Alert from 'reactor-ui/components/Alert'
import { Flex, Box } from 'reactor-ui'
import { Form, RfFormError, RfFormField, useReactorActionForm, yup, FormStatus } from 'reactor-form/components/RfForm'
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'


const EventRegister = ({
  publicEvent,
  withTicket
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formProps, isSubmitting] = useReactorActionForm(
    withTicket ? 'RnxEvent.RegisterWithTicket' : 'RnxEvent.Register',
    {
      initialValues: {
        id: publicEvent.id
      },
      onSuccess: () => {
        // navigate('./lobby')
        // window.location.reload()
      }
    }
  )

  if (!publicEvent.registerEnabled) {
    return (
      <Box>
        Registrations are closed.
      </Box>
    )
  }

  if (formProps.phase === 'SUCCESS') return (
    <Box sx={{
      textAlign: 'center'
    }}>
    <Box>
      Kaydınız tamamlandı.
    </Box>
    Buraya tıklayarak giriş yapabilirsinis.
    <Button as={Link} colorScheme='brand' to='../' mt={4} isFullWidth>
        Giriş Yap
      </Button>
    </Box>
  )

  return (
    <Form {...formProps}>
      <Box as='img' src={publicEvent.logoLight} sx={{
        width: ['90%', null, null, 150],
        mx: 'auto',
        my: 3
      }} />
      <Box sx={{
        textAlign: 'center',
        color: 'dark.300'
      }}>
        {t('txt.register_eventrise_account')}
      </Box>
      {withTicket && <RfFormField name="ticket" label={t('field.ticket')} schema={yup.string().min(4).required()} required focus description={t('txt.ticket_description')} />}
      <RfFormField name="name" label={t('field.name')} schema={yup.string().min(4).required()} required focus />
      <RfFormField name="company" label={t('field.company')} />
      <RfFormField name="position" label={t('field.position')}/>
      <RfFormField name="email" label={t('field.email')} schema={yup.string().min(4).required()} required/>
      <RfFormField name="password" label={t('field.password')} kind='password' required />
      <Box as='textarea' sx={{
        width: '95%',
        height: 100,
        my: 4,
        ml: 2
      }} disabled value='Bu gizlilik politikası ile Ekspoturk Fuarcılık A.Ş. (gizlilik politikasında “eventrise.io” olarak anılacaktır) üzerinden kullanıcıların eventrise.io bulunan kişisel bilgilerin veya kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) kapsamında işlenmesi, depolanması, aktarılması, kullanımı, korunması ile kullanıcıların aydınlatılması hususları amaçlanmıştır.
Kullanıcıların kişisel bilgileri; adı-soyadı, doğum tarihi, telefon numarası, e-posta adresi, T.C. vatandaşlık kimlik numarası gibi kişiyi doğrudan ya da dolaylı olarak tanımlamaya yönelik her türlü bilgi olup, bu gizlilik politikasında “kişisel bilgiler veya kişisel veriler” olarak anılacaktır. Yine işbu gizlilik politikasında “açık rıza”, belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı ifade eder.
“eventrise.io​” kullanıcılarının kişisel bilgileri, bu gizlilik politikası ile belirlenen esas ve kapsam haricinde hiçbir şekilde kullanılmayacak ancak kullanıcının açık rızasıyla veya açık rıza sayılan hallerde üçüncü kişilerle paylaşılacaktır. Açık rıza sayılan hallerin başında işbu sözleşme ile gizlilik politikasını kabul etme hali gelmektedir.
“eventrise.io​” kullanıcıları, işbu sözleşme ve gizlilik politikasını kabul etmekle, kişisel bilgi ve verilerin işlenmesine, depolanmasına, aktarılmasına, korunmasına ve kullanılmasına açıkça rıza gösterdiklerini kabul ettiklerini ve bunun için yazılı veya sözlü olarak ayrıca bir rıza beyanına veya işleme gerek olmadığını kabul ve beyan eder.
eventrise.io​ kullanıcısının fiili imkansızlık nedeniyle rızasını açıklayamayacak durumda bulunması veya rızasına hukuki geçerlilik tanınmaması nedeniyle başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması, kanunlarda açıkça öngörülmüş olması, bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin, aktarılmasının veya depolanmasının gerekli olması, eventrise.io hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması, eventrise.io​ kullanıcısının kendisi tarafından alenileştirilmiş olması, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin, depolamanın veya aktarmanın zorunlu olması, kullanıcıların temel hak ve özgürlüklerine zarar vermemek kaydıyla, eventrise.io​ kullanıcılarının meşru menfaatleri için veri işlenmesinin, depolanmasının veya aktarılmasının zorunlu olması durumunda kullanıcıların açık rızası aranmaksızın kişisel veriler aktarılacak, depolanacak ve işlenecektir.
eventrise.io​ kişisel bilgilerin eventrise.io​ kullanıcılarının gerçekleştirmiş olduğu işlem bilgilerinin güvenliğini ve gizliliğini sağlamak için gerekli olan önlemleri almaktadır. eventrise.io​ bu amaçla başka programlar kullanabilir. Ancak eventrise.io​ tarafından gerekli bilgi güvenliği önlemleri alınmasına karşın internet sitesine ve/veya sisteme yapılan saldırılar (siber saldırı vs) sonucunda kişisel bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda eventrise.io bu nedenle herhangi bir sorumluluğu olmayacaktır.
Bir kullanıcının bir başka kullanıcının bilgilerine ulaşması ve bu bilgileri değiştirmesi mümkün değildir. Kullanıcının kişisel bilgileri, eventrise.io​ haricinde, yalnızca kullanıcı tarafından ulaşılabilir ve bu bilgiler ancak kullanıcı tarafından güncellenebilir. Kullanıcının talebi ile de kişisel bilgiler eventrise.io​ tarafından da güncelenebilir.
eventrise.io​ kişisel bilgileri, ölçümleme çalışmaları, reklam, tanıtım, pazarlama ile sair iletişim faaliyetleri amacıyla kullanılabileceği, aktarabileceği, yurtiçi ve yurtdışı grup şirketleri ve iş ortaklarıyla paylaşabileceği gibi, eventrise.io​ tarafından sunulan hizmet kalitesinin artırılması ve site faaliyetlerinin daha iyi şekilde yönetilmesi vs. amacıyla kayıt altına alanabilir, depolanabilir ve kullanılabilir.
Adli ve idari kurumlar tarafından gelen talepler ve bildirimler doğrultusunda kişisel bilgiler, gizlilik politikasına uygun olarak idari ve adli makamlar ile paylaşılabilir.
Kullanıcının kişisel bilgileri, gerçekleştirilen işlemlerde gerekli görülmesi halinde, sürecin hızlandırılması ve kullanıcılar arasındaki iletişimi sağlamak amacıyla işleme dahil olan diğer kullanıcı ile paylaşılabilir.
eventrise.io​ internet sayfasını kullanan kullanıcıların istatistiksel bilgilerini, teknik bir iletişim dosyasını kullanarak elde edebilir. Kullanıcılar isterse bu dosyayı engellemeye yönelik tarayıcı ayarlarını değiştirebilirler.
eventrise.io​ internet sayfasını kullanan kullanıcılar, 6698 sayılı KVKK kapsamından açıkça aydınlatıldığını ve sitenin gizlilik politikasını kabul ettiğini kabul ve beyan eder. '>

      </Box>
      <RfFormField name="kvkk" label={t('msg.read_and_consent_privacy')} kind='checkbox' required />

      <RfFormError error={formProps.error} errorMap={{
        'INVALID_TICKET': (
          <Box>
            Bir sorun olduğunu düşünüyorsanız <Box as='a' fontWeight='bold' href='https://wa.me/+905369503122' target='_blank'> buraya tıklayarak Whatsapp Destek Hattı</Box> üzerinden yardım alabilirsiniz.
          </Box>
        )
      }} />
      {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
      <FormStatus notReadyMessage={t('msg.fill_out_required_fields')}/>

      <Button sx={{
        textTransform: 'uppercase'
      }} colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
        {t('btn.register')}
      </Button>

      <Button sx={{
        textTransform: 'capitalize'
      }} as={Link} to='../' mt={4} isFullWidth>
      {t('btn.login')}
      </Button>
    </Form>
  )
}

export default EventRegister