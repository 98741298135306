import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { useTheme } from '@emotion/react'
import { setIn, getIn } from 'final-form'
import * as changeCase from "change-case"
import isArray from 'lodash/isArray'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { useFormState, useForm } from 'react-final-form'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'
import InputLabel from 'reactor-ui/components/InputLabel'

import RfTextField from './RfTextField'

//
import useParseValuesFromServer from '../hooks/useParseValuesFromServer'
import useResourceFetch from 'reactor/hooks/useResourceFetch'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfSelectField = ({
  name,
  field,
}) => {
  if (field.options?.loader) return <RfRemoteSelectField name={name} field={field} />
  return <RfLocalSelectField name={name} field={field} />
}


const RfSelectValue = React.forwardRef(({
  data,
  innerProps,
  selectProps,
  ...rest
}, ref) => {
  const { selectProps: { field } } = selectProps
  const { t } = useTranslation()
  const content = (field?.options?.translate) ? (
    <Box textTransform={'capitalize'}>{t(data?.label)}</Box>
  ) : (
    data?.label
  )
  if (data.label === 'enum.image') console.log(field, content, data, selectProps, rest)
  return (
    <Box {...innerProps} ref={ref} sx={{
      p: 1,
      px: 2,
      color: selectProps.isDisabled ? 'dark.300' : null
    }}>
      {content}
    </Box>
  )
})

const components = {
  MultiValueLabel: RfSelectValue,
  SingleValue: RfSelectValue
}

const RfLocalSelectField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })
  const theme = useTheme()

  // const values = useParseValuesFromServer(field.options.values, ctx)

  let finalOptions = field.options.options

  const selectValue = field.options?.isMultiple ? input.value?.map?.(v => ({ label: v, value: v })) : field.options?.options.find(x => x.value === input.value)

  // console.log(options, input.value, selectValue)

  if (field.options?.translate) {
    finalOptions = finalOptions.map(opt => ({
      ...opt,
      label: changeCase.title(t(`${opt.label}`) || opt.label)
    }))
  }

  console.log('sel', selectValue, field)

  if (field.options.create) {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        <InputLabel variant='active'>
          {field.label}
        </InputLabel>
        <CreatableSelect
          cacheOptions
          defaultOptions
          onChange={(props) => {
            const val = field.options.isMultiple ? props?.map(x => x.value) : props?.value
            input.onChange(val)
            field.onChange?.(val, props)
          }}
          value={selectValue}
          selectProps={{ field }}
          menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
          isMulti={field.options.isMultiple}
          isOptionDisabled={(option) => field.options?.isMultiple && selectValue && selectValue.length >= field.options.limit}
          isValidNewOption={(option) => option?.length >= 4 && !(field.options?.isMultiple && selectValue && selectValue.length >= field.options.limit)}
          placeholder={field.placeholder || field.label}
          isClearable={field.isClearable !== undefined ? field.isClearable : true}
          components={components}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: theme.colors.brand[500],
              paddingTop: 4,
              paddingBottom: 4,
              ...field?.styles?.control,
            }),
            option: (provided, state) => ({
              ...provided,
              color: '#000'
            }),
            menu: (provided, state) => ({
              ...provided,
              zIndex: 9
            }),
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 999999
            }),
          }}
        />
        <RfFieldAlert meta={meta} />
        <RfFieldDescription field={field} />
      </Box>
    )
  } else {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        <InputLabel variant='active'>
          {field.label}
        </InputLabel>
        <Select
          onChange={(props) => {
            const val = field.options?.isMultiple ? props?.map(x => x.value) : props?.value
            input.onChange(val)
            field.onChange?.(val, props)
          }}
          selectProps={{ field }}
          value={selectValue}
          options={finalOptions}
          components={components}
          menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
          isMulti={field.options?.isMultiple}
          isOptionDisabled={(option) => field.options?.isMultiple && selectValue && selectValue.length >= field.options.limit}
          placeholder={field.placeholder || field.label}
          isClearable={field.isClearable !== undefined ? field.isClearable : true}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: theme.colors.primary,
              paddingTop: 4,
              paddingBottom: 4,
              ...field?.styles?.control,
            }),
            option: (provided, state) => ({
              ...provided,
              color: '#000'
            }),
            menu: (provided, state) => ({
              ...provided,
              zIndex: 9
            }),
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 999999
            }),
          }} />

        {field.hasOther && input.value === field.hasOther.value && (
          <RfTextField placeholder={field.hasOther.placeholder} name={`${name}${field.hasOther.label}`} field={{ ...field, kind: 'text' }} />
        )}

        <RfFieldAlert meta={meta} />
        <RfFieldDescription field={field} />
      </Box>
    )
  }
}

const useLoader = (loader, ctx) => {
  let result
  if (loader.kind === 'resource') result = useResourceLoader(loader.options, ctx)
  else throw Error(`INVALID_LOADER: ${loader.kind}`)
  return result
}

const useResourceLoader = (loader, ctx) => {
  const { resourceIdentifier, values: valuesFromOptions } = loader
  const values = useParseValuesFromServer(valuesFromOptions, ctx)
  const resourceFetch = useResourceFetch(resourceIdentifier)

  const fetch = async (value) => {
    const result = await resourceFetch({ ...values, name: value })
    return result.data
  }

  return { fetch, values }
}

const RfRemoteSelectField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })
  const [value, valueSet] = React.useState()
  const theme = useTheme()

  const ctx = {}

  const loader = useLoader(field.options.loader, ctx, field.options)

  React.useEffect(() => {
    if (input.value) {
      if (isArray(input.value)) {
        const newValues = input.value.map(i => ({
          value: i,
          label: i
        }))
        valueSet(newValues)
      } else {
        valueSet({
          value: input.value,
          label: input.value
        })
      }
    }
  }, [])

  if (field.options.create) {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        <InputLabel variant='active'>
          {field.label}
        </InputLabel>
        <AsyncCreatableSelect
          cacheOptions
          defaultOptions
          loadOptions={loader.fetch}
          onChange={(props) => {
            const val = field.options.isMultiple ? props?.map(x => x.value) : props?.value
            valueSet(props)
            input.onChange(val)
            field.onChange?.(val, props)
          }}
          value={value}
          selectProps={{ field }}
          menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
          isMulti={field.options.isMultiple}
          isOptionDisabled={(option) => field.options?.isMultiple && value.length >= field.options.limit}
          placeholder={field.placeholder || field.label}
          isClearable={field.isClearable !== undefined ? field.isClearable : true}
          components={components}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: theme.colors.brand[500],
              paddingTop: 4,
              paddingBottom: 4,
              ...field?.styles?.control,
            }),
            option: (provided, state) => ({
              ...provided,
              color: '#000'
            }),
            menu: (provided, state) => ({
              ...provided,
              zIndex: 9
            }),
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 999999
            }),
          }}
        />
        <RfFieldAlert meta={meta} />
        <RfFieldDescription field={field} />
      </Box>
    )
  } else {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        <InputLabel variant='active'>
          {field.label}
        </InputLabel>
        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={loader.fetch}
          selectProps={{ field }}
          onChange={(props) => {
            const val = field.options.isMultiple ? props?.map(x => x.value) : props?.value
            valueSet(props)
            input.onChange(val)
            field.onChange?.(val, props)
          }}
          value={value}
          menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
          isMulti={field.options.isMultiple}
          isOptionDisabled={(option) => field.options?.isMultiple && value.length >= field.options.limit}
          placeholder={field.placeholder || field.label}
          isClearable={field.isClearable !== undefined ? field.isClearable : true}
          components={components}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: theme.colors.brand[500],
              paddingTop: 4,
              paddingBottom: 4,
              ...field?.styles?.control,
            }),
            option: (provided, state) => ({
              ...provided,
              color: '#000'
            }),
            menu: (provided, state) => ({
              ...provided,
              zIndex: 9
            }),
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 999999
            }),
          }}
        />
        <RfFieldAlert meta={meta} />
        <RfFieldDescription field={field} />
      </Box>
    )
  }

  // const selectValue = options?.find(x => x.value === input.value)
  // let selectValue
  // if (field.getValue) {
  //   selectValue = field.getValue(field, options, input.value)
  // } else {
  //   selectValue = options?.find(x => x.value === input.value)
  // }

  // // console.log(options, input.value, selectValue)

  // if (field.options?.translate) {
  //   finalOptions = finalOptions.map(opt => ({
  //     ...opt,
  //     label: changeCase.title(window?.translations?.generic?.[`enum.${opt.label}`] || opt.label)
  //   }))
  // }


  return (
    <Box sx={{
      position: 'relative'
    }}>
      <Box status='active' sx={{
        color: 'dark7',
        position: 'absolute',
        top: '13px',
        left: '14px',
        transition: 'all linear 0.1s',
        zIndex: 1,
        cursor: 'text',
      }} variants={{
        _prop: 'status',
        active: {
          color: 'dark7',
          top: '-10px',
          left: '10px',
          fontSize: 's',
          background: '#FFF',
          py: '2px',
          px: '4px',
          fontWeight: 'bold',
          color: 'dark6',
          // ...activeSx
        }
      }}>
        {field.label}
      </Box>
      <Select
        onChange={(props) => {
          const val = field.options?.isMultiple ? props?.map(x => x.value) : props?.value
          input.onChange(val)
          field.onChange?.(val, props)
        }}
        value={selectValue}
        options={finalOptions}
        // menuPosition='fixed'
        menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
        isMulti={field.options?.isMultiple}
        placeholder={field.placeholder || field.label}
        isClearable={field.isClearable !== undefined ? field.isClearable : true}
        // menuPlacement='top'
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: theme.colors.primary,
            paddingTop: 4,
            paddingBottom: 4,
            ...field?.styles?.control,
          }),
          option: (provided, state) => ({
            ...provided,
            color: '#000'
          }),
          menu: (provided, state) => ({
            ...provided,
            zIndex: 9
          }),
          menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 999999
          }),
        }} />

      {field.hasOther && input.value === field.hasOther.value && (
        <RfTextField placeholder={field.hasOther.placeholder} name={`${name}${field.hasOther.label}`} field={{ ...field, kind: 'text' }} />
      )}
    </Box>
  )
}

export default RfSelectField