import React from 'react'

// Reactor UI
import { Flex, Box } from '@chakra-ui/react'

// import { styled, t, tt, tc, Box } from '../index'
// import Icon from './Icon'


import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'

// import { extendTheme } from "@chakra-ui/react"
// const theme = extendTheme({
//   components: {
//     InputLabel: {
//       baseStyle: {
//         color: 'red',
//         width: 50,
//         height: 50,
//         bg: 'red'
//       }
//     },
//   },
// })

const Label = ({
  variant, activeSx, status, sx, ...rest
}) => {
  const styles = useStyleConfig("ReactorInputLabel", {
    variant, defaultStyleConfig: {
      baseStyle: {
        pointerEvents: 'none',
        color: 'dark.300',
        position: 'absolute',
        top: '8px',
        left: 3,
        transition: 'all linear 0.1s',
        zIndex: 1,
        cursor: 'text',
        textTransform: 'capitalize',
        // fontSize: 'xl',
        ...sx
      },
      variants: {
        active: {
          color: 'dark.500',
          top: '-11px',
          left: 'calc(var(--chakra-space-3) - 4px)',
          fontSize: 'sm',
          background: '#FFF',
          py: '2px',
          px: '4px',
          borderRadius: 4,
          textTransform: 'capitalize',
          // fontWeight: 'bold',
          // color: 'dark6',
          ...activeSx
        }
      }
    }
  })

  return <Box __css={styles} {...rest} />
}


const RInput = React.forwardRef(({
  variant,
  ...rest
}, ref) => {
  const styles = useStyleConfig("ReactorInput", {
    variant, defaultStyleConfig: {
      baseStyle: {
        borderRadius: 4,
        px: 3,
        py: 2,
        border: '1px solid var(--chakra-colors-dark-100)',
        outline: 'none',
        '&:focus': {
          outline: 'none',
          border: '1px solid var(--chakra-colors-brand-500)',
          boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
        },
        transition: 'all linear 0.1s',
      },
      variants: {
        active: {
          border: '1px solid var(--chakra-colors-brand-300)',
          // color: 'dark7',
          // top: '-10px',
          // left: '3px',
          // fontSize: 'sm',
          // background: '#FFF',
          // py: '2px',
          // px: '4px',
          // fontWeight: 'bold',
          // color: 'dark6',
          // ...activeSx
        },

      }
    }
  })

  return (
    <Box ref={ref} as='input' __css={styles} {...rest} />
  )
})

const RTextAreaInput = React.forwardRef(({
  isActive,
  variant,
  ...rest
}, ref) => {
  const styles = useStyleConfig("ReactorInput", {
    variant, defaultStyleConfig: {
      baseStyle: {
        borderRadius: 4,
        px: 3,
        py: 2,
        border: '1px solid var(--chakra-colors-dark-100)',
        outline: 'none',
        '&:focus': {
          outline: 'none',
          border: '1px solid var(--chakra-colors-brand-500)',
          boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
        },
        transition: 'all linear 0.1s',
      },
      variants: {
        active: {
          border: '1px solid var(--chakra-colors-brand-300)',
          // color: 'dark7',
          // top: '-10px',
          // left: '3px',
          // fontSize: 'sm',
          // background: '#FFF',
          // py: '2px',
          // px: '4px',
          // fontWeight: 'bold',
          // color: 'dark6',
          // ...activeSx
        },

      }
    }
  })

  return (
    <Box as='textarea' ref={ref} __css={styles}{...rest} />
  )
})

// const RInput = styled.input`
//   border: none;
//   background: none;

//   width: calc(100% - 20px);

//   margin: 12px;

//   ${p => p.isActive && 'margin-top: 16px; margin-bottom: 8px;'}

//   font-family: ${t('fontFamily')};
//   font-size:  ${t('fontSizes.base')};

//   &:focus {
//     outline: none;
//   }

//   transition: all linear 0.1s;

//   z-index: 2;
// `

// const RTextAreaInput = styled.textarea`
//   border: none;
//   background: none;

//   width: calc(100% - 20px);

//   margin: 12px;

//   ${p => p.isActive && 'margin-top: 16px; margin-bottom: 8px;'}

//   font-family: ${t('fontFamily')};
//   font-size:  ${t('fontSizes.base')};

//   &:focus {
//     outline: none;
//   }

//   transition: all linear 0.1s;

//   z-index: 2;

//   max-width: 100%;
// `

const After = ({
  icon,
  items
}) => (
  <Box sx={{
    position: 'absolute',
    right: 1,
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }}>
    <Icon icon={icon} />
    {items && items.map((item, dx) => (
      <Box key={dx}>
        {item}
      </Box>
    ))}
  </Box>
)

const TextInput = React.forwardRef((props, ref) => {
  const { placeholder, label, helperText, onChange, value, fullWidth, className, tabIndex, multiline, focus, selectOnMount, onMount, hasDefaultValue, afterIcon, afterItems, onBlur, onFocus, requiredLabel = true, wrapperSx, labelSx, activeLabelSx, ...rest } = props
  const inputRef = React.useRef(null)
  const [isActive, setIsActive] = React.useState(false)

  const finalRef = ref || props.refs ? e => {
    ref && ref(e)
    if (props.refs) props.refs.forEach(propRef => propRef.current = e)
    inputRef.current = e
  } : inputRef
  const [hasFocus, setHasFocus] = React.useState(false)

  React.useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
    select: () => inputRef.current.select(),
    name: props.name
  }))

  React.useEffect(() => {
    if (focus && selectOnMount) inputRef.current.select()
    if (focus) inputRef.current.focus()
  }, [])

  const handleFocus = e => {
    e.stopPropagation()
    setHasFocus(true)
    inputRef.current && inputRef.current.focus()
    onFocus && onFocus(e)
  }

  const handleBlur = e => {
    if (inputRef.current && document.activeElement === inputRef.current) return
    setHasFocus(false)
    inputRef.current && inputRef.current.blur()
    onBlur && onBlur(e)
    if (!value) setIsActive(false)
  }

  React.useEffect(() => {
    if (value) setIsActive(true)
    // else setIsActive(false)
  }, [value])

  // const isActive = (inputRef.current && inputRef.current.value) || hasFocus || props.value
  // const isActive = (inputRef.current && inputRef.current.value) || props.value

  const C = multiline || props.type === 'textarea' ? RTextAreaInput : RInput

  return (
    <Box className={className} onClick={handleFocus} sx={{
      border: '1px solid #FFF',
      borderColor: isActive ? 'primary' : 'primary8',
      transition: 'all 0.2s ease',
      // backgroundColor: theme.input?.backgroundColor || theme.colors?.light8,
      backgroundColor: 'light8',
      position: 'relative',
      // borderRadius: theme.input?.borderRadius || 1,
      borderRadius: 1,
      cursor: 'text',
      ":hover": {
        // backgroundColor: theme.input?.backgroundColorHover || theme.colors?.light6,
        backgroundColor: 'light6',
      },
      ...wrapperSx
    }}>
      {/* <RInputLabel status={isActive && 'active'} sx={labelSx} activeSx={activeLabelSx}>{label} {requiredLabel && (rest.required ? '*' : '(opsiyonel)')}</RInputLabel> */}
      {/* <Label variant={isActive && 'active'} sx={labelSx} activeSx={activeLabelSx}>{label} {requiredLabel && (rest.required ? '*' : '(opsiyonel)')}</Label> */}
      {/* <Label variant={isActive && 'active'} sx={labelSx} activeSx={activeLabelSx}>{label} {requiredLabel && (!rest.required && '(opsiyonel)')}</Label> */}
      <Label variant={isActive && 'active'} sx={labelSx} activeSx={activeLabelSx}>{label}</Label>
      <C variant={isActive && 'active'} onChange={onChange} value={value} onFocus={handleFocus} onBlur={handleBlur} ref={finalRef} tabIndex={tabIndex} {...rest} />
      {/* {(afterIcon || afterItems) && <After icon={afterIcon} items={afterItems}/>} */}
    </Box>
  )

})

export default TextInput
