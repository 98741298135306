import React from 'react'

import { Routes, Route, useParams, Link, useNavigate } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "reactor-ui/components/Modal"

import ProgramContent from '../components/ProgramContent'


const Program = ({
  defaultIsOpen
}) => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen })
  return (
    <>
      <Modal isOpen={isOpen} onClose={() => {
        onClose()
        defaultIsOpen && navigate('../lobby')
      }} size='full'>
        <ModalOverlay />
        <ModalContent sx={{
        width: ['100%', null, null, '60%'],
        my: 20,
        minHeight: 'fit-content'
      }}>
          <ModalCloseButton />
          <ModalBody>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl',
              color: 'brand.500'
            }}>
              Program
            </Box>
            <ProgramContent/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Program