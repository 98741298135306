import RcAssetManagement from './pages/RcAssetManagement'


export default {
  name: 'RcAsset',
  components: {
    'RcAssetManagement': {
      component: RcAssetManagement
    },
  }
}