import React from 'react'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

// Reactor
import { Route, Routes, Navigate, useParams, Link } from 'reactor-vera/router'

import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'

import GraphTable from 'reactor-vera/apps/data/components/GraphTable'

// Reactor UI
import Button from 'reactor-ui/components/Button'
import BareTabs from 'reactor-ui/components/BareTabs'

//
import { RnxEventContext } from '../eventContext'
import EventSetup from './EventSetup'
import EventSetupItem from './EventSetupItem'

const EventHome = props => {
  const params = useParams()
  const { rnxEvent, result, meta } = React.useContext(RnxEventContext)

  return (
    <Routes>
      <Route path='/' element={(
        <>

        </>
      )} />
      <Route path='area/:rnxEventAreaId/*' element={<EventSetupItem rnxEvent={rnxEvent} />} />
    </Routes>
  )
}

export default EventHome