import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const InfoPill = ({
  header,
  value,
  sx
}) => {
  return (
    <Flex sx={{
      alignItems: 'stretch',
      border: '1px solid #FFF',
      borderColor: 'brand.500',
      borderRadius: 4,
      px: 2,
      py: 1,
      fontSize: 'sm',
      ...sx
    }}>
      <Box sx={{
        fontSize: 'xs',
        letterSpacing: '-0.03em',
        textTransform: 'uppercase',
        borderRadius: '4px 0 0 4px',
        fontWeight: '600',
        mr: 2,
        bg: 'brand.100',
        px: 2,
        py: 1,
        ml: -2,
        my: -1
      }}>
        {header}
      </Box>
      {value}
    </Flex>
  )
}

export default InfoPill