import React from 'react'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import RfActionForm from 'reactor-form/components/RfActionForm'


const ReactorAction = ({
  action,
  displayAs,
  onClose,
  anchorKind,
  anchor,
}) => {
  if (!action.meta.ui) return null

  if (action.meta.ui.component) return action.meta.ui.component

  return (
    <RfActionForm
      actionKey={`${action.elementName}.${changeCase.pascal(action.name)}`}
      {...action.meta.ui}
      displayAs={displayAs || action.meta.ui.displayAs}
      onClose={onClose}
      anchorKind={anchorKind}
      anchor={anchor}
    />
  )
}

export default ReactorAction