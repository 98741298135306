import React from 'react'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useLocationFilter from 'reactor-vera/hooks/useLocationFilter'


const useModelListReactorQuery = ({
  modelName,
  filterKey,
  query = {}
}) => {
  const graphKey = `${changeCase.camel(modelName)}List`
  const filterInstance = useLocationFilter(filterKey || modelName)

  const { itemFields, filters, ...rest } = query
  const graphFilters = {
    ...filters,
    ...filterInstance.locationFilters
  }

  const result = useReactorQuery({
    [graphKey]: {
      itemFields: itemFields,
      filters: graphFilters,
      ...rest,
    }
  })

  return [result, graphKey, filterInstance]
}

export default useModelListReactorQuery