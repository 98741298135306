import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor Ui
import { Box, Flex } from 'reactor-ui'
import Alert from 'reactor-ui/components/Alert'


const RfFieldAlert = ({
  meta, sx
}) => {
  const { t } = useTranslation()
  if ((meta.touched || meta.dirty) && (meta.error?.length > 0)) {
    return (
      <Alert sx={{
        // my: 2
        borderRadius: 4,
        ...sx
      }}>
        {meta.error.map((err, dx) => (
          <Box key={dx}>
            {t(err)}
          </Box>
        ))}
      </Alert>
    )
  }

  return null
}

export default RfFieldAlert