import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import i18n from 'i18next'

// Reactor
import useReactorAction from 'reactor/hooks/useReactorAction'
import { Routes, Route, useParams, Link, useHref, useNavigate, Navigate } from 'reactor-vera/router'
import reactorActions from 'reactor/systems/reactor/actions'
import useReactorQuery from 'reactor/hooks/useReactorQuery'

// ReactorUi
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

import RnxEventEntrance from './RnxEventEntrance'
import RnxEventLobby from './RnxEventLobby'
import RnxEventExhibitionHall from './RnxEventExhibitionHall'
import RnxEventBoothHall from './RnxEventBoothHall'
import RnxEventRegisterWithTicket from './RnxEventRegisterWithTicket'
// import Zoom from './Zoom'
import ProgramContent from '../components/ProgramContent'
import RnxEventActivity from './RnxEventActivity'
import Register from './Register'
import SponsorContent from '../components/SponsorContent'
import AttendeeContent from '../components/AttendeeContent'
import MyVisitsContent from '../components/MyVisitsContent'
import NetworkContent from '../components/NetworkContent'
import RnxEventForms from './RnxEventForms'
import RnxEventBooth from './RnxEventBooth'

import RnxEventSponsors from './RnxEventSponsors'
import RnxEventAttendees from './RnxEventAttendees'
import RnxEventUserProfile from './RnxEventUserProfile'


const RnxEventHome = props => {
  return (
    <Routes>
      <Route path='/' element={<div />} />
      {/* <Route path='test' element={<Zoom/>} /> */}
      <Route path=':rcTenantId/:eventId*' element={<EventHome />} />
      <Route path=':rcTenantId/:eventId/activity/:activityId' element={<RnxEventActivity />} />
    </Routes>
  )
}

const EventsHome = ({

}) => {
  return 'home'
}

const EventHome = ({

}) => {
  const [ready, readySet] = React.useState()
  const dispatch = useDispatch()
  const params = useParams()
  React.useEffect(() => {
    dispatch(reactorActions.setup({
      config: {
        channel: 'ReactorPublic',
      },
      rcTenantId: params.rcTenantId
    }))
    readySet(true)
  }, [])

  if (!ready) return null
  return <TenantEvent />
}

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from "reactor-ui/components/Drawer"

const TenantEvent = ({

}) => {
  const { t } = useTranslation()
  const params = useParams()
  const result = useReactorQuery({
    'publicEvent': {
      id: params.eventId,
      fields: [
        'name', 'logoDark', 'logoLight', 'eventId', 'user', 'status', 'eventriseLogo', 'url', 'eventUser', 'primaryLanguage', 'attendanceKind', 'registerEnabled'
      ]
    },
  })
  const publicEvent = result.graph?.publicEvent
  if (!publicEvent) return null
  const user = publicEvent.user
  console.log('pubev', publicEvent, user)

  return (
    <TenantEventContent user={user} publicEvent={publicEvent} />
  )
}

const TenantEventContent = ({
  user,
  publicEvent
}) => {
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang', publicEvent.primaryLanguage)
  React.useEffect(() => {
    let langToUse = selectedLang || 'tr'
    if (langToUse == 'TURKISH') langToUse = 'tr'
    else if (langToUse == 'ENGLISH') langToUse = 'en'
    i18n.changeLanguage(langToUse)
  }, [])
  console.log('selectedLang', selectedLang)
  return (
    <Box sx={{
      // background: `url('${publicEventArea.image}') center center no-repeat fixed`,
      // backgroundSize: 'cover',
      // width: '100vw',
      // height: '100vh',
    }}>
      <HeaderMenu user={user} publicEvent={publicEvent} />

      <Box sx={{
        display: ['block', null, null, 'none'],
        bg: 'dark.500',
        height: '80px'
      }} />
      <HomeRoutes user={user} publicEvent={publicEvent} />
    </Box>
  )
}

const HomeRoutes = ({
  user,
  publicEvent
}) => {
  if (publicEvent.status === 'ACTIVE') {

    if (publicEvent.eventUser) {
      return (
        <>
          <Routes>
            <Route path="/" element={<Navigate to={`${publicEvent.url}/lobby`} />} />
            <Route path='lobby*' element={<RnxEventLobby publicEvent={publicEvent} />} />
            <Route path='conference-hall' element={<RnxEventLobby publicEvent={publicEvent} conference />} />
            <Route path='exhibition-hall*' element={<RnxEventExhibitionHall publicEvent={publicEvent} />} />
            <Route path='hall/:hallId*' element={<RnxEventBoothHall publicEvent={publicEvent} />} />
            <Route path='activity/:activityId' element={<RnxEventActivity publicEvent={publicEvent} />} />
            <Route path='profile-register/*' element={<RnxEventForms publicEvent={publicEvent} />} />
            <Route path='booth/:boothId/*' element={<RnxEventBooth publicEvent={publicEvent} />} />
            <Route path='sponsors/*' element={<RnxEventSponsors publicEvent={publicEvent} />} />
            <Route path='attendees/*' element={<RnxEventAttendees publicEvent={publicEvent} />} />
            <Route path='me/*' element={<RnxEventUserProfile publicEvent={publicEvent} />} />
            <Route path="*" element={<Navigate to={publicEvent.url} />} />
          </Routes>
        </>
      )
    } else if (publicEvent.user) {
      return (
        <>
          <Routes>
            <Route path="/" element={<MemberRegister publicEvent={publicEvent} />} />
            <Route path="*" element={<Navigate to={publicEvent.url} />} />
          </Routes>
        </>
      )
    } else {
      return (
        <Routes>
          <Route path='/' element={<RnxEventEntrance publicEvent={publicEvent} />} />
          <Route path='register' element={<RnxEventEntrance publicEvent={publicEvent} register />} />
          <Route path='register_with_ticket' element={<RnxEventRegisterWithTicket publicEvent={publicEvent} register />} />
          <Route path='forgot/*' element={<RnxEventEntrance publicEvent={publicEvent} forgot />} />
          <Route path='profile-register/*' element={<RnxEventForms publicEvent={publicEvent} />} />
          <Route path='invitation/:invitationId/*' element={<RnxEventInvitation publicEvent={publicEvent} />} />
          <Route path="*" element={<Navigate to={publicEvent.url} />} />
        </Routes>
      )
    }

  } else if (publicEvent.status === 'REGISTRATION_ONLY') {

    if (user) {
      return (
        <Routes>
          <Route path='/' element={<RnxEventRegisterOnly publicEvent={publicEvent} />} />
          <Route path="*" element={<Navigate to={publicEvent.url} />} />
        </Routes>
      )
    } else {
      return (
        <Routes>
          <Route path='/' element={<RnxEventEntrance publicEvent={publicEvent} />} />
          <Route path='register' element={<RnxEventEntrance publicEvent={publicEvent} register />} />
          {/* <Route path='register_with_ticket' element={<RnxEventRegisterWithTicket publicEvent={publicEvent} register />} /> */}
          <Route path='forgot/*' element={<RnxEventEntrance publicEvent={publicEvent} forgot />} />
          <Route path='profile-register/*' element={<RnxEventForms publicEvent={publicEvent} />} />
          <Route path="*" element={<Navigate to={publicEvent.url} />} />
        </Routes>
      )
    }

  } else {
    return (
      <Routes>
        <Route path='/' element={<RnxEventEntrance publicEvent={publicEvent} />} />
        {publicEvent.status === 'ACTIVE' && <Route path='register' element={<RnxEventEntrance publicEvent={publicEvent} register />} />}
        {publicEvent.status === 'ACTIVE' && <Route path='forgot/*' element={<RnxEventEntrance publicEvent={publicEvent} forgot />} />}
        <Route path='profile-register/*' element={<RnxEventForms publicEvent={publicEvent} />} />
        <Route path='invitation/:invitationId/*' element={<RnxEventInvitation publicEvent={publicEvent} />} />
      </Routes>
    )
  }
}

const MemberRegister = ({
  publicEvent
}) => {
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang', publicEvent.primaryLanguage)
  const { t } = useTranslation()
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: params.eventId,
      kind: 'ENTRANCE',
      fields: [
        'name', 'image'
      ]
    },
    'visitorForm': {
      id: params.eventId,
      langKey: selectedLang
    }
  })
  const publicEventArea = result.graph?.publicEventArea
  const visitorForm = result.graph?.visitorForm
  const dispatchAction = useReactorAction('RcApp.LogoutRcUser')
  const navigate = useNavigate()
  const href = useHref('')
  console.log('visitorForm', visitorForm)
  if (!publicEventArea) return null

  let content

  if (publicEvent.attendanceKind == 'CUSTOM_TICKET') {
    content = (
      <>
        <Box sx={{
          textAlign: 'center',
          my: 4
        }}>
          {t('msg.event_registration_required')}
        </Box>
        <MemberFormTicketContent visitorForm={visitorForm} publicEvent={publicEvent} />
      </>
    )
  } else if (publicEvent.registerEnabled) {
    content = (
      <>
        <Box sx={{
          textAlign: 'center',
          my: 4
        }}>
          {t('msg.event_registration_required')}
        </Box>
        <MemberFormContent visitorForm={visitorForm} publicEvent={publicEvent} />
      </>
    )
  } else {
    content = <Box sx={{
      textAlign: 'center',
      my: 4
    }}>
      Registrations are closed.
    </Box>
  }

  return (
    <Box sx={{
      background: `url('${publicEventArea.image}') center center no-repeat fixed`,
      backgroundSize: 'cover',
      width: '100vw',
      minHeight: '100vh',
      pt: 100,
      pb: 100,
    }}>
      <Box sx={{
        ml: ['auto', null, null, null, 100],
        mr: ['auto', null, null, null, 0],
        mt: 8,
        bg: 'rgba(255, 255, 255, 0.95)',
        mb: 4,
        borderRadius: 4,
        width: ['95%', null, null, 350],
        p: 5
      }}>
        <Box as='img' src={publicEvent.logoLight} sx={{
          width: 150,
          mx: 'auto',
          my: 3,
        }} />
        <Box sx={{
          textAlign: 'center'
        }}>
          {t('txt.hello')} {publicEvent.user.name}
        </Box>

        {content}

        <Box sx={{ my: 2, color: 'brand.500' }}>
          <Box as='a' sx={{
            textTransform: 'capitalize'
          }} onClick={() => {
            dispatchAction(null, {
              success: () => {
                navigate(`${href}/`)
                window.location.reload()
              }
            })
          }}>
            <Icon name='log-out'>{t('label.logout')}</Icon>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

// Reactor UI
import { Form, RfFormError, RfFormField, useReactorActionForm, yup, FormStatus } from 'reactor-form/components/RfForm'
import Button from 'reactor-ui/components/Button'
// Vendor
import Linkify from 'react-linkify'

const MemberFormContent = ({
  visitorForm,
  publicEvent
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.RegisterMembership',
    {
      initialValues: {
        id: publicEvent.id,
      },
      onSuccess: () => {
        navigate('./lobby')
        window.location.reload()
      }
    }
  )
  console.log(formProps)

  if (formProps.phase === 'SUCCESS') return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <Box>
        Kaydınız tamamlandı.
      </Box>

      <Box>
        Yönlendiriliyorsunuz.
      </Box>
    </Box>
  )

  return (
    <Box>
      <Form {...formProps}>
        {visitorForm.profile.fields.map((f, dx) => {
          // console.log('--fi', f)
          let kind
          let options
          if (f.kind === 'OPTION') {
            kind = 'select'
            options = {
              options: f.options.map(o => ({ value: o.value, label: o.value })),
              isMultiple: f.is_multiple,
              limit: f.limit
            }
          } else if (f.kind === 'STRING') {
            kind = 'text'
          } else if (f.kind === 'IMAGE') {
            kind = 'file'
            options = {
              acceptedKinds: 'image/jpg,image/png'
            }
          } else if (f.kind === 'CONTRACT') {
            kind = 'contract'
            options = {
              header: f.header,
              text: f.text
            }
          } else {
            kind = f.kind.toLowerCase()
          }
          return (
            <RfFormField description={<Linkify componentDecorator={componentDecorator}>{f.description}</Linkify>} name={`properties.${f.codeName}`} label={f.name} kind={kind} options={options} required={f.is_required} />
          )
        })}
        <RfFormError error={formProps.error} />
        <FormStatus notReadyMessage={t('msg.fill_out_required_fields')} />
        {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
        <Button sx={{
          textTransform: 'uppercase'
        }} colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
          {t('btn.register')}
        </Button>
      </Form>
    </Box>
  )
}

const MemberFormTicketContent = ({
  visitorForm,
  publicEvent
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.RegisterMembershipWithTicket',
    {
      initialValues: {
        id: publicEvent.id,
      },
      onSuccess: () => {
        navigate('./lobby')
        window.location.reload()
      }
    }
  )
  console.log(formProps)

  if (formProps.phase === 'SUCCESS') return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <Box>
        Kaydınız tamamlandı.
      </Box>

      <Box>
        Yönlendiriliyorsunuz.
      </Box>
    </Box>
  )

  return (
    <Box>
      <Box sx={{
        my: 2,
        textAlign: 'center'
      }}>
        Gelecek ID yoksa <Box as='a' fontWeight='bold' color='brand.500' href='https://geleceginbilimi.com' target='_blank'>buraya tıklayarak etkinlik sitesinden</Box> kayıt olabilirsin.
      </Box>
      <Form {...formProps}>
        <RfFormField name="ticket" label={t('field.ticket')} schema={yup.string().min(4).required()} required focus description={t('txt.ticket_description')} />
        <RfFormError error={formProps.error} errorMap={{
          'INVALID_TICKET': (
            <Box>
              Bir sorun olduğunu düşünüyorsanız <Box as='a' fontWeight='bold' href='https://wa.me/+905369503122' target='_blank'> buraya tıklayarak Whatsapp Destek Hattı</Box> üzerinden yardım alabilirsiniz.
            </Box>
          )
        }} />
        <FormStatus notReadyMessage={t('msg.fill_out_required_fields')} />
        {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
        <Button sx={{
          textTransform: 'uppercase'
        }} colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
          {t('btn.register')}
        </Button>
      </Form>
    </Box>
  )
}

const componentDecorator = (decoratedHref, decoratedText, key) => {
  return (
    <Box as='a' sx={{
      color: 'brand.500',
      fontWeight: 'bold'
    }} href={decoratedHref} key={key}>
      {decoratedText}
    </Box>
  )
}

const RnxEventRegisterOnly = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: params.eventId,
      kind: 'ENTRANCE',
      fields: [
        'name', 'image'
      ]
    },
  })
  const publicEventArea = result.graph?.publicEventArea
  const dispatchAction = useReactorAction('RcApp.LogoutRcUser')
  const navigate = useNavigate()
  const href = useHref('')
  if (!publicEventArea) return null
  return (
    <Box sx={{
      background: `url('${publicEventArea.image}') center center no-repeat fixed`,
      backgroundSize: 'cover',
      width: '100vw',
      minHeight: '100vh',
      pt: 100,
      pb: 100,
    }}>
      <Box sx={{
        ml: ['auto', null, null, null, 100],
        mr: ['auto', null, null, null, 0],
        mt: 8,
        bg: 'rgba(255, 255, 255, 0.95)',
        mb: 4,
        borderRadius: 4,
        width: ['95%', null, null, 350],
        p: 5
      }}>
        <Box as='img' src={publicEvent.logoLight} sx={{
          width: 150,
          mx: 'auto',
          my: 3,
        }} />
        <Box sx={{
          textAlign: 'center'
        }}>
          {t('msg.event_closed')}
        </Box>
        <Box sx={{ my: 2, color: 'brand.500' }}>
          <Box as='a' sx={{
            textTransform: 'capitalize'
          }} onClick={() => {
            dispatchAction(null, {
              success: () => {
                navigate(`${href}/`)
                window.location.reload()
              }
            })
          }}>
            <Icon name='log-out'>{t('label.logout')}</Icon>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const DrawerMenu = ({
  user,
  publicEvent,
  isOpen,
  onClose
}) => {
  const btnRef = React.useRef()

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Box as='img' src='' sx={{
            width: '100%'
          }}></Box>
        </DrawerHeader>

        <DrawerBody>
          <Menu menuClose={onClose} user={user} publicEvent={publicEvent} />
        </DrawerBody>

        {/* <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button>
          </DrawerFooter> */}
      </DrawerContent>
    </Drawer>
  )
}

const HeaderMenu = ({
  user,
  publicEvent
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang')
  const href = useHref('')
  return (
    <>
      <Flex sx={{
        position: 'fixed',
        zIndex: 9,
        left: 5,
        top: 5,
        width: '100vw',
        alignItems: 'center'
      }}>
        <Link to={user ? `${href}/lobby` : `${href}`}>
          <Box as='img' src={publicEvent.logoDark} sx={{
            // minHeight: '70px',
            bg: 'rgba(0, 0, 0, 0.6)',
            borderRadius: 4,
            p: 3,
            maxWidth: ['150px', null, null, '250px']
            // p:4
          }} />
        </Link>

        {user && publicEvent.status === 'ACTIVE' && <Box sx={{
          ml: 10,
          color: '#FFF',
          fontWeight: 'bold',
          fontSize: 'l',
          display: ['none', null, 'block'],
        }}>
          <Link to={`${href}/lobby`} sx={{
            textTransform: 'capitalize'
          }}>
            {t('label.lobby')}
          </Link>

          <Link to={`${href}/exhibition-hall`} sx={{
            ml: 6,
            textTransform: 'capitalize'
          }}>
            {t('label.exhibition_hall')}
          </Link>

          <Link to={`${href}/conference-hall`} sx={{
            ml: 6,
            textTransform: 'capitalize'
          }}>
            {t('label.conference_hall')}
          </Link>
        </Box>}
        {user && publicEvent.status === 'ACTIVE' && <Box sx={{
          position: 'fixed',
          zIndex: 9,
          right: 5,
          top: 5
        }} colorScheme="teal" onClick={onOpen}>
          <Icon color='#FFF' name='paragraph-justify' size={8} />
        </Box>}

        <Box as='a' sx={{
          display: 'block',
          bg: 'rgba(0, 0, 0, 0.4)',
          px: 3,
          py: 1,
          color: '#FFF',
          mx: 1,
          ml: 6
        }} onClick={() => {
          setSelectedLang('tr')
          window.location.reload()
        }}>TR</Box>
        <Box as='a' sx={{
          display: 'block',
          bg: 'rgba(0, 0, 0, 0.4)',
          px: 3,
          py: 1,
          color: '#FFF',
          mx: 1,
        }} onClick={() => {
          setSelectedLang('en')
          window.location.reload()
        }}>EN</Box>
      </Flex>
      <DrawerMenu user={user} publicEvent={publicEvent} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

const IconLink = ({
  icon,
  onClick,
  to,
  children
}) => {
  return (
    <Icon wrapperSx={{
      color: 'brand.500',
      my: 2,
    }} sx={{

    }} name={icon}>
      <Link sx={{
        display: 'block',
        textTransform: 'capitalize'
      }} onClick={onClick} to={to}>{children}</Link>
    </Icon>
  )
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  // useDisclosure
} from "reactor-ui/components/Modal"

const Menu = ({
  menuClose,
  publicEvent,
  user
}) => {
  const { t } = useTranslation()
  const href = useHref('')
  const dispatchAction = useReactorAction('RcApp.LogoutRcUser')
  const navigate = useNavigate()
  console.log('user', user)
  return (
    <>
      <Box as='img' src='/public/img/eventrise-logo.png' sx={{
        width: '60%',
        mx: 'auto',
        display: 'block',
        mb: 8
      }} />
      {publicEvent?.eventUser?.accountId && <Box sx={{
        mb: 4,
        borderBottom: '1px solid #e0e0e0',
        pb: 4,
        mb: 4
      }}>
        <IconLink icon='kiosk' onClick={menuClose} to={`${href}/me/booth`}>{t('label.my_booth')}</IconLink>
        <IconLink icon='kiosk' onClick={menuClose} to={`${href}/me/boothvisitors`}>{t('label.my_visitors')}</IconLink>
        {/* <Visits user={publicEvent.eventUser} /> */}
      </Box>}
      <Box sx={{
        my: 4,
        py: 1
      }}>
        {/* <Box sx={{ my: 2 }}>
          <Program menuClose={menuClose} />
        </Box> */}
        <IconLink icon='calendar' onClick={menuClose} to={`${href}/conference-hall`}>Program</IconLink>

        {/* <Box sx={{ my: 2 }}>
          <Sponsors menuClose={menuClose} />
        </Box> */}
        <IconLink icon='bank' onClick={menuClose} to={`${href}/sponsors`}>{t('label.sponsors')}</IconLink>

        {/* <Box sx={{ my: 2 }}>
          <Attendees menuClose={menuClose} />
        </Box> */}
        <IconLink icon='user-group' onClick={menuClose} to={`${href}/attendees`}>{t('label.attendees')}</IconLink>

        {/* <Box sx={{ my: 2 }}>
          <MyVisits menuClose={menuClose} />
        </Box> */}
        <IconLink icon='footprints' onClick={menuClose} to={`${href}/me/visits`}>{t('label.my_visits')}</IconLink>

        {/* <Box sx={{ my: 2 }}>
          <Network menuClose={menuClose} publicEvent={publicEvent} />
        </Box> */}
        <IconLink icon='cluster' onClick={menuClose} to={`${href}/me/connections`}>{t('label.my_network')}</IconLink>

        <IconLink icon='user-group' onClick={menuClose} to={`${href}/me/profile`}>{t('label.my_profile')}</IconLink>
      </Box>
      <Box sx={{
        mb: 4,
        mt: 4,
        borderBottom: '1px solid #e0e0e0',
        borderTop: '1px solid #e0e0e0',
        py: 4,
        color: 'brand.500'
      }}>
        <Icon sx={{
          my: 2,
        }} name='asterisk'>
          <Link sx={{
            display: 'block'
          }} onClick={menuClose} to={`${href}/lobby`}>{t('label.lobby')}</Link>
        </Icon>
        <Icon sx={{
          my: 2,
        }} name='asterisk'>
          <Link sx={{
            display: 'block'
          }} onClick={menuClose} to={`${href}/exhibition-hall`}>{t('label.exhibition_hall')}</Link>
        </Icon>
        <Icon sx={{
          my: 2,
        }} name='asterisk'>
          <Link sx={{
            display: 'block'
          }} onClick={menuClose} to={`${href}/conference-hall`}>{t('label.conference_hall')}</Link>
        </Icon>
      </Box>

      <Box sx={{ my: 2, color: 'brand.500' }}>
        <Box as='a' sx={{
          textTransform: 'capitalize'
        }} onClick={() => {
          dispatchAction(null, {
            success: () => {
              navigate(`${href}/`)
              window.location.reload()
            }
          })
        }}>
          <Icon name='log-out'>{t('label.logout')}</Icon>
        </Box>
      </Box>

    </>
  )
}

const Visits = ({
  user
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  return (
    <>
      <Box color='brand.500' onClick={onOpen} as='a' sx={{ textTransform: 'capitalize' }}>
        <Icon name='kiosk'>{t('label.my_booth')}</Icon>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent >
          <ModalCloseButton />
          <ModalBody>
            <VisitList user={user} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const VisitList = ({
  user
}) => {
  const result = useReactorQuery({
    'publicContact': {
      fields: [
        'visits'
      ]
    },
  })
  const publicContact = result.graph?.publicContact
  if (!publicContact) return null
  console.log('publicContact', publicContact)
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        fontSize: 'xl',
        color: 'brand.500'
      }}>
        <Icon name='user' color='brand.500'>
          Stand Ziyaretçilerim
        </Icon>
      </Box>

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4
      }}>
        {publicContact.visits?.map((visit, dx) => {
          const contact = visit.rnx_contact
          const eventContact = contact?.rnx_event_contact_list?.[0]
          console.log('--', contact)
          return (
            <Box key={dx} sx={{
              mx: 4,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              flex: '0 0 150px',
              px: 3,
              my: 4,
              py: 2,
              borderRadius: 4,
              textAlign: 'center'
            }}>
              <Icon name='user' sx={{
                mx: 'auto'
              }} size={20} color='brand.500' />

              <Box sx={{
                fontWeight: 'bold'
              }}>
                {contact.name}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {eventContact.rnx_event_account?.name || eventContact.data?.rcEav?.default?.values?.infos?.company}
              </Box>

              <Box sx={{
                fontSize: 'sm'
              }}>
                {eventContact.data?.rcEav?.default?.values?.infos?.position}
              </Box>

              <Box sx={{
                fontSize: 'sm',
                bg: 'brand.500',
                color: '#FFF',
                p: 1,
                borderRadius: 10
              }}>
                {dayjs(visit.datetime_start).add(3, 'hours').format('DD.MM.YYYY HH.mm')}
              </Box>
            </Box>
          )
        })}
      </Flex>

      {/*
      return (
      <Box key={dx} sx={{
        my: 3,
        pb: 3,
        borderBottom: '1px solid #e0e0e0'
      }}>
        <Flex sx={{
          alignItems: 'center'
        }}>
          <Box sx={{ flex: '1 1 0' }}>
            <Icon name='user' color='brand.500'>
              {visit.rnx_contact?.name}
            </Icon>
          </Box>

          <Box sx={{ flex: '1 1 0' }}>
            {visit.datetime_start}
          </Box>
        </Flex>
      </Box>
      )
      })} */}
    </Box>
  )
}



const RnxEventInvitation = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang')
  const params = useParams()
  const result = useReactorQuery({
    'publicEventArea': {
      id: params.eventId,
      kind: 'ENTRANCE',
      fields: [
        'name', 'image'
      ]
    },
    'visitorForm': {
      id: params.eventId,
      langKey: selectedLang
    },
    'publicInvitation': {
      id: params.invitationId,
      eventid: params.eventId,
    }
  })
  const publicEventArea = result.graph?.publicEventArea
  const visitorForm = result.graph?.visitorForm
  const publicInvitation = result.graph?.publicInvitation
  if (!publicEventArea) return null

  let content

  if (!publicInvitation.data) {
    content = (
      'Invalid invitation'
    )
  } else if (publicInvitation.data.status ==='ALREADY_ACCEPTED') {
    content = (
      <Box>
        You've already accepted your invitation.

        <Button as={Link} sx={{
          textTransform: 'uppercase'
        }} colorScheme={'brand'} submit isFullWidth to='./../../'>
          {t('btn.login')}
        </Button>
      </Box>
    )
  } else if (publicInvitation.data.status ==='INVITED') {
    content = <InvitationForm publicEvent={publicEvent} visitorForm={visitorForm}/>
  }

  return (
    <Box sx={{
      background: `url('${publicEventArea.image}') center center no-repeat fixed`,
      backgroundSize: 'cover',
      width: '100vw',
      minHeight: '100vh',
      pt: 100,
      pb: 5
    }}>
      <Box sx={{
        bg: 'rgba(255, 255, 255, 1)',
        borderRadius: 4,
        width: ['%95', null, null, 500],
        mx: 'auto',
        p: 5
      }}>
        {content}
      </Box>
    </Box>
  )
}

const InvitationForm = ({
  visitorForm,
  publicEvent
}) => {
  const params = useParams()
  const { t } = useTranslation()
  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.AcceptInvitation',
    {
      initialValues: {
        id: params.invitationId,
        eventId: params.eventId,
      },
      onSuccess: () => {
        // navigate('./lobby')
        // window.location.reload()
      }
    }
  )
  if (formProps.phase === 'SUCCESS') return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <Box>
        {t('txt.registration_complete')}
      </Box>

      <Box>
        <Button as={Link} sx={{
          textTransform: 'uppercase'
        }} colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth to='./../../'>
          {t('btn.login')}
        </Button>
      </Box>
    </Box>
  )

  return (
    <Box>
      <Form {...formProps}>
        <Box as='img' src={publicEvent.logoLight} sx={{
          width: 150,
          mx: 'auto',
          my: 3
        }} />
        <RfFormField name="name" label={t('field.name')} required focus description={t('txt.register_name_description')} />
        <RfFormField name="password" label={t('field.password')} kind='password' required description={t('txt.register_password_description')} />

        {visitorForm.profile.fields.map((f, dx) => {
          // console.log('--fi', f)
          let kind
          let options
          if (f.kind === 'OPTION') {
            kind = 'select'
            options = {
              options: f.options.map(o => ({ value: o.value, label: o.value })),
              isMultiple: f.is_multiple,
              limit: f.limit
            }
          } else if (f.kind === 'STRING') {
            kind = 'text'
          } else if (f.kind === 'IMAGE') {
            kind = 'file'
            options = {
              acceptedKinds: 'image/jpg,image/png'
            }
          } else if (f.kind === 'CONTRACT') {
            kind = 'contract'
            options = {
              header: f.header,
              text: f.text
            }
          } else {
            kind = f.kind.toLowerCase()
          }
          return (
            <RfFormField description={<Linkify componentDecorator={componentDecorator}>{f.description}</Linkify>} name={`properties.${f.codeName}`} label={f.name} kind={kind} options={options} required={f.is_required} />
          )
        })}

        <RfFormError error={formProps.error} />
        {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
        <Button colorScheme={'brand'} sx={{ textTransform: 'uppercase' }} submit isLoading={isSubmitting} isFullWidth>
          {t('btn.save')}
        </Button>
      </Form>
    </Box>
  )
}


export default RnxEventHome