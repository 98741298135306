import React from 'react'

import { Link as RRLink, NavLink as RRNavLink, useLocation, useResolvedPath } from "react-router-dom"
export { Link as RRLink, NavLink as RRNavLink, Routes, Route, Outlet, Navigate, useNavigate, useParams, useMatch, useResolvedPath, useInRouterContext, useLocation, useHref, useSearchParams  } from "react-router-dom"

// Reactor UI
import { Box, Flex } from 'reactor-ui'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'

export const Link = React.forwardRef(({
  variant,
  __css,
  ...props
}, ref) => {
  const styles = useStyleConfig("ReactorLink", {
    variant, defaultStyleConfig: {
      baseStyle: {

      },
      variants: {
        active: {
          color: 'brand.300'
        },

      }
    }
  })

  return <Box ref={ref} as={RRLink} __css={{...__css, ...styles}} {...props}/>
})

export const NavLink = ({
  end,
  ...props
}) => {
  let location = useLocation()
  let path = useResolvedPath(props.to)

  let locationPathname = location.pathname
  let toPathname = path.pathname
  if (!props.caseSensitive) {
    locationPathname = locationPathname.toLowerCase()
    toPathname = toPathname.toLowerCase()
  }

  let isActive = end
    ? locationPathname === toPathname
    : locationPathname.startsWith(toPathname)

  return (
    <Link variant={isActive ? 'active' : null} {...props}/>
  )
}

export const useActiveLink = ({
  end,
  to,
  caseSensitive
}) => {
  let location = useLocation()
  let path = useResolvedPath(to)

  let locationPathname = location.pathname
  let toPathname = path.pathname
  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase()
    toPathname = toPathname.toLowerCase()
  }

  let isActive = end
    ? locationPathname === toPathname
    : locationPathname.startsWith(toPathname)

  return isActive
}