import React from 'react'

// Vendor
import { useTheme } from '@emotion/react'
import Select from 'react-select'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const SelectInput = ({
  value,
  options,
  placeholder,
  onChange,
  isClearable,
  isMulti
}) => {
  const theme = useTheme()

  const selectValue = isMulti ? value?.map?.(v => ({ label: v, value: v })) : options?.find(x => x.value === value)
  return (
    <Select
      onChange={(props) => {
        isMulti ? onChange(props.map(p => p.value)) : onChange(props?.value)
      }}
      isClearable={isClearable}
      isMulti={isMulti}
      value={selectValue}
      options={options}
      placeholder={placeholder}
      menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderColor: theme.colors.brand[500],
          paddingTop: 4,
          paddingBottom: 4,
        }),
        option: (provided, state) => ({
          ...provided,
          color: '#000'
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: 9
        }),
        menuPortal: (provided, state) => ({
          ...provided,
          zIndex: 999999
        }),
      }}
    />
  )
}

export default SelectInput