import React from 'react'

import sortBy from 'lodash/sortBy'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Routes, Route, useParams, Link, useHref, useNavigate } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

const MyVisitsContent = ({
  onClose
}) => {
  const params = useParams()
  const result = useReactorQuery({
    'publicContact': {
      eventId: params.eventId,
      fields: [
        'myVisits', 'recommendations', 'url'
      ]
    },
  })
  console.log('re', result.graph)
  const visits = result.graph?.publicContact?.myVisits

  const sponsors = result.graph?.publicContact?.recommendations.sponsors
  const logos = result.graph?.publicContact?.recommendations?.logos

  const sponsors2 = result.graph?.publicContact?.myVisits.sponsors
  const logos2 = result.graph?.publicContact?.myVisits?.logos
  const url = result.graph?.publicContact?.url
  if (!sponsors) return null
  return (
    <>
      <Box sx={{
        fontWeight: 'bold',
        my: 2
      }}>
        Ziyaret ettiğiniz standlar
      </Box>

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4
      }}>
        {sponsors2.map((sponsor, dx) => {
          const info = sponsor.data.rcEav.default.values.profile_info
          const account = sponsor.owner_rnx_event_account
          const logo = logos2.find(x => x.id == info.logo)
          // console.log(account, info, logo)

          return (
            <Link onClick={onClose} to={`${url}/me/visits/booth/${sponsor.id}`} key={dx} sx={{
              mx: 4,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              flex: ['1 1 200px', null, null, '0 0 200px'],
              py: 5,
              px: 3,
              my: 4,
              borderRadius: 4,
              textAlign: 'center'
            }}>
              <Box as='img' src={logo.url} sx={{
                height: '70px',
                display: 'block',
                mx: 'auto'
              }} />
              {account.name}
            </Link>
          )
        })}
      </Flex>

      <Box sx={{
        fontWeight: 'bold',
        my: 2
      }}>
        İlginizi çekebilecek firmalar
      </Box>

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4
      }}>
        {sponsors.map((sponsor, dx) => {
          const info = sponsor.data.rcEav.default.values.profile_info
          const account = sponsor.owner_rnx_event_account
          const logo = logos.find(x => x.id == info.logo)
          // console.log(account, info, logo)

          return (
            <Link onClick={onClose} to={`${url}/me/visits/booth/${sponsor.id}`} key={dx} sx={{
              mx: 4,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              flex: ['1 1 200px', null, null, '0 0 200px'],
              py: 5,
              px: 3,
              my: 4,
              borderRadius: 4,
              textAlign: 'center'
            }}>
              {logo.url && <Box as='img' src={logo.url} sx={{
                height: '70px',
                display: 'block',
                mx: 'auto'
              }} />}
              {account.name}
            </Link>
          )
        })}
      </Flex>
    </>
  )
}

export default MyVisitsContent