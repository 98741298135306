import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import { useNavigate } from 'reactor-vera/router'


const EventCreate = props => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useBreadcrumb(`eventCreate`, t('op.create_event'))

  const [formProps, isSubmitting] = useReactorActionForm(
    'RnxEvent.CreateEvent',
    {
      onSuccess: () => navigate('../')
    }
  )

  return (
    <Box>
      <Card>
        {t('op.create_event')}
        <Form {...formProps}>
          <RfFormField name="name" label={t('field.name')} tabIndex={1} schema={yup.string().min(4).required()} required focus />
          <RfFormField name="dateStart" label={t('field.date_start')} kind='date' required />
          <RfFormField name="dateFinish" label={t('field.date_finish')} kind='date' required />
          <RfFormField name="language" label={t('field.language')} kind='select' required options={{
            loader: {
              kind: 'resource',
              options: {
                'resourceIdentifier': 'RcApp.REnum.modelField',
                'values': {
                  'modelName': 'RnxEvent',
                  'fieldName': 'primary_language',
                }
              }
            }
          }} />
          {/* <RfFormField name="eventLogo" label={t('field.event_logo')} kind='file' required /> */}
          <RfFormField name="rnxEventTemplate" label={t('field.template')} kind='entity' required options={{
            systemQuery: true,
          }} />

          <RfFormError error={formProps.error} />
          {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
          <Button colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
            OLUŞTUR
          </Button>
        </Form>
      </Card>

    </Box>
  )
}

export default EventCreate