import TenantManagement from './pages/TenantManagement'
import UserManagement from './pages/UserManagement'


export default {
  name: 'RcApp',
  components: {
    'TenantManagement': {
      component: TenantManagement
    },
    'UserManagement': {
      component: UserManagement
    }
  }
}