import React from 'react'


const RnxFormsHome = props => {
  return (
    <Routes>
      <Route path='/' element={<div />} />
      {/* <Route path='test' element={<Zoom/>} /> */}
      <Route path=':rcTenantId/:eventId*' element={<EventHome />} />
      <Route path=':rcTenantId/:eventId/activity/:activityId' element={<RnxEventActivity/>} />
    </Routes>
  )
}

export default RnxFormsHome