import React from 'react'

// Reactor
import { useParams } from 'reactor-vera/router'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import { RnxEventContext } from '../eventContext'

import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'

// Reactor UI
import { Flex, Box } from 'reactor-ui'


const RnxEventManager = ({
  children
}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxEvent': {
      filters: {
        id: params.rnxEventId
      }
    },
  })

  useBreadcrumb(`rnxEventManager:${result.graph?.rnxEvent.id}`, result.graph?.rnxEvent.name)

  const rnxEvent = result.graph?.rnxEvent
  const meta = result.graph?.rnxEventMeta

  const ctx = React.useMemo(() => ({
    rnxEvent,
    result,
    meta
  }), [rnxEvent])

  if (!rnxEvent) return null
  return (
    <RnxEventContext.Provider value={ctx}>
      <EntityDetail
        icon={'fire'}
        entity={rnxEvent}
        schema={result.schema?.rnxEvent}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
      <Box my={4}>
      {children}
      </Box>
    </RnxEventContext.Provider>
  )
}

export default RnxEventManager