import React from 'react'

// Reactor
import { Route, Routes, Navigate } from 'reactor-vera/router'
import ReactorVeraApp from 'reactor-vera/ReactorVeraApp'

// Project
import storeCreator from './core/store'
import theme from './core/theme'

import rcAppApp from 'elements/rc_app/rcApp'
import rnxEventGeneral from 'elements/rnx_event/rnxEventGeneral'
import rnxEventAdmin from 'elements/rnx_event/rnxEventAdmin'
import rnxEvent from 'elements/rnx_event/rnxEvent'
import rcImageTemplate from 'elements/rc_image_template/rcImageTemplate'
import rcAsset from 'elements/rc_asset/rcAsset'
import rnxContentProject from 'elements/rnx_content/rnxContentProject'

const apps = [
  rcAppApp,
  rnxEventGeneral,
  rnxEventAdmin,
  rnxEvent,
  rcImageTemplate,
  rcAsset,
  rnxContentProject,
]

import RnxEventHome from 'elements/rnx_event/rnxEventPublic/pages/RnxEventHome'
import RnxFormsHome from 'elements/rnx_event/rnxEventPublic/pages/RnxFormsHome'

import PrivacyPolicy from './pages/PrivacyPolicy'
import SocialLogin from './pages/SocialLogin'

const App = () => (
  <ReactorVeraApp
    storeCreator={storeCreator}
    theme={theme}
    // homeElement={<div/>}
    // globalCss={globalCss}
    apps={apps}
    // lang={getDefaultLang('tr')}
    config={{
      channel: 'Reactor',
    }}
    routes={[
      <Route path="events/*" element={<RnxEventHome/>} key='events' />,
      <Route path="forms/*" element={<RnxFormsHome/>} key='forms' />,
      <Route path="privacy-policy-and-terms-of-use" element={<PrivacyPolicy/>} key='privacy' />,
      <Route path="social-login/:kind" element={<SocialLogin/>} key='social-login' />
    ]}
  />
)

export default App
