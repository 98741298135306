import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import { useNavigate, Link } from 'reactor-vera/router'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'
import ActionButton from 'reactor-vera/apps/data/components/ActionButton'


const EventLogin = ({
  publicEvent
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formProps, isSubmitting] = useReactorActionForm(
    'RcApp.LoginRcUser',
    {
      onSuccess: () => {
        navigate('./lobby')
        window.location.reload()
      }
    }
  )

  let registerContent

  if (publicEvent.attendanceKind == 'CUSTOM_TICKET') {
    registerContent = (
      <Button sx={{ textTransform: 'uppercase' }} as={Link} to='register_with_ticket' mt={4} isLoading={isSubmitting} isFullWidth>
        {t('btn.register_with_ticket')}
      </Button>
    )
  } else {
    registerContent = (
      <>
        <ActionButton callbacks={{
          success: data => window.location.href = data.uri
        }} sx={{ textTransform: 'uppercase' }} actionIdentifier={'RcApp.SocialLoginStart'} data={{ kind: 'google', data: { id: publicEvent.id } }} isLoading={isSubmitting} isFullWidth>
          {t('btn.login_with_google')}
        </ActionButton>

        <ActionButton callbacks={{
          success: data => window.location.href = data.uri
        }} sx={{ textTransform: 'uppercase', mt: 4 }} actionIdentifier={'RcApp.SocialLoginStart'} data={{ kind: 'linkedin', data: { id: publicEvent.id } }} isLoading={isSubmitting} isFullWidth>
          {t('btn.login_with_linkedin')}
        </ActionButton>

        {publicEvent.registerEnabled && <Button sx={{ textTransform: 'uppercase' }} as={Link} to='register' mt={4} isLoading={isSubmitting} isFullWidth>
          {t('btn.register')}
        </Button>}
      </>
    )
  }

  return (
    <Form {...formProps}>
      <Box as='img' src={publicEvent.logoLight} sx={{
        width: 150,
        mx: 'auto',
        my: 3
      }} />
      <Box sx={{
        textAlign: 'center',
        color: 'dark.300'
      }}>
        {t('txt.login_with_eventrise')}
      </Box>
      <RfFormField name="email" label={t('field.email')} tabIndex={1} schema={yup.string().min(4).required()} required focus />
      <RfFormField name="password" label={t('field.password')} kind='password' tabIndex={2} required />
      {/* {(publicEvent.attendanceKind == 'CUSTOM_TICKET') && (
        'test'
      )} */}

      <RfFormError error={formProps.error} />
      {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
      <Button sx={{ textTransform: 'uppercase' }} colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
        {t('btn.login')}
      </Button>

      <Box sx={{
        fontSize: 'sm',
        textTransform: 'uppercase',
        textAlign: 'center',
        my: 4
      }}>
        <Link to={'./forgot'} sx={{ color: 'brand.500' }}>{t('label.forgot_password')}</Link>
      </Box>

      <Box sx={{
        fontSize: 'sm',
        textTransform: 'uppercase',
        textAlign: 'center',
        my: 4
      }}>
        {t('label.or')}
      </Box>

      {registerContent}
    </Form>
  )
}

export default EventLogin