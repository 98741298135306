import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import ModelDetailCard from 'reactor-vera/apps/model/components/ModelDetailCard'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

import { Link } from 'reactor-vera/router'



const Projects = props => {
  return (
    <Routes>
      <Route path='/' element={<ContentProjectHome />} />
      <Route path='project/:rnxContentProjectId/*' element={<ContentProjectDetail />} />
    </Routes>
  )
}

const ContentProjectHome = props => {
  const { t } = useTranslation()
  return (
    <>
    <GraphTable
      modelName='RnxContentProject'
      detailLink='project'
    />
    </>
  )
}

const ContentProjectDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxContentProject': {
      filters: {
        id: params.rnxContentProjectId
      }
    },
  })

  useBreadcrumb(`rnxContentProjectDetail:${result.graph?.rnxContentProject.id}`, result.graph?.rnxContentProject.name)

  const rnxContentProject = result.graph?.rnxContentProject
  const meta = result.graph?.rnxContentProjectMeta

  if (!rnxContentProject) return null

  return (
    <>
      <ModelDetailCard
      modelName='rnxContentProject'
      entity={rnxContentProject}
      schema={result.schema?.rnxContentProject}
      fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
    </>
  )
}

export default Projects